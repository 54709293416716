import React from "react";
import DatePicker from "react-datepicker";

const SelectDate = (props) => {
  return (
    <div
      className={
        props.showDateMonthPicker
          ? "date-picker date-month-picker"
          : "date-picker"
      }
    >
      {props.label && (
        <label htmlFor={props.name} className="input-label">
          {props.required && <span style={{ color: "#FF615B" }}>*</span>}
          {props.label}
        </label>
      )}
      <DatePicker
      required={props.required}
        selected={props.value}
        onChange={(date) => props.onChange(date)}
        popperPlacement="bottom-end"
        dateFormat={props.dateFormat || "dd MMMM yyyy"}
        showMonthYearPicker={props.showMonthYearPicker || null}
        placeholderText={props.placeholder || null}
        showYearDropdown={props.showYearDropdown || null}
      />
    </div>
  );
};

export default SelectDate;
