import React, { useState, Fragment, useContext } from "react";
import { Grid, Tabs, Tab, Box, makeStyles } from "@material-ui/core";
import TablePagination from "../../../../component/Navigation/TablePagination";
import MaterialTable from "material-table";
import StatusTag from "../../../../component/Misc/StatusTag";
import priceFormat from "../../../../helper/PriceFormat";
import moment from "moment";
import ButtonSecondary from "../../../../component/Button/ButtonSecondary";
import { PDFDownloadLink } from "@react-pdf/renderer";
import ResiPembayaran from "../../Pembayaran/ResiPembayaran";
import { errorMessage } from "../../../../helper/Static";
import Axios from "axios";
import { batalkanPembayaranURL } from "../../../../api/pembayaran";
import { toast } from "react-toastify";
import Success from "../../../../component/Misc/Success";
import Failed from "../../../../component/Misc/Failed";
import UserContext from "../../../../context/User/UserContext";

function TabPanel(props) {
  const { children, value, index, classes, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`siswa-tabpanel-${index}`}
      aria-labelledby={`siswa-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3} classes={{ root: classes.boxRoot }}>
          {children}
        </Box>
      )}
    </div>
  );
}

const useStyles = makeStyles(() => ({
  root: {
    textTransform: "none",
    color: "#80848D",
    fontWeight: 700,
  },
  indicator: {
    backgroundColor: "#FFB038",
    height: 3,
  },
  selected: {
    fontWeight: 700,
    color: "#2a2a2a",
  },
  boxRoot: {
    border: "1px solid #E1E1E1",
  },
}));

const TabsSiswa = (props) => {
  const classes = useStyles();
  const user = useContext(UserContext);

  const [value, setValue] = useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const page = props.page;
  const total = props.history.total;
  const setPage = props.setPageHistory;

  const batalkanPembayaran = async (id) => {
    const reason = window.prompt(`Isikan alasan pembatalan pembayaran ${id}`);
    if (reason) {
      try {
        const canceling = await Axios.post(batalkanPembayaranURL(id), {
          reason,
        });
        if (canceling) {
          if (!canceling.error) {
            toast(<Success>{canceling.data.data}</Success>);
            props.reload();
          } else {
            throw canceling.data.error;
          }
        } else {
          throw errorMessage("membatalkan pembayaran");
        }
      } catch (error) {
        toast(<Failed>{error}</Failed>);
      }
    }
  };

  return (
    <div>
      <div className="wrapper-form edit">
        <Grid container justify="space-between" alignItems="center">
          <Grid item>
            <div className="header-title">ADMINISTRASI</div>
          </Grid>
        </Grid>
      </div>
      <div className="wrapper">
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="siswa tabs"
          classes={{ indicator: classes.indicator }}
        >
          <Tab
            label="Tagihan"
            id="siswa-tab-0"
            aria-controls="siswa-tabpanel-0"
            classes={{ selected: classes.selected, root: classes.root }}
          />
          <Tab
            label="History Pembayaran"
            id="siswa-tab-1"
            aria-controls="siswa-tabpanel-1"
            classes={{ selected: classes.selected, root: classes.root }}
          />
        </Tabs>
        <TabPanel classes={classes} value={value} index={0}>
          <div className="table-wrapper">
            <MaterialTable
              columns={[
                {
                  title: "Tagihan",
                  field: "type",
                  render: (rowData) => (
                    <div className="text-bold">
                      {rowData.type} - {rowData.name}
                    </div>
                  ),
                },

                {
                  title: "Jumlah Tagihan",
                  field: "jumlah_tagihan",
                  render: (rowData) => (
                    <div>{priceFormat(rowData.jumlah_tagihan)}</div>
                  ),
                },
                {
                  title: "Tenggat Tagihan",
                  field: "tenggat_tagihan",
                  render: (rowData) => (
                    <div className="">
                      {rowData.tenggat_tagihan
                        ? moment(new Date(rowData.tenggat_tagihan)).format(
                            "DD MMMM YYYY"
                          )
                        : "-"}
                      {rowData.status && (
                        <StatusTag
                          background={
                            rowData.status === "paid" ? "#78B666" : "#DC6E6E"
                          }
                        >
                          {rowData.status === "paid" ? "Lunas" : "Belum Lunas"}
                        </StatusTag>
                      )}
                    </div>
                  ),
                },

                {
                  title: "",
                  field: "status",
                  render: (rowData) => {
                    return <div>-</div>;
                  },
                },
              ]}
              detailPanel={(rowData) => {
                return (
                  <div className="tagihan-details">
                    <p>Pembayaran</p>
                    {rowData.tagihan.map((data, index) => {
                      return (
                        <div className="tagihan-timeline">
                          <div
                            className={`tagihan-status ${
                              index + 1 === rowData.tagihan.length ? "" : "line"
                            }`}
                          >
                            <i
                              className="material-icons"
                              style={{
                                color:
                                  data.status === "unpaid"
                                    ? "#80848D"
                                    : "#78B666",
                              }}
                            >
                              check_circle
                            </i>
                          </div>
                          <div>
                            <div className="text-bold">
                              {moment(new Date(data.bulan_penagihan)).format(
                                "MMMM YYYY"
                              )}
                            </div>
                            <p className="color-grey">
                              {data.tanggal_pembayaran
                                ? moment(
                                    new Date(data.tanggal_pembayaran)
                                  ).format("DD MMMM YYYY hh:mm:ss")
                                : "-"}
                            </p>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                );
              }}
              data={props.tagihan}
              isLoading={props.loading}
              options={{
                toolbar: false,
                padding: "dense",
                pageSize: 10,
                paging: false,
              }}
            />
          </div>
        </TabPanel>
        <TabPanel classes={classes} value={value} index={1}>
          <div className="table-wrapper">
            <MaterialTable
              key={total}
              columns={[
                {
                  title: "Tanggal & Waktu",
                  field: "created_at",
                  render: (rowData) => (
                    <div
                      style={{
                        color:
                          rowData.status === "valid" ? "#2a2a2a" : "#80848D",
                      }}
                    >
                      {rowData.created_at}
                    </div>
                  ),
                },
                {
                  title: "No. Resi",
                  field: "nomor_resi",
                  render: (rowData) => (
                    <Fragment>
                      {rowData.status === "valid" ? (
                        <div>
                          <PDFDownloadLink
                            document={<ResiPembayaran data={rowData.resi} />}
                            fileName={`${rowData.nomor_resi}.pdf`}
                          >
                            {({ blob, url, loading, error }) =>
                              loading ? (
                                "Memuat dokumen..."
                              ) : (
                                <span className="link-underline text-bold">
                                  {rowData.nomor_resi}
                                </span>
                              )
                            }
                          </PDFDownloadLink>
                        </div>
                      ) : (
                        <div style={{ color: "#80848D" }}>
                          {rowData.nomor_resi}
                        </div>
                      )}
                    </Fragment>
                  ),
                },
                {
                  title: "Nama Siswa",
                  field: "nama_siswa",
                  render: (rowData) => (
                    <div
                      style={{
                        color:
                          rowData.status === "valid" ? "#2a2a2a" : "#80848D",
                      }}
                    >
                      {rowData.nama_siswa}
                    </div>
                  ),
                },
                {
                  title: "Pembayaran",
                  field: "items",
                  width: 300,
                  render: (rowData) => {
                    return (
                      <div
                        style={{
                          color:
                            rowData.status === "valid" ? "#2a2a2a" : "#80848D",
                        }}
                      >
                        {rowData.items.map((item, index) => {
                          const objKey = Object.keys(item);

                          return (
                            <div key={index}>
                              {objKey.map((key, idx) => {
                                return (
                                  <div key={idx} className="mb-3">
                                    <div>
                                      <span className="text-bold">
                                        {item[key].tipe}
                                      </span>{" "}
                                      - {item[key].nama}
                                    </div>
                                    <div>
                                      {moment(
                                        new Date(item[key].bulan_mulai)
                                      ).format("MMMM YYYY")}{" "}
                                      -{" "}
                                      {moment(
                                        new Date(item[key].bulan_selesai)
                                      ).format("MMMM YYYY")}{" "}
                                    </div>
                                    <div style={{ color: "#80848D" }}>
                                      {item[key].jumlah}
                                    </div>
                                  </div>
                                );
                              })}
                            </div>
                          );
                        })}
                      </div>
                    );
                  },
                },
                {
                  title: "Jumlah Pembayaran",
                  field: "jumlah",
                  render: (rowData) => (
                    <div
                      style={{
                        color:
                          rowData.status === "valid" ? "#2a2a2a" : "#80848D",
                      }}
                    >
                      {!!rowData.diskon && (
                        <div style={{ fontSize: 12 }}>
                          Diskon: {priceFormat(rowData.diskon)}
                        </div>
                      )}
                      <div>{priceFormat(rowData.jumlah)}</div>
                    </div>
                  ),
                },
                {
                  title: "",
                  width: 200,
                  hidden: user?.user.role === "Kasir",
                  render: (rowData) => {
                    return (
                      <div className="table-action">
                        {rowData.status === "valid" ? (
                          <ButtonSecondary
                            icon="cancel_presentation"
                            color="#DC6E6E"
                            borderColor="#DC6E6E"
                            onClick={() =>
                              batalkanPembayaran(rowData.nomor_resi)
                            }
                          >
                            Batal
                          </ButtonSecondary>
                        ) : (
                          <div className="d-block" style={{ color: "#80848D" }}>
                            <div className="text-bold">
                              DIBATALKAN <br />
                            </div>
                            <div>Alasan: </div>
                            <div>{rowData.reason}</div>
                          </div>
                        )}
                      </div>
                    );
                  },
                },
              ]}
              data={props.history.data}
              isLoading={props.history.isLoading}
              options={{
                toolbar: false,
                padding: "dense",
                pageSize: 10,
              }}
              components={{
                Pagination: (props) => {
                  console.log("pagination props", props);
                  return (
                    <td>
                      <TablePagination
                        onChangePage={(e) => setPage(e)}
                        total={total}
                        page={page}
                        itemShow={props.rowsPerPage}
                      />
                    </td>
                  );
                },
              }}
            />
          </div>
        </TabPanel>
      </div>
    </div>
  );
};

export default TabsSiswa;
