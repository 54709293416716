import React from "react";
import MaterialTable from "material-table";
import { Grid } from "@material-ui/core";

const TableCekRegistrasi = (props) => {
  console.log("summary props", props);
  return (
    <div className="table-wrapper pb-4">
      <div className="text-bold color-ocean-blue mb-4" style={{ fontSize: 18 }}>
        Paket Pendidikan
      </div>
      <MaterialTable
        columns={[
          {
            title: "Paket",
            field: "type",
            width: 350,
            render: (rowData) => (
              <div className="mb-1">
                <div className="text-bold">{rowData.type}</div>
                {rowData.data
                  ? rowData.data.map((data) => <div>{data.paket}</div>)
                  : "-"}
              </div>
            ),
          },

          {
            title: "Masa Belajar",
            field: "data",
            render: (rowData) => (
              <div className="mt-1 mb-1">
                {rowData.data?.map((data) => {
                  return (
                    <div key={data.nama} style={{ padding: "12px 0px" }}>
                      {data.masa_belajar}
                    </div>
                  );
                })}
              </div>
            ),
          },
          {
            title: "Biaya Per Bulan",
            field: "data",
            render: (rowData) => (
              <div className="mt-1 mb-1">
                {rowData.data?.map((data) => {
                  return (
                    <div key={data.nama} style={{ padding: "12px 0px" }}>
                      {data.biaya_per_bulan}
                    </div>
                  );
                })}
              </div>
            ),
          },
          {
            title: "Jumlah",
            field: "data",
            render: (rowData) => (
              <div className="mt-1 mb-1">
                {rowData.data?.map((data) => {
                  return (
                    <div key={data.nama} style={{ padding: "12px 0px" }}>
                      {data.jumlah}
                    </div>
                  );
                })}
              </div>
            ),
          },
        ]}
        isLoading={props.data.loading}
        data={props.data.table}
        options={{
          toolbar: false,
          padding: "dense",
          pageSize: 10,
          paging: false,
        }}
      />
      <div style={{background: 'rgba(255, 245, 208, 0.5)', marginTop: 32, fontWeight: 700 }}>
      <Grid container justify="flex-end" spacing={3}>
          <Grid item xs={3} style={{ textAlign: 'center' }}>
              Total
          </Grid>
          <Grid item xs={3} style={{ textAlign: 'center' }}>
              {props.data.total}
          </Grid>
      </Grid>
      </div>
    </div>
  );
};

export default TableCekRegistrasi;
