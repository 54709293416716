import React, { useState, useContext } from "react";
import Private from "../../../../layout/Private";
import {
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  makeStyles,
} from "@material-ui/core";
import Breadcrumb from "../../../../component/Navigation/Breadcrumb";
import DataDiriSiswa from "./DataDiriSiswa";
import PaketPendidikan from "./PaketPendidikan";
import RegisterSiswaProvider from "../../../../context/RegisterSiswa/RegisterSiswaProvider";
import RegistrasiSiswaContext from "../../../../context/RegisterSiswa/RegisterSiswaContext";
import CekRegistrasi from "./CekRegistrasi";
import successImage from "../../../../assets/img/success-image.svg";
import ButtonPrimary from "../../../../component/Button/ButtonPrimary";
import ButtonSecondary from "../../../../component/Button/ButtonSecondary";
import Toastr from "../../../../component/Misc/Toastr";
import { panelInitial } from "./SiswaInitialData";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles({
  root: {
    backgroundColor: "#F6F9FF",
    color: "#3A82D1",
    border: "1px solid #E1E1E1",
    minHeight: 56,
    "&$expanded": {
      minHeight: 56,
    },
  },
  details: {
    border: "1px solid #E1E1E1",
    borderTop: 0,
  },
  summaryDisabled: {
    color: "#80848D",
  },
});

const RegistrasiSiswa = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const siswaContext = useContext(RegistrasiSiswaContext);

  const [state, setState] = useState({
    breadcrumb: [
      {
        name: "EEC",
        url: "/dashboard",
        active: false,
      },
      {
        name: "Siswa",
        url: "/siswa/registrasi",
        active: false,
      },
      {
        name: "Registrasi Siswa",
        url: "/siswa/registrasi",
        active: true,
      },
    ],
    panel: panelInitial,
  });

  const togglePanel = (idx) => {
    setState({
      ...state,
      panel: state.panel.map((data, index) => {
        if (idx === index) {
          return {
            ...data,
            active: true,
          };
        }

        return {
          ...data,
          active: false,
        };
      }),
    });
  };

  const setActivePanel = (idx) => {
    console.log("active", idx);
    const activeIndex = idx;
    const filledIndex = idx - 1;
    setState({
      ...state,
      panel: state.panel.map((data, index) => {
        if (activeIndex === index) {
          const checkLastPanel = activeIndex === 2 && { filled: true };
          return {
            ...data,
            active: true,
            disabled: false,
            ...checkLastPanel,
          };
        }

        if (filledIndex === index) {
          return {
            ...data,
            filled: true,
            active: false,
          };
        }

        return data;
      }),
    });
  };

  const setBackPanel = (idx) => {
    console.log("back", idx);
    setState({
      ...state,
      panel: state.panel.map((data, index) => {
        if (idx === index) {
          return {
            ...data,
            active: true,
          };
        }

        return {
          ...data,
          active: false,
        };
      }),
    });
  };

  const resetForm = () => {
    siswaContext.reset();
    siswaContext.onChangeArray("isCreated", false);
    siswaContext.onChangeArray("isReset", true);
    setState({
      ...state,
      panel: panelInitial,
    });
  };

  const goToPayment = () => {
    console.log("push");
    history.push({
      pathname: `/pembayaran/baru`,
      search: `id=${
        siswaContext.createdData.id
      }&name=${siswaContext.createdData.nama_siswa.replace(/ /g, "_")}`,
    });
  };

  console.log("data panel", state.panel);
  console.log("siswa context", siswaContext);

  return (
    <Private {...props}>
      <Breadcrumb data={state.breadcrumb} />
      {siswaContext.isCreated ? (
        <div className="wrapper">
          <div className="siswa-created">
            <img src={successImage} alt="success" />
            <div className="mt-4">
              <a
                className="text-bold"
                href={`/siswa/daftar/${siswaContext.createdData.id}`}
                target="_blank"
                rel="noopener noreferrer"
                style={{ textDecoration: "underline" }}
              >
                {siswaContext.createdData.nama_siswa}
              </a>{" "}
              berhasil didaftarkan
            </div>
            <div className="mt-4">
              <ButtonPrimary onClick={goToPayment} type="button">
                Lanjutkan Pembayaran
              </ButtonPrimary>
            </div>
            <div className="mt-3">
              <ButtonSecondary onClick={resetForm} icon="add">
                Registrasi Siswa Baru
              </ButtonSecondary>
            </div>
          </div>
        </div>
      ) : (
        <div className="wrapper-padding">
          {state.panel.map((data, index) => {
            return (
              <ExpansionPanel
                key={index}
                expanded={data.active}
                disabled={data.disabled}
              >
                <ExpansionPanelSummary
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  classes={{
                    root: classes.root,
                    disabled: classes.summaryDisabled,
                  }}
                  onClick={() => togglePanel(index)}
                >
                  <div className="text-uppercase text-bold d-flex align-items-center">
                    <span
                      className={`panel-number ${
                        data.disabled ? "panel-number-disabled" : ""
                      }`}
                    >
                      {index + 1}
                    </span>{" "}
                    {data.title}
                    {data.filled && (
                      <i
                        className="material-icons"
                        style={{
                          fontSize: 20,
                          color: "#78B666",
                          marginLeft: 4,
                        }}
                      >
                        check_circle
                      </i>
                    )}
                  </div>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails classes={{ root: classes.details }}>
                  {data.body === "siswa" && (
                    <DataDiriSiswa
                      setActivePanel={(index) => setActivePanel(index)}
                    />
                  )}

                  {data.body === "pendidikan" && (
                    <PaketPendidikan
                      setActivePanel={(index) => setActivePanel(index)}
                      setBackPanel={(index) => setBackPanel(index)}
                    />
                  )}

                  {data.body === "cek" && (
                    <CekRegistrasi
                      active={data.active}
                      setBackPanel={(index) => setBackPanel(index)}
                    />
                  )}
                </ExpansionPanelDetails>
              </ExpansionPanel>
            );
          })}
        </div>
      )}

      <Toastr />
    </Private>
  );
};

export default (props) => {
  return (
    <RegisterSiswaProvider>
      <RegistrasiSiswa {...props} />
    </RegisterSiswaProvider>
  );
};
