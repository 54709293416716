import React from 'react';
import { CircularProgress } from '@material-ui/core';

const Loading = () => {
    return (
        <div className="loading-state">
            <CircularProgress />
        </div>
    );
};

export default Loading;