/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import Breadcrumb from "../../../../component/Navigation/Breadcrumb";
import Private from "../../../../layout/Private";
import TablePagination from "../../../../component/Navigation/TablePagination";
import MaterialTable from "material-table";
import { Grid, Tooltip, Switch, makeStyles } from "@material-ui/core";
import LinkOutlined from "../../../../component/Button/LinkOutlined";
import { useLocation, Link } from "react-router-dom";
import { toast } from "react-toastify";
import Failed from "../../../../component/Misc/Failed";
import Success from "../../../../component/Misc/Success";
import Toastr from "../../../../component/Misc/Toastr";
import Axios from "axios";
import {
  listTahunAjaranURL,
  deleteTahunAjaranURL,
  editStatusTahunAjaranURL,
} from "../../../../api/tahunAjaran";
import moment from "moment";
import { errorMessage, deleteMessage } from "../../../../helper/Static";

const useStyle = makeStyles({
  switchBase: {
    "&$checked": {
      color: "#fff",
    },
    "&$checked + $track": {
      backgroundColor: "#FFB038",
      opacity: 1,
    },
  },
  thumb: {
    boxShadow: "0px 0px 2px rgba(0, 0, 0, 0.15)",
    border: "1px solid #E1E1E1",
  },
  label: {
    fontSize: 14,
  },
  checked: {},
  track: {},
});

const TahunAjaran = props => {
  const location = useLocation();
  const classes = useStyle();
  const [state, setState] = useState({
    breadcrumb: [
      {
        name: "EEC",
        url: "/dashboard",
        active: false,
      },
      {
        name: "Pengaturan",
        url: "/pengaturan",
        active: false,
      },
      {
        name: "Tahun Ajaran",
        url: "/pentaturan/tahun-ajaran",
        active: true,
      },
    ],
    tahunAjaran: [],
    total: '',
    isLoading: true,
  });

  const getTahunAjaranData = async () => {
    setState({
      ...state,
      isLoading: true,
    });
    try {
      const tahunAjaran = await Axios.get(listTahunAjaranURL);
      if (tahunAjaran) {
        console.log("tahun ajaran", tahunAjaran);
        setState({
          ...state,
          tahunAjaran: tahunAjaran.data.data,
          total: tahunAjaran.data.total,
          isLoading: false,
        });
      } else {
        const errorText = "Error";
        throw errorText;
      }
    } catch (error) {
      setState({
        ...state,
        isLoading: false,
      });
      toast(<Failed>{error}</Failed>);
    }
  };

  useEffect(() => {
    if (location.toast) {
      toast(<Success>{location.toast}</Success>);
    }
    getTahunAjaranData();
  }, []);

  const deleteTahunAjaran = async (id, text) => {
    if (window.confirm(deleteMessage(`tahun ajaran ${text}`))) {
      try {
        const deleting = await Axios.post(deleteTahunAjaranURL(id));
        if (deleting) {
          if (!deleting.data.error) {
            toast(<Success>{deleting.data.data}</Success>);
            getTahunAjaranData();
          } else {
            throw deleting.data.error;
          }
        } else {
          throw errorMessage("menghapus tahun ajaran");
        }
      } catch (error) {
        toast(<Failed>{error}</Failed>);
        setState({
          ...state,
          isLoading: false,
        });
      }
    }
  };

  const onChangeStatus = async (id, value) => {
    setState({
      ...state,
      isLoading: true,
    })
    try {
      const updating = await Axios.post(editStatusTahunAjaranURL(id), {
        status: value
      });
      if (updating) {
        if (!updating.data.error) {
          toast(<Success>{updating.data.data}</Success>);
          getTahunAjaranData();
        } else {
          throw updating.data.error;
        }
      } else {
        throw errorMessage("mengubah status tahun ajaran");
      }
    } catch (error) {
      toast(<Failed>{error}</Failed>);
      setState({
        ...state,
        isLoading: false,
      });
    }
  };

  return (
    <Private {...props}>
      <Breadcrumb data={state.breadcrumb} />
      <div className="wrapper table-wrapper">
        <Grid container className="mb-3" spacing={3} justify="flex-end">
          <Grid item>
            <LinkOutlined address="/pengaturan/tahun-ajaran/baru" icon="add">
              TAMBAH TAHUN AJARAN
            </LinkOutlined>
          </Grid>
        </Grid>
        <div className="table-title">Daftar Tahun Ajaran</div>
        <MaterialTable
          columns={[
            {
              title: "Periode Tahun Ajaran",
              field: "tanggal_mulai",
              render: (rowData) => (
                <div>
                  {moment(new Date(rowData.tanggal_mulai)).format("MMMM YYYY")}{" "}
                  -{" "}
                  {moment(new Date(rowData.tanggal_selesai)).format(
                    "MMMM YYYY"
                  )}{" "}
                </div>
              ),
            },
            {
              title: "Status",
              field: "status",
              render: (rowData) => {
                return (
                  <div>
                    <Switch
                      classes={{
                        switchBase: classes.switchBase,
                        thumb: classes.thumb,
                        track: classes.track,
                        checked: classes.checked,
                      }}
                      size="small"
                      checked={rowData.status === "aktif"}
                      onChange={() => onChangeStatus(rowData.id, rowData.status === 'aktif' ? 'non-aktif' : 'aktif')}
                    />
                    <p
                      className={`sentence-case text-bold ${
                        rowData.status === "aktif" ? "color-secondary" : "color-grey"
                      }`}
                    >
                      {rowData.status}
                    </p>
                  </div>
                );
              },
            },
            {
              title: "",
              render: (rowData) => {
                const text = `${moment(new Date(rowData.tanggal_mulai)).format(
                  "MMMM YYYY"
                )} - ${moment(new Date(rowData.tanggal_selesai)).format(
                  "MMMM YYYY"
                )}`;
                return (
                  <div className="table-action">
                    <Tooltip title="Edit tahun ajaran" arrow>
                      <Link to={`/pengaturan/tahun-ajaran/${rowData.id}/edit`}>
                        <i className="material-icons table-edit">edit</i>
                      </Link>
                    </Tooltip>

                    <Tooltip title="Hapus tahun ajaran" arrow>
                      <button
                        onClick={() => deleteTahunAjaran(rowData.id, text)}
                      >
                        <i className="material-icons table-delete">delete</i>
                      </button>
                    </Tooltip>
                  </div>
                );
              },
            },
            {
              title: "tanggal selesai",
              field: "tanggal_selesai",
              hidden: true,
            },
            {
              title: "id",
              field: "id",
              hidden: true,
            },
          ]}
          isLoading={state.isLoading}
          data={state.tahunAjaran}
          options={{
            toolbar: false,
            padding: "dense",
            pageSize: 10,
          }}
          components={{
            Pagination: (props) => {
              console.log("pagination props", props);
              return (
                <td>
                  <TablePagination
                    onChangePage={(e) => props.onChangePage(e, props.page + 1)}
                    total={state.total}
                    page={props.page + 1}
                    itemShow={props.rowsPerPage}
                  />
                </td>
              );
            },
          }}
        />
      </div>
      <Toastr />
    </Private>
  );
};

export default TahunAjaran;
