/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useState,
  useEffect,
  useCallback,
  Fragment,
  useContext,
  useRef,
} from "react";
import Breadcrumb from "../../../component/Navigation/Breadcrumb";
import Private from "../../../layout/Private";
import MaterialTable from "material-table";
import { Grid } from "@material-ui/core";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import SingleSelect from "../../../component/Field/SingleSelect";
import Failed from "../../../component/Misc/Failed";
import Success from "../../../component/Misc/Success";
import Toastr from "../../../component/Misc/Toastr";
import Axios from "axios";
// import _ from 'lodash';
// import { errorMessage, deleteMessage } from "../../../../helper/Static";
// import moment from "moment";
import DetailPanel from "./KenaikanPanel";
import { listPendidikanURL } from "../../../api/sekolah";
import ButtonLoading from "../../../component/Button/ButtonLoading";
import ButtonPrimary from "../../../component/Button/ButtonPrimary";
import { findKelasByPendidikanURL } from "../../../api/kelas";
import { luluskanURL, findByKelasLuluskanURL } from "../../../api/siswa";
import PanelContext from "../../../context/KenaikanKelas/PanelContext";
import PanelProvider from "../../../context/KenaikanKelas/PanelProvider";

const DetailIcon = (props) => (
  <div className="d-flex align-items-center">
    <i className="material-icons color-ocean-blue">
      {props.open ? "expand_less" : "expand_more"}
    </i>
    <div className="text-bold color-ocean-blue font-md">
      {props.open ? "Tutup" : "Detail"}
    </div>
  </div>
);

const KenaikanKelas = (props) => {
  const location = useLocation();
  const context = useContext(PanelContext);

  const [state, setState] = useState({
    breadcrumb: [
      {
        name: "EEC",
        url: "/dashboard",
        active: false,
      },
      {
        name: "Kelas",
        url: "/daftar-kelas",
        active: false,
      },
      {
        name: "Kenaikan Kelas",
        url: "/daftar-kelas/kenaikan",
        active: true,
      },
    ],
    data: [],
    filter: {
      date: new Date(),
    },
    selectedId: "",
    total: "",
    sekolah: [],
    isLoading: false,
  });

  const [pendidikan, setPendidikan] = useState(null);
  const [sekolah, setSekolah] = useState([]);
  const [loading, setLoading] = useState(false);
  const [sekolahLoading, setSekolahLoading] = useState([]);
  const [siswaLoading, setSiswaLoading] = useState(false);
  const ref = useRef();

  const getAllData = useCallback(async (id) => {
    setState({
      ...state,
      isLoading: true,
    });

    try {
      const fetch = await Axios.get(findKelasByPendidikanURL(id));
      if (fetch) {
        console.log("data", fetch);
        const manipulateData =
          fetch.data.data.map((res, i) => ({
            ...res,
            pendidikan: pendidikan.label,
            checked: false,
            index: i,
            detail: null,
          })) || [];

        setState({
          ...state,
          data: manipulateData,
          total: fetch.data.total,
          isLoading: false,
        });
        context.onChange("data", manipulateData);
      } else {
        throw new Error("Tidak dapat memuat kelas!");
      }
    } catch (error) {
      setState({
        ...state,
        isLoading: false,
      });
      toast(<Failed>{error.message}</Failed>);
    }
  });

  useEffect(() => {
    if (pendidikan) {
      getAllData(pendidikan.value);
    }
  }, [pendidikan]);

  const getDetailData = async (id) => {
    setSiswaLoading(true)
    try {
      const fetch = await Axios.get(findByKelasLuluskanURL(id));
      if (fetch) {
        const manipulateData =
          fetch.data.data.map((res, i) => ({
            ...res,
            checked: true,
          })) || [];

        context.onChangePanel(id, "detail", manipulateData);
      } else {
        throw new Error("Tidak dapat memuat data.");
      }
    } catch (error) {
      toast(<Failed>{error.message}</Failed>);
    } finally {
      setSiswaLoading(false)
    }
  };

  const getAllPendidikan = async () => {
    const urlParam = `${listPendidikanURL}`;
    setSekolahLoading(true)

    try {
      const sekolah = await Axios.get(urlParam);
      if (sekolah) {
        console.log("tahun ajaran", sekolah);
        setSekolah(
          sekolah.data.data.map((data) => ({
            ...data,
            value: data.id,
            label: data.nama,
          }))
        );
        setSekolahLoading(false)
      } else {
        throw new Error("Tidak dapat memuat pendidikan!");
      }
    } catch (error) {
      toast(<Failed>{error.message}</Failed>);
      setSekolahLoading(false)
    }
  };

  useEffect(() => {
    getAllPendidikan();
  }, []);

  useEffect(() => {
    if (location.toast) {
      toast(<Success>{location.toast}</Success>);
    }
  }, []);

  const selectKelas = (id) => {
    const manipulateData = context.data.map((data) => {
      if (data.id === id) {
        return {
          ...data,
          checked: !data.checked,
        };
      }

      return data;
    });

    context.onChange("data", manipulateData);
  };

  const luluskan = async () => {
    try {
      setLoading(true);
      console.log(context.data);

      const manipulateData = context.data.reduce((result, options) => {
        const checkSiswaSelected = options.detail?.filter(
          (data) => data.checked
        );
        if (checkSiswaSelected?.length) {
          const data = {
            list_id_siswa: checkSiswaSelected.map((data) => data.id),
            id_kelas: options.id,
          };

          return result.concat(data);
        }

        return result;
      }, []);

      console.log(manipulateData);
      const submit = await Axios.post(luluskanURL, {
        list_kelas: manipulateData,
      });
      if (submit.data.error) {
        throw new Error(submit.data.error);
      }
      if (submit) {
        setLoading(false);
        getAllData(pendidikan.value);
        toast(<Success>Berhasil meluluskan siswa!</Success>);
      }
    } catch (error) {
      setLoading(false);
      toast(<Failed>{error.message}</Failed>);
    }
  };

  return (
    <Private {...props}>
      <Breadcrumb data={state.breadcrumb} />
      <div className="wrapper-form edit">
        <Grid container justify="space-between" alignItems="center">
          <Grid item className="header-title">
            Kenaikan Kelas
          </Grid>
        </Grid>
      </div>
      <div className="wrapper">
        <Grid
          container
          spacing={3}
          justify="center"
          alignItems="flex-end"
          className="mb-4"
        >
          <Grid item xs={12} md={4}>
            <SingleSelect
              value={pendidikan}
              placeholder="Pilih Pendidikan"
              onChange={(e) => setPendidikan(e)}
              label="Pendidikan"
              options={sekolah}
              isLoading={sekolahLoading}
            />
          </Grid>
        </Grid>
        {pendidikan && (
          <Fragment>
            <div className="separator-border pt-4 mb-4"></div>
            <p className="font-sm">Menampilkan daftar kenaikan kelas</p>
            <div className=" table-wrapper table-penagihan">
              <MaterialTable
                ref={ref}
                columns={[
                  // {
                  //   title: "",
                  //   field: "checked",
                  //   width: 30,
                  //   render: (rowData) => (
                  //     <div>
                  //       <Checkbox
                  //         style={{ padding: "0px 8px" }}
                  //         color="default"
                  //         checked={rowData.checked}
                  //         onChange={() => {

                  //           selectSiswa(rowData.id_siswa)
                  //         }}
                  //         name={rowData.nama_siswa}
                  //       />
                  //     </div>
                  //   ),
                  // },
                  {
                    title: "Pendidikan",
                    field: "pendidikan",
                    width: 300,
                  },
                  {
                    title: "Tingkat",
                    field: "tingkat",
                    render: (rowData) => <div>Tingkat {rowData.tingkat}</div>,
                  },
                  {
                    width: 300,
                    title: "Tahun Ajaran",
                    field: "tahun_ajaran",
                    render: (rowData) => <div>{rowData.tahun_ajaran || '-'}</div>,
                  },
                  {
                    title: "Status",
                    field: "jumlah",
                    render: (rowData) => <div>Aktif</div>,
                  },
                ]}
                isLoading={state.isLoading}
                data={context.data}
                // onRowClick={(event, rowData, togglePanel) => togglePanel()}
                onSelectionChange={(rows) => {
                  if(rows[0]) {
                    getDetailData(rows[0].id);
                    selectKelas(rows[0].id);
                  }
                }}
                options={{
                  selection: true,
                  selectionProps: { color: "default" },
                  toolbar: false,
                  padding: "dense",
                  paging: false,
                  actionsColumnIndex: -1,
                  detailPanelColumnAlignment: "right",
                  rowStyle: (rowData) => {
                    if (rowData.tableData.id % 2 === 0) {
                      return { backgroundColor: "#F6F9FF" };
                    }

                    return { backgroundColor: "#fff" };
                  },
                }}
                detailPanel={[
                  {
                    icon: () => <DetailIcon />,
                    openIcon: () => <DetailIcon open />,
                    render: (rowData) => {
                      return (
                        <DetailPanel
                          id={rowData.id}
                          index={rowData.tableData.id}
                          checked={rowData.tableData.checked}
                        />
                      );
                    },
                  },
                ]}
              />
            </div>
            <Grid
              className="mt-4"
              container
              spacing={3}
              justify="flex-end"
            >
              {/* <Grid item>
                Luluskan ke Tahun Ajaran{" "}
                <span className="text-bold">Agustus 2021-Juli 2022</span>
              </Grid> */}
              <Grid item>
                {loading || siswaLoading ? (
                  <ButtonLoading>{loading ? "Memproses..." : "Memeriksa data siswa..."}</ButtonLoading>
                ) : (
                  <ButtonPrimary onClick={luluskan}>
                    Luluskan Kelas
                  </ButtonPrimary>
                )}
              </Grid>
            </Grid>
          </Fragment>
        )}
      </div>

      <Toastr />
    </Private>
  );
};

export default (props) => {
  return (
    <PanelProvider>
      <KenaikanKelas {...props} />
    </PanelProvider>
  );
};
