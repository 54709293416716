import React, { useState, useContext, useEffect } from "react";
import { Grid } from "@material-ui/core";
import ButtonPrimary from "../../../../component/Button/ButtonPrimary";
import ButtonSecondary from "../../../../component/Button/ButtonSecondary";
import Failed from "../../../../component/Misc/Failed";
import PaketSekolah from "./PaketSekolah";
import PaketKursus from "./PaketKursus";
import PaketLainLain from "./PaketLainLain";
import { toast } from "react-toastify";
import RegistrasiSiswaContext from "../../../../context/RegisterSiswa/RegisterSiswaContext";

const PaketPendidikan = (props) => {
  const siswaContext = useContext(RegistrasiSiswaContext)
  const [tabs, setTabs] = useState("sekolah");

  const [sekolah, setSekolah] = useState({});
  const [inventori, setInventori] = useState([]);
  const [kursus, setKursus] = useState([]);

  const submitPendidikan = (e) => {
    if(e) {
      e.preventDefault();
    }

    try {
      // Validate sekolah
      const validateSekolah =
        sekolah.tahun_ajaran || sekolah.jenjang_pendidikan || sekolah.kelas;

      if (validateSekolah) {
        if (
          !sekolah.tahun_ajaran  ||
          !sekolah.jenjang_pendidikan ||
          !sekolah.kelas
        ) {

          const errorText = {
            error: "Mohon lengkapi data sekolah!",
            type: "sekolah",
            data: null,
          };
          throw errorText;
        }
      }

      // Validate inventory
      if (inventori.length) {
        const checkRequired = inventori.filter(
          (data) => data.nama && data.harga
        );
        if (checkRequired.length !== inventori.length) {
          const errorText = {
            error: "Mohon lengkapi data pembelian lain-lain!",
            type: "inventori",
            data: null,
          };
          throw errorText;
        }
      }

      if (!validateSekolah && !kursus.length) {
        const errorText = {
          error: "Mohon lengkapi data paket pendidikan!",
          type: "sekolah",
          data: null,
        };
        throw errorText;
      }

      const sekolahData = {
        jenjang_pendidikan: sekolah.jenjang_pendidikan ? sekolah.jenjang_pendidikan.value : null,
        tahun_ajaran: sekolah.tahun_ajaran ? sekolah.tahun_ajaran.value : null,
        kelas: sekolah.kelas ? sekolah.kelas.value : null
      }

      console.log('submit', sekolah, kursus, inventori)
      siswaContext.onChangeArray('sekolah', sekolahData);
      siswaContext.onChangeArray('kursus', kursus);
      siswaContext.onChangeArray('inventori', inventori)

      props.setActivePanel(2);

    } catch (error) {
      if(error.type === 'sekolah') {
        setTabs("sekolah")
      }

      if(error.type === 'inventori') {
        setTabs("lain-lain")
      }

      toast(<Failed>{error.error}</Failed>);

      console.log('error', error)
    }
  };

  useEffect(() => {
    if(siswaContext.isReset) {
      setSekolah({});
      setInventori([]);
      setKursus([])
    }
  }, [siswaContext.isReset])

  return (
    <div style={{ width: "100%" }}>
      <form noValidate onSubmit={(e) => submitPendidikan(e)}>
        <nav>
          <div className="nav nav-tabs" id="nav-tab" role="tablist">
            <a
              className={`nav-item nav-link ${
                tabs === "sekolah" ? "active" : ""
              }`}
              id="sekolah-tab"
              data-toggle="tab"
              href="#sekolah"
              role="tab"
              aria-controls="sekolah"
              aria-selected="true"
              onClick={() => setTabs("sekolah")}
            >
              Sekolah
            </a>
            <a
              className={`nav-item nav-link ${
                tabs === "kursus" ? "active" : ""
              }`}
              id="kursus-tab"
              data-toggle="tab"
              href="#kursus"
              role="tab"
              aria-controls="kursus"
              aria-selected="true"
              onClick={() => setTabs("kursus")}
            >
              Kursus
            </a>
            <a
              className={`nav-item nav-link ${
                tabs === "lain-lain" ? "active" : ""
              }`}
              id="lain-lain-tab"
              data-toggle="tab"
              href="#lain-lain"
              role="tab"
              aria-controls="lain-lain"
              aria-selected="true"
              onClick={() => setTabs("lain-lain")}
            >
              Lain-lain
            </a>
          </div>
        </nav>
        <div className="tab-content" id="nav-tabContent">
          <div
            className={`tab-pane ${tabs === "sekolah" ? "show active" : ""}`}
            id="sekolah"
            role="tabpanel"
            aria-labelledby="sekolah-tab"
          >
            <PaketSekolah setSekolah={(data) => setSekolah(data)} />
          </div>
          <div
            className={`tab-pane ${tabs === "kursus" ? "show active" : ""}`}
            id="kursus"
            role="tabpanel"
            aria-labelledby="kursus-tab"
          >
            <PaketKursus setKursus={(data) => setKursus(data)} />
          </div>
          <div
            className={`tab-pane ${tabs === "lain-lain" ? "show active" : ""}`}
            id="lain-lain"
            role="tabpanel"
            aria-labelledby="lain-lain-tab"
          >
            <PaketLainLain setInventori={(data) => setInventori(data)}/>
          </div>
        </div>
        <div className="container">
          <Grid className="mt-4" container spacing={3} justify="flex-end">
            <Grid item>
              <ButtonSecondary onClick={() => props.setBackPanel(0)} icon="chevron_left">Kembali</ButtonSecondary>
            </Grid>
            <Grid item>
              <ButtonPrimary type="submit">Selanjutnya</ButtonPrimary>
            </Grid>
          </Grid>
        </div>
      </form>
    </div>
  );
};

export default PaketPendidikan;
