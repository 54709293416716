/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useCallback, useEffect, Fragment } from "react";
import moment from "moment";
import queryString from "query-string";
import Private from "../../../../layout/Private";
import Breadcrumb from "../../../../component/Navigation/Breadcrumb";
import {
  Grid,
  FormControlLabel,
  makeStyles,
  Checkbox,
  FormGroup,
  IconButton,
  Icon,
} from "@material-ui/core";
import { toast } from "react-toastify";
import Axios from "axios";
import successImage from "../../../../assets/img/success-image.svg";
import ButtonPrimary from "../../../../component/Button/ButtonPrimary";
import Failed from "../../../../component/Misc/Failed";
import Toastr from "../../../../component/Misc/Toastr";
import useDebounce from "../../../../helper/useDebounce";
import { listFindByNameURL } from "../../../../api/siswa";
import DropdownSiswa from "./DropdownSiswa";
import TextField from "../../../../component/Field/TextField";
import ButtonAddItem from "../../../../component/Button/ButtonAddItem";
import ButtonLoading from "../../../../component/Button/ButtonLoading";
import TablePembayaran from "./TablePembayaran";
import priceFormat from "../../../../helper/PriceFormat";
import { getPayableURL, createPembayaranURL } from "../../../../api/pembayaran";
import LinkOutlined from "../../../../component/Button/LinkOutlined";
// import { BlobProvider } from "@react-pdf/renderer";
// import ResiPembayaran from "../ResiPembayaran";
import TableSummary from "./TableSummary";
import { useLocation } from "react-router-dom";

const useStyles = makeStyles({
  radio: {
    "&$checked": {
      color: "#2a2a2a",

      "&:hover": {
        backgroundColor: `rgba(0,0,0, 0.04)`,
      },
    },
    "&:hover": {
      backgroundColor: `rgba(0,0,0, 0.04)`,
    },
  },
  checked: {},
});

const BuatPembayaran = (props) => {
  const classes = useStyles();
  const location = useLocation();
  const [details, setDetails] = useState(false);

  const [state, setState] = useState({
    startDate: new Date(),
    endDate: new Date(),
    spp: false,
    lain: false,
    created: false,
    diskon: "",
    breadcrumb: [
      {
        name: "EEC",
        url: "/dashboard",
        active: false,
      },
      {
        name: "Pembayaran",
        url: "/pembayaran/baru",
        active: false,
      },
      {
        name: "Buat Pembayaran",
        url: "/pembayaran/baru",
        active: true,
      },
    ],
  });
  const [others, setOthers] = useState([
    {
      harga: "",
      nama: "",
    },
  ]);
  const [payable, setPayable] = useState({
    spp: [],
    lain: [],
    loading: false,
    totalSpp: 0,
    totalOther: 0,
  });
  const [resi, setResi] = useState(null);
  const [selected, setSelected] = useState(null);
  const [search, setSearch] = useState("");
  // const [query, setQuery] = useState(null)

  const debouncedSearch = useDebounce(search, 500);

  useEffect(() => {
    const query = queryString.parse(location.search);
    if (query.id) {
      // setQuery(query.id)
      setSelected({
        label: query.name.replace(/_/g, " "),
        value: query.id,
        id: query.id,
      });
      getPayableData(query.id, query.id);
      setState({
        ...state,
        spp: true,
      });
    }
  }, []);

  const getAllData = useCallback(
    async (filter) => {
      console.log("filterrrrrr", filter);
      setState({
        ...state,
        isLoading: true,
      });

      const checkSearch = debouncedSearch && { name: debouncedSearch };

      try {
        const siswa = await Axios.post(listFindByNameURL, {
          ...checkSearch,
        });
        if (!siswa.data.error) {
          const manipluatedData = await Promise.all(
            siswa.data.data.map((data) => {
              return {
                ...data,
                label: data.nama_siswa,
                value: data.id,
              };
            })
          );
          console.log("daftar siswa", siswa);
          setState({
            ...state,
            siswa: manipluatedData,
            total: siswa.data.total,
            isLoading: false,
          });
        } else {
          const errorText = "Error";
          throw errorText;
        }
      } catch (error) {
        setState({
          ...state,
          isLoading: false,
        });
        toast(<Failed>{error}</Failed>);
      }
    },
    [debouncedSearch]
  );

  useEffect(() => {
    getAllData();
  }, [getAllData]);

  const getPayableData = async (id, query) => {
    try {
      const payData = await Axios.get(
        `${getPayableURL(id)}${query ? `/true` : ""}`
      );
      if (payData.data.data.message && !payData.data.data.item) {
        throw new Error(payData.data.data.message);
      }

      const manipulateSpp = [
        {
          type: "Sekolah",
          data: payData.data.data.spp.sekolah.map((data) => {
            // const initDate = moment(
            //   new Date(`${data.tanggal_selesai.substring(0, 9)}2`)
            // ).isBefore(new Date())
            //   ? new Date(`${data.tanggal_selesai.substring(0, 9)}1`)
            //   : new Date();
            return {
              ...data,
              checked: false,
              tanggal_mulai: new Date(`${data.tanggal_mulai.substring(0, 9)}1`),
              tanggal_selesai: new Date(
                `${data.tanggal_selesai.substring(0, 9)}2`
              ),
              start_pay_month: null,
              end_pay_month: null,
              diff: 0,
              total: data.biaya_per_bulan_angka,
              type: "sekolah",
            };
          }),
        },
        {
          type: "Kursus",
          data: payData.data.data.spp.kursus.map((data) => {
            return {
              ...data,
              checked: false,
              tanggal_mulai: new Date(`${data.tanggal_mulai.substring(0, 9)}1`),
              tanggal_selesai: new Date(
                `${data.tanggal_selesai.substring(0, 9)}1`
              ),
              start_pay_month: null,
              end_pay_month: null,
              type: "kursus",
            };
          }),
        },
      ];

      setPayable({
        ...payable,
        spp: manipulateSpp.filter((data) => data.data.length),
        lain: payData.data.data.lain,
        totalOther: payData.data.data.lain.reduce(
          (a, b) => a + (parseFloat(b.harga) || 0),
          0
        ),
      });

      setOthers(
        payData.data.data.lain.map((lain) => ({
          ...lain,
          nama: lain.nama_barang,
        }))
      );
    } catch (error) {
      console.log(error.message);
      toast(<Failed>{error.message}</Failed>);
    }
  };

  const getDiff = (start, end) => {
    return parseInt(
      moment(new Date(end)).diff(new Date(start), "months", true) + 1
    );
  };

  const onChangePayableData = async (data, name, value) => {
    const changedData = await Promise.all(
      payable.spp.map((pay) => {
        if (pay.type === data.type) {
          const payData = pay.data.map((item) => {
            if (item.nama === data.nama) {
              const newStart =
                name === "start_pay_month" ? value : data.start_pay_month;
              const newEnd =
                name === "end_pay_month" ? value : data.end_pay_month;

              const newChecked = name === "checked" ? value : data.checked;
              const calcMonth =
                data.start_pay_month && data.end_pay_month
                  ? getDiff(newStart, newEnd)
                  : 1;

              return {
                ...item,
                [name]: value,
                diff: calcMonth,
                subtotal:
                  newChecked &&
                  parseFloat(calcMonth * data.biaya_per_bulan_angka),
              };
            }

            return item;
          });

          return {
            ...pay,
            data: payData,
            totalType: payData.reduce(
              (a, b) => a + (parseFloat(b.subtotal) || 0),
              0
            ),
          };
        }

        return pay;
      })
    );

    setPayable({
      ...payable,
      spp: changedData,
      totalSpp: changedData.reduce(
        (a, b) => a + (parseFloat(b.totalType) || 0),
        0
      ),
    });
  };

  const onChangeSiswa = (value) => {
    setSelected(value);
    getPayableData(value.value);
  };

  const onChangeSearch = (e) => {
    setSearch(e);
  };

  const onChange = (value, name) => {
    setState({
      ...state,
      [name]: value,
    });
  };

  const onChangeOthers = (value, name, index) => {
    const changedOther = others.map((other, i) => {
      if (index === i) {
        return {
          ...other,
          [name]: value,
        };
      }
      return other;
    });
    setOthers(changedOther);
    setPayable({
      ...payable,
      totalOther: changedOther.reduce(
        (a, b) => a + (parseFloat(b.harga) || 0),
        0
      ),
    });
  };

  const addItem = () => {
    setOthers(
      others.concat({
        harga: "",
        nama: "",
      })
    );
  };

  const deleteItem = (index) => {
    setOthers(others.filter((data, i) => i !== index));
  };

  const createPembayaran = async (e) => {
    e.preventDefault();
    setState({
      ...state,
      loading: true,
    });

    console.log("submit payable", payable);
    console.log("submit other", others);
    console.log("submit selected", selected);

    const manipulatePayable = payable.spp
      .map((data) => data.data.filter((item) => item.checked))
      .flat();
    console.log("manipulate arr", manipulatePayable);

    const sppData = manipulatePayable.map((data) => {
      return {
        tipe: data.type,
        nama: data.nama,
        tanggal_mulai: moment(new Date(data.start_pay_month)).format(
          "YYYY-MM-00"
        ),
        tanggal_selesai: moment(new Date(data.end_pay_month)).format(
          "YYYY-MM-00"
        ),
        biaya_perbulan: data.biaya_per_bulan_angka,
      };
    });

    const lainData = others.map((data) => {
      return {
        nama_barang: data.nama,
        harga: data.harga,
        deleted: false,
      };
    });

    const checkLain = state.lain && { lain: lainData };
    const checkSpp = state.spp && { spp: sppData };

    try {
      if (!state.spp && !state.lain) {
        throw new Error("Pilih pembayaran terlebih dahulu!");
      }

      const submit = await Axios.post(createPembayaranURL, {
        id_siswa: selected.id,
        diskon: parseFloat(state.diskon),
        ...checkLain,
        ...checkSpp,
      });

      console.log("submit", submit);

      if (submit) {
        if (!submit.data.error) {
          setResi(submit.data.data.nomor_resi);
          setState({
            ...state,
            loading: false,
            created: true,
          });
        } else {
          throw new Error(submit.data.error);
        }
      } else {
        throw new Error(
          "Tidak dapat membuat tahun ajaran untuk saat ini. Mohon coba kembali!"
        );
      }
    } catch (error) {
      // console.log("LOGIN ERROR", error);
      toast(<Failed>{error.message}</Failed>);
      setState({
        ...state,
        loading: false,
        created: false,
      });
    }
  };

  console.log("get payable", payable);

  return (
    <Private {...props}>
      <Breadcrumb data={state.breadcrumb} />
      {state.created ? (
        <div className="wrapper">
          <div className="siswa-created">
            <img src={successImage} alt="success" />
            <div className="mt-4">Pembayaran berhasil dibuat.</div>
            <div className="mt-4">
              <a
                style={{ color: "#fff" }}
                target="_blank"
                rel="noopener noreferrer"
                href={`/pembayaran/resi/${resi}`}
              >
                <ButtonPrimary style={{ width: 310 }} type="button">
                  Cetak Resi
                </ButtonPrimary>
              </a>
            </div>
            <div className="mt-3">
              <LinkOutlined address={`/siswa/daftar/${selected.id}`}>
                Lihat Detail Pembayaran
              </LinkOutlined>
            </div>
          </div>
        </div>
      ) : (
        <Fragment>
          <div className="wrapper-form">
            <div className="header-title">DETAIL PEMBAYARAN</div>
          </div>
          <form onSubmit={(e) => createPembayaran(e)}>
            <div className="wrapper">
              <div className="container" style={{ maxWidth: 1040 }}>
                <Grid container spacing={3}>
                  <Grid item xs={12} className="separator pb-4">
                    <label
                      htmlFor="tahun-ajaran-status"
                      className="input-label"
                    >
                      <span style={{ color: "#FF615B" }}>*</span>
                      <span>Jenis Pembayaran</span>
                    </label>
                    <FormGroup
                      aria-label="tahun-ajaran-status"
                      name="tahun-ajaran-status"
                      style={{ flexDirection: "row" }}
                    >
                      <FormControlLabel
                        value="spp"
                        control={
                          <Checkbox
                            onChange={() => onChange(!state.spp, "spp")}
                            checked={state.spp}
                            disableRipple
                            classes={{
                              root: classes.radio,
                              checked: classes.checked,
                            }}
                          />
                        }
                        label="Uang Bulanan (SPP)"
                        style={{ width: "fit-content" }}
                      />
                      <FormControlLabel
                        value="non-aktif"
                        control={
                          <Checkbox
                            onChange={() => onChange(!state.lain, "lain")}
                            checked={state.lain}
                            classes={{
                              root: classes.radio,
                              checked: classes.checked,
                            }}
                          />
                        }
                        label="Lain-lain (Alat Tulis/Buku)"
                        style={{ width: "fit-content" }}
                      />
                    </FormGroup>
                  </Grid>
                  <Grid item xs={12} className="mt-2">
                    <DropdownSiswa
                      name="Pembayaran Oleh"
                      label="Pembayaran Oleh"
                      placeholder="Cari nama siswa atau NIS"
                      className="mb-2"
                      value={selected}
                      onChange={(e) => onChangeSiswa(e)}
                      options={state.siswa}
                      onChangeInputValue={(value) => onChangeSearch(value)}
                      required
                    />
                  </Grid>
                  {state.spp && selected && (
                    <Grid item xs={12}>
                      <div className="text-bold mb-2">Uang Bulanan (SPP)</div>
                      <TablePembayaran
                        data={payable}
                        onChangePayableData={(data, name, value) =>
                          onChangePayableData(data, name, value)
                        }
                      />
                    </Grid>
                  )}
                  {state.lain && selected && (
                    <Grid item xs={12} className="mt-2 pb-4 separator">
                      <div className="text-bold mb-2">Lain-lain</div>
                      <div>
                        {others.map((data, index) => {
                          return (
                            <Grid
                              key={index}
                              container
                              spacing={3}
                              style={{ position: "relative" }}
                            >
                              <Grid item xs={12} md={6}>
                                <TextField
                                  name="nama"
                                  label="Nama Barang"
                                  placeholder="Isikan Nama Barang"
                                  className="mb-2"
                                  value={data.nama}
                                  onChange={(e) =>
                                    onChangeOthers(
                                      e.target.value,
                                      "nama",
                                      index
                                    )
                                  }
                                  required
                                />
                              </Grid>
                              <Grid item xs={12} md={6}>
                                <TextField
                                  type="number"
                                  name="harga"
                                  label="Harga"
                                  placeholder="Isikan Harga"
                                  className="mb-2"
                                  value={data.harga}
                                  onChange={(e) =>
                                    onChangeOthers(
                                      e.target.value,
                                      "harga",
                                      index
                                    )
                                  }
                                  required
                                />
                              </Grid>
                              <Grid
                                item
                                style={{
                                  position: "absolute",
                                  right: "-56px",
                                  top: 26,
                                }}
                              >
                                <IconButton onClick={() => deleteItem(index)}>
                                  <Icon style={{ color: "#DC6E6E" }}>
                                    delete
                                  </Icon>
                                </IconButton>
                              </Grid>
                            </Grid>
                          );
                        })}
                        <ButtonAddItem
                          onClick={addItem}
                          className="mt-4"
                          icon="add"
                        >
                          Tambah Item
                        </ButtonAddItem>
                      </div>
                    </Grid>
                  )}
                  {(state.spp || state.lain) && selected && (
                    <Grid item xs={12} md={6} className="mt-2 mb-4">
                      <TextField
                        type="number"
                        name="diskon"
                        label="Diskon"
                        placeholder="Masukkan nominal diskon, contoh: 50000"
                        className="mb-2"
                        value={state.diskon}
                        onChange={(e) => onChange(e.target.value, "diskon")}
                      />
                    </Grid>
                  )}
                </Grid>
                {(state.spp || state.lain) && selected && (
                  <Fragment>
                    <Grid item xs={12} className="mb-2">
                      <Grid container spacing={5} className="background-grey">
                        <Grid item xs={12} style={{ paddingBottom: 0 }}>
                          <button
                            type="button"
                            className="text-bold link-underline"
                            onClick={() => setDetails(!details)}
                          >
                            {details ? "TUTUP DETAILS" : "DETAILS"}{" "}
                            <i
                              className="material-icons"
                              style={{ transform: "translate(0px, 6px)" }}
                            >{`expand_${details ? "less" : "more"}`}</i>
                          </button>
                          {details && (
                            <TableSummary
                              data={{ ...payable, lain: others }}
                              lain={state.lain}
                              spp={state.spp}
                            />
                          )}
                        </Grid>
                        <Grid item xs={6}></Grid>
                        <Grid item xs={6}>
                          <Grid container spacing={3}>
                            <Grid item xs={6}>
                              Subtotal
                            </Grid>
                            <Grid item xs={6}>
                              {priceFormat(
                                payable.totalSpp + payable.totalOther
                              )}
                            </Grid>
                          </Grid>
                          <Grid container spacing={3}>
                            <Grid item xs={6}>
                              Diskon
                            </Grid>
                            <Grid item xs={6} style={{ position: "relative" }}>
                              <span style={{ position: "absolute", left: -1 }}>
                                -
                              </span>{" "}
                              {priceFormat(state.diskon || 0)}
                            </Grid>
                          </Grid>
                          <Grid container spacing={3}>
                            <Grid item xs={6} className="text-bold">
                              Total
                            </Grid>
                            <Grid item xs={6} className="text-bold">
                              {priceFormat(
                                payable.totalSpp +
                                  payable.totalOther -
                                  (state.diskon || 0)
                              )}
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid
                      className="pt-4"
                      container
                      spacing={3}
                      justify="flex-end"
                    >
                      <Grid item>
                        {state.loading ? (
                          <ButtonLoading>Memproses...</ButtonLoading>
                        ) : (
                          <ButtonPrimary type="submit">
                            Buat Pembayaran
                          </ButtonPrimary>
                        )}
                      </Grid>
                    </Grid>
                  </Fragment>
                )}
              </div>
            </div>
          </form>
        </Fragment>
      )}

      <Toastr />
    </Private>
  );
};

export default BuatPembayaran;
