/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useState, useEffect, useContext } from "react";
// import useDebounce from "../../../../helper/useDebounce";
import { listPendidikanOptionForRegisterURL } from "../../../../api/sekolah";
import Axios from "axios";
import { toast } from "react-toastify";
import Failed from "../../../../component/Misc/Failed";
import { Grid } from "@material-ui/core";
import SingleSelect from "../../../../component/Field/SingleSelect";
import { listTahunAjaranGetActiveOptionURL } from "../../../../api/tahunAjaran";
// import moment from "moment";
import { listKelasGetOptionURL } from "../../../../api/kelas";
import { sekolahInitial, ajaranInitial, kelasInitial } from "./SiswaInitialData";
import RegistrasiSiswaContext from "../../../../context/RegisterSiswa/RegisterSiswaContext";

const PaketSekolah = (props) => {
  const [sekolah, setSekolah] = useState(sekolahInitial);
  const [ajaran, setAjaran] = useState(ajaranInitial);
  const [kelas, setKelas] = useState(kelasInitial);
  const siswaContext = useContext(RegistrasiSiswaContext);


  useEffect(() => {
    const data = {
      jenjang_pendidikan: sekolah.selected,
      tahun_ajaran: ajaran.selected,
      kelas: kelas.selected,
    };

    props.setSekolah(data);
  }, [sekolah.selected, ajaran.selected, kelas.selected]);

  const getAllSekolahData = useCallback(async () => {
    setSekolah({
      ...sekolah,
      isLoading: true,
    });

    const urlParam = `${listPendidikanOptionForRegisterURL}`;

    try {
      const sekolah = await Axios.get(urlParam);
      if (sekolah) {
        // console.log("tahun ajaran", sekolah);
        setSekolah({
          ...sekolah,
          data: sekolah.data.data,
          total: sekolah.data.total,
          isLoading: false,
        });
      } else {
        const errorText = "Error";
        throw errorText;
      }
    } catch (error) {
      setSekolah({
        ...sekolah,
        isLoading: false,
      });
      toast(<Failed>{error}</Failed>);
    }
  }, []);

  useEffect(() => {
    getAllSekolahData();
  }, [getAllSekolahData]);

  const getAllTahunAjaranData = useCallback(async () => {
    setAjaran({
      ...ajaran,
      isLoading: true,
    });

    const urlParam = `${listTahunAjaranGetActiveOptionURL}`;

    try {
      const ajaran = await Axios.get(urlParam);
      if (ajaran) {
        // console.log("tahun ajaran", ajaran);

        setAjaran({
          ...ajaran,
          data: ajaran.data.data,
          total: ajaran.data.total,
          isLoading: false,
        });
      } else {
        const errorText = "Error";
        throw errorText;
      }
    } catch (error) {
      setAjaran({
        ...ajaran,
        isLoading: false,
      });
      toast(<Failed>{error}</Failed>);
    }
  }, []);

  useEffect(() => {
    getAllTahunAjaranData();
  }, [getAllTahunAjaranData]);

  const getAllKelasData = useCallback(async () => {
    setKelas({
      ...kelas,
      kelasLoading: true,
    });

    try {
      if (sekolah.selected && ajaran.selected) {
        const urlParam = `${listKelasGetOptionURL}/${sekolah.selected.value}/${ajaran.selected.value}`;

        const kelas = await Axios.get(urlParam);
        if (kelas) {
          // console.log("tahun ajaran", kelas);
          setKelas({
            ...kelas,
            data: kelas.data.data ? kelas.data.data : [],
            total: kelas.data.total,
            noOptionMessage: kelas.data.data
              ? "Tidak ada data yang ditemukan"
              : "Tidak ada kelas yang tersedia",
            kelasLoading: false,
            selected: null,
          });
        } else {
          const errorText = "Error";
          throw errorText;
        }
      } else {
        setKelas({
          ...kelas,
          data: [],
          selected: null,
          noOptionMessage:
            "Pilih Jenjang Pendidikan dan Tahun Ajaran terlebih dahulu!",
          kelasLoading: false,
        });
      }
    } catch (error) {
      setKelas({
        ...kelas,
        kelasLoading: false,
      });
      toast(<Failed>{error}</Failed>);
    }
  }, [sekolah.selected, ajaran.selected]);

  useEffect(() => {
    getAllKelasData();
  }, [getAllKelasData]);

  useEffect(() => {
    if(siswaContext.isReset) {
      setSekolah(sekolahInitial);
      setAjaran(ajaranInitial);
      setKelas(kelasInitial);
    }
  }, [siswaContext.isReset])

  return (
    <div className="container pt-4 pb-4">
      <div className="text-bold text-uppercase color-ocean-blue mb-4">
        Sekolah
      </div>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <SingleSelect
            name="jenjang-pendidikan"
            label="Jenjang Pendidikan"
            placeholder="Pilih Jenjang Pendidikan"
            className="mb-2"
            value={sekolah.selected}
            onChange={(e) => setSekolah({ ...sekolah, selected: e })}
            options={sekolah.data}
            isClearable
            required
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <SingleSelect
            name="tahun-ajaran"
            label="Tahun Ajaran"
            placeholder="Pilih Tahun Ajaran"
            className="mb-2"
            value={ajaran.selected}
            onChange={(e) => setAjaran({ ...ajaran, selected: e })}
            options={ajaran.data}
            isClearable
            required
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <SingleSelect
            name="kelas"
            label="Kelas"
            placeholder="Pilih Kelas"
            className="mb-2"
            value={kelas.selected}
            onChange={(e) => {
              if (sekolah.selected && ajaran.selected) {
                setKelas({ ...kelas, selected: e });
              }
            }}
            options={kelas.data}
            isLoading={kelas.kelasLoading}
            noOptionsMessage={kelas.noOptionMessage}
            isClearable
            required
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default PaketSekolah;
