import React from 'react';

const ButtonSecondary = props => {
    return (
        <button
            type={props.type || 'button'}
            className={`link-outlined ${props.className}`}
            style={{...props.style, borderColor: props.color || '#25345A', color: props.color || '#25345A'}}
            onClick={props.onClick}
        >
            {props.icon && <i className="material-icons">{props.icon}</i>}
            {props.children}
        </button>
    );
};

export default ButtonSecondary;