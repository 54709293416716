/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useCallback } from "react";
import Breadcrumb from "../../../../component/Navigation/Breadcrumb";
import Private from "../../../../layout/Private";
import TablePagination from "../../../../component/Navigation/TablePagination";
import MaterialTable from "material-table";
import { Grid, Tooltip } from "@material-ui/core";
import LinkOutlined from "../../../../component/Button/LinkOutlined";
import { useLocation, Link } from "react-router-dom";
import { toast } from "react-toastify";
import Failed from "../../../../component/Misc/Failed";
import Success from "../../../../component/Misc/Success";
import Toastr from "../../../../component/Misc/Toastr";
import Axios from "axios";
import { errorMessage, deleteMessage } from "../../../../helper/Static";
import priceFormat from "../../../../helper/PriceFormat";
import { listKursusURL, deleteKursusURL } from "../../../../api/kursus";
import moment from "moment";
import TextField from "../../../../component/Field/TextField";
import useDebounce from "../../../../helper/useDebounce";

const Kursus = props => {
  const location = useLocation();
  const [state, setState] = useState({
    breadcrumb: [
      {
        name: "EEC",
        url: "/dashboard",
        active: false,
      },
      {
        name: "Pengaturan",
        url: "/pengaturan",
        active: false,
      },
      {
        name: "Kursus",
        url: "/pengaturan/kursus",
        active: true,
      },
    ],
    kursus: [],
    total: '',
    isLoading: true,
  });

  const [page, setPage] = useState(1);
  const [search, setSearch] = useState('');
  const debouncedSearch = useDebounce(search, 500);

  const getAllData = useCallback(async () => {
    setState({
      ...state,
      isLoading: true,
    });

    const urlParam = `${listKursusURL}?page=${page}${search ? `&search=${debouncedSearch}` : ''}`

    try {
      const kursus = await Axios.get(urlParam);
      if (kursus) {
        // console.log("tahun ajaran", kursus);
        setState({
          ...state,
          kursus: kursus.data.data,
          total: kursus.data.total,
          isLoading: false,
        });
      } else {
        const errorText = "Error";
        throw errorText;
      }
    } catch (error) {
      setState({
        ...state,
        isLoading: false,
      });
      toast(<Failed>{error}</Failed>);
    }
  }, [page, debouncedSearch]);

  useEffect(() => {
    getAllData();
  }, [getAllData]);

  useEffect(() => {
    if (location.toast) {
      toast(<Success>{location.toast}</Success>);
    }
  }, [])

  const deleteKursus = async (id, text) => {
    if (window.confirm(deleteMessage(`kursus ${text}`))) {
      try {
        const deleting = await Axios.post(deleteKursusURL(id));
        if (deleting) {
          if (!deleting.data.error) {
            toast(<Success>{deleting.data.data}</Success>);
            getAllData();
          } else {
            throw deleting.data.error;
          }
        } else {
          throw errorMessage("menghapus kursus");
        }
      } catch (error) {
        toast(<Failed>{error}</Failed>);
        setState({
          ...state,
          isLoading: false,
        });
      }
    }
  };

  const onChangeSearch = (e) => {
    setSearch(e.target.value)
    setPage(1)
  }

  return (
    <Private {...props}>
      <Breadcrumb data={state.breadcrumb} />
      <div className="wrapper table-wrapper">
        <Grid container className="mb-3" spacing={3} justify="flex-end">
          <Grid item>
            <LinkOutlined address="/pengaturan/kursus/baru" icon="add">
              TAMBAH KURSUS
            </LinkOutlined>
          </Grid>
          <Grid item xs={12} lg={4}>
            <TextField 
              value={search}
              placeholder="Cari Kursus"
              onChange={e => onChangeSearch(e)}
            />
          </Grid>
        </Grid>
        <div className="table-title">Daftar Kursus</div>
        <MaterialTable
          columns={[
            {
              title: "Pendidikan",
              field: "nama",
            },
            {
              title: "Biaya",
              field: "biaya",
              render: (rowData) => <div>{priceFormat(rowData.biaya)}</div>,
            },
            {
              title: "Jadwal Pembelajaran",
              field: "tanggal_mulai",
              render: (rowData) => (
                <div>
                  {moment(new Date(rowData.tanggal_mulai)).format(
                    "MMMM YYYY"
                  )}{" "}
                  -{" "}
                  {moment(new Date(rowData.tanggal_selesai)).format("MMMM YYYY")}
                </div>
              ),
            },
            // {
            //   title: "Kelas",
            //   field: "kelas",
            //   render: (rowData) => <div>{rowData.jumlah_kelas} kelas</div>,
            // },

            {
              title: "",
              render: (rowData) => {
                return (
                  <div className="table-action">
                    <Tooltip title="Edit kursus" arrow>
                    <Link to={`/pengaturan/kursus/${rowData.id}/edit`}>
                        <i className="material-icons table-edit">edit</i>
                      </Link>
                    </Tooltip>

                    <Tooltip title="Hapus kursus" arrow>
                      <button
                        onClick={() => deleteKursus(rowData.id, rowData.nama)}
                      >
                        <i className="material-icons table-delete">delete</i>
                      </button>
                    </Tooltip>
                  </div>
                );
              },
            },

            {
              title: "id",
              field: "id",
              hidden: true,
            },
            {
              title: "end",
              field: "tanggal_selesai",
              hidden: true,
            },
          ]}
          isLoading={state.isLoading}
          data={state.kursus}
          options={{
            toolbar: false,
            padding: "dense",
            pageSize: 10,
          }}
          components={{
            Pagination: (props) => {
              // console.log("pagination props", props);
              return (
                <td>
                  <TablePagination
                    onChangePage={(e) => setPage(e)}
                    total={state.total}
                    page={page}
                    itemShow={props.rowsPerPage}
                  />
                </td>
              );
            },
          }}
        />
      </div>
      <Toastr />
    </Private>
  );
};

export default Kursus;
