import checkEnv from "./index";

const baseRoute = checkEnv();

// GET
const listLaporanURL = `${baseRoute}/laporan/get-all`;


export {
    listLaporanURL,
};
