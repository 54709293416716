import React, { useState } from "react";
import moment from 'moment';
import Private from "../../../../layout/Private";
import Breadcrumb from "../../../../component/Navigation/Breadcrumb";
import {
  Grid,
  RadioGroup,
  FormControlLabel,
  Radio,
  makeStyles,
} from "@material-ui/core";
import DateRange from "../../../../component/Field/DateRange";
import ButtonSecondary from "../../../../component/Button/ButtonSecondary";
import ButtonPrimary from "../../../../component/Button/ButtonPrimary";
import Axios from "axios";
import { createTahunAjaranURL } from "../../../../api/tahunAjaran";
import { toast } from "react-toastify";
import Failed from "../../../../component/Misc/Failed";
import ButtonLoading from "../../../../component/Button/ButtonLoading";
import { useHistory } from "react-router-dom";
import { cancelMessage } from "../../../../helper/Static";
import Toastr from "../../../../component/Misc/Toastr";

const useStyles = makeStyles({
  radio: {
    "&$checked": {
      color: "#2a2a2a",

      "&:hover": {
        backgroundColor: `rgba(0,0,0, 0.04)`,
      },
    },
    "&:hover": {
      backgroundColor: `rgba(0,0,0, 0.04)`,
    },
  },
  checked: {},
});

const TambahTahunAjaran = props => {
  const history = useHistory();

  const [state, setState] = useState({
    startDate: new Date(),
    endDate: new Date(),
    status: "aktif",
    breadcrumb: [
      {
        name: "EEC",
        url: "/dashboard",
        active: false,
      },
      {
        name: "Pengaturan",
        url: "/pengaturan",
        active: false,
      },
      {
        name: "Tahun Ajaran",
        url: "/pengaturan/tahun-ajaran",
        active: false,
      },
      {
        name: "Input Tahun Ajaran",
        url: "/pengaturan/tahun-ajaran/baru",
        active: true,
      },
    ],
  });

  const onChange = (value, name) => {
    setState({
      ...state,
      [name]: value,
    });
  };

  const cancel = () => {
    if(window.confirm(cancelMessage(`tambah tahun ajaran baru`))){
      history.push("/pengaturan/tahun-ajaran")
    }
  };

  const submitTahunAjaran = async (e) => {
    e.preventDefault();
    setState({
      ...state,
      loading: true,
    });

    try {
      const submit = await Axios.post(createTahunAjaranURL, {
        tanggal_mulai: moment(state.startDate).format('YYYY-MM'),
        tanggal_selesai: moment(state.endDate).format('YYYY-MM'),
        status: state.status,
      });

      console.log('submit', submit)

      if (submit) {
        if (!submit.data.error) {
          history.push({
            pathname: "/pengaturan/tahun-ajaran",
            toast: "Berhasil menambahkan tahun ajaran baru",
          });
          setState({
            ...state,
            loading: false,
          });
        } else {
          throw submit.data.error;
        }
      } else {
        const errorText =
          "Tidak dapat membuat tahun ajaran untuk saat ini. Mohon coba kembali!";
        throw errorText;
      }
    } catch (error) {
      console.log("LOGIN ERROR", error);
      toast(<Failed>{error}</Failed>);
      setState({
        ...state,
        loading: false,
      });
    }
  };

  console.log("state tahun ajaran", state);
  const classes = useStyles();

  return (
    <Private {...props}>
      <Breadcrumb data={state.breadcrumb} />
      <div className="wrapper-form">
        <div className="header-title">TAMBAH TAHUN AJARAN</div>
      </div>
      <form onSubmit={(e) => submitTahunAjaran(e)}>
        <div className="wrapper">
          <div className="container">
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <DateRange
                  label="Periode Tahun Ajaran"
                  name="tahun-ajaran"
                  startDate={state.startDate}
                  endDate={state.endDate}
                  setStartDate={(value) => onChange(value, "startDate")}
                  setEndDate={(value) => onChange(value, "endDate")}
                  dateFormat="MMMM yyyy"
                  showMonthYearPicker
                  required
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <label htmlFor="tahun-ajaran-status" className="input-label">
                  <span style={{ color: "#FF615B" }}>*</span>
                  <span>Status</span>
                </label>
                <RadioGroup
                  aria-label="tahun-ajaran-status"
                  name="tahun-ajaran-status"
                  value={state.status}
                  onChange={(e) => onChange(e.target.value, "status")}
                  style={{ flexDirection: "row" }}               
                >
                  <FormControlLabel
                    value="aktif"
                    control={
                      <Radio
                        disableRipple
                        classes={{
                          root: classes.radio,
                          checked: classes.checked,
                        }}
                      />
                    }
                    label="Aktif"
                    style={{ width: "fit-content" }}
                  />
                  <FormControlLabel
                    value="non-aktif"
                    control={
                      <Radio
                        disableRipple
                        classes={{
                          root: classes.radio,
                          checked: classes.checked,
                        }}
                      />
                    }
                    label="Non-Aktif"
                    style={{ width: "fit-content" }}
                  />
                </RadioGroup>
              </Grid>
            </Grid>
            <Grid className="mt-4" container spacing={3} justify="flex-end">
              <Grid item>
                <ButtonSecondary onClick={cancel}>Batalkan</ButtonSecondary>
              </Grid>
              <Grid item>
              {state.loading ? (
                  <ButtonLoading>Memproses...</ButtonLoading>
                ) : (
                  <ButtonPrimary type="submit">Tambah Tahun Ajaran</ButtonPrimary>
                )}
              </Grid>
            </Grid>
          </div>
        </div>
      </form>
      <Toastr />
    </Private>
  );
};

export default TambahTahunAjaran;
