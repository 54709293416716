import React from "react";

const Success = props => {
  return (
    <div className="toastr toastr-success">
      <i className="material-icons">check_circle</i>
      <span>{props.children}</span>
    </div>
  );
};

export default Success;
