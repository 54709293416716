import React from "react";
import { Popover } from "@material-ui/core";

const DropdownFilter = (props) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  return (
    <div style={{
        ...props.style,
        paddingLeft: props.pl || 0,
        paddingRight: props.pr || 0,
        borderRight: props.borderRight ? 'solid 1px #80848D' : 0,
        borderLeft: props.borderLeft ? 'solid 1px #80848D' : 0,
      }}>
      <button
        className="d-flex"
        onClick={handleClick}
        
      >
        <span>{props.title}</span>
        <i className="material-icons">arrow_drop_down</i>
      </button>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        PaperProps={{
            elevation: 2,
        }}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
      >
        {props.filter}
      </Popover>
    </div>
  );
};

export default DropdownFilter;
