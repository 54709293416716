import React from 'react';
import Navbar from '../Navigation/Navbar';

const MainContent = props => {
    return (
        <div className={`main-content ${props.status.main}`}>
            <Navbar {...props} />
            <div className="main-content-bg">
            {props.children}

            </div>
        </div>
    );
};

export default MainContent;