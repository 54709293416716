import React, { Component } from 'react';
import UserContext from './UserContext';
import Axios from 'axios';
import { userFindByIdURL } from '../../api/user';
import checkRole from '../../route/CheckRole';

class UserProvider extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user: {},
            baseRoute: '',
            isLoading: true,
        }
    }

    async componentDidMount() {
        const { data } = this.props;
        try {
            const user = await Axios.get(userFindByIdURL(data.sub));
            if (user.data) {
              console.log("tahun ajaran", user);
              const checkCurrentRole = checkRole(user.data.data.role, 'dashboard');
              this.setState({
                user: user.data.data,
                baseRoute: checkCurrentRole.baseRoute,
                isLoading: false,
              });
            } else {
              const errorText = "Error";
              throw errorText;
            }
          } catch (error) {
            this.setState({
              isLoading: false,
            });
           
          }
    }
    
    render() {
        const { user, isLoading } = this.state;
        console.log('props', this.props)
        return (
            <UserContext.Provider
                value={{
                    user,
                    isLoading,
                }}
            >
                {this.props.children}
            </UserContext.Provider>
        );
    }
}

export default UserProvider;