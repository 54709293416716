/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useCallback, Fragment, useContext } from "react";
import Breadcrumb from "../../../../component/Navigation/Breadcrumb";
import Private from "../../../../layout/Private";
import TablePagination from "../../../../component/Navigation/TablePagination";
import MaterialTable from "material-table";
import { Grid, Popover } from "@material-ui/core";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import Failed from "../../../../component/Misc/Failed";
import Success from "../../../../component/Misc/Success";
import Toastr from "../../../../component/Misc/Toastr";
import Axios from "axios";
// import { errorMessage, deleteMessage } from "../../../../helper/Static";
// import { deleteKelasURL, listKelasURL } from "../../../../api/kelas";
import TextField from "../../../../component/Field/TextField";
import useDebounce from "../../../../helper/useDebounce";
import {
  listPembayaranURL,
  batalkanPembayaranURL,
} from "../../../../api/pembayaran";
import priceFormat from "../../../../helper/PriceFormat";
import ButtonSecondary from "../../../../component/Button/ButtonSecondary";
import { errorMessage } from "../../../../helper/Static";
import moment from "moment";
import MaterialDateRange from "../../../../component/Field/MaterialDateRange";
import ButtonPrimary from "../../../../component/Button/ButtonPrimary";
import UserContext from "../../../../context/User/UserContext";

const DaftarPembayaran = (props) => {
  const user = useContext(UserContext)
  console.log("user", user)
  const location = useLocation();
  const [state, setState] = useState({
    breadcrumb: [
      {
        name: "EEC",
        url: "/dashboard",
        active: false,
      },
      {
        name: "Pengaturan",
        url: "/pengaturan",
        active: false,
      },
      {
        name: "Kelas",
        url: "/pengaturan/kelas",
        active: true,
      },
    ],
    kelas: [],
    total: "",
    isLoading: true,
  });

  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const debouncedSearch = useDebounce(search, 500);

  const [anchorEl, setAnchorEl] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [status, setStatus] = useState("today");

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const getAllData = useCallback(
    async (data) => {
      setState({
        ...state,
        isLoading: true,
      });

      const urlParam = `${listPembayaranURL}?page=${page}${
        search ? `&search=${debouncedSearch}` : ""
      }`;

      const checkFilter = {
        dari_tanggal:
          data.status === "today"
            ? moment(new Date()).startOf("month").format("YYYY-MM-DD")
            : moment(new Date(data.startDate)).format("YYYY-MM-DD"),
        hingga_tanggal:
          data.status === "today"
            ? moment(new Date()).endOf("month").format("YYYY-MM-DD")
            : moment(new Date(data.endDate)).format("YYYY-MM-DD"),
      };

      try {
        const pembayaran = await Axios.get(urlParam, {
          params: checkFilter,
        });

        console.log("pembayaran", pembayaran);

        if (pembayaran) {
          setState({
            ...state,
            kelas:
              pembayaran.data.data?.map((pay, index) => {
                const getContent = pay.items.map((item, idx) => {
                  const objKey = Object.keys(item);

                  return objKey.map((key) => {
                    return {
                      detail: `${item[key].qty} x ${item[key].tipe}-${item[key].nama} @ ${item[key].harga}`,
                      range: `${moment(new Date(item[key].bulan_mulai)).format(
                        "MMMM YYYY"
                      )} - ${moment(new Date(item[key].bulan_selesai)).format(
                        "MMMM YYYY"
                      )}`,
                      total: `${item[key].jumlah},-`,
                    };
                  });
                });

                console.log("content", getContent);
                return {
                  ...pay,
                  resi: {
                    nama_siswa: pay.nama_siswa,
                    kasir: pay.cashier.name,
                    no: pay.nomor_resi,
                    subtotal: `${priceFormat(pay.subtotal)},-`,
                    diskon: pay.diskon
                      ? `${priceFormat(pay.diskon)},-`
                      : "Rp. 0,-",
                    total: `${priceFormat(pay.jumlah)},-`,
                    tanggal: pay.created_at,
                    detail: getContent[0],
                  },
                };
              }) || [],
            total: pembayaran.data.total,
            isLoading: false,
          });
        } else {
          throw new Error("Tidak dapat memuat data pembayaran");
        }
      } catch (error) {
        setState({
          ...state,
          isLoading: false,
        });
        toast(<Failed>{error}</Failed>);
      }
    },
    [page, debouncedSearch]
  );

  useEffect(() => {
    getAllData({ status, startDate, endDate });
  }, [getAllData]);

  useEffect(() => {
    if (location.toast) {
      toast(<Success>{location.toast}</Success>);
    }
  }, []);

  const batalkanPembayaran = async (id) => {
    const reason = window.prompt(`Isikan alasan pembatalan pembayaran ${id}`);
    if (reason) {
      setState({
        ...state,
        isLoading: true,
      });
      try {
        const canceling = await Axios.post(batalkanPembayaranURL(id), {
          reason,
        });
        if (canceling) {
          if (!canceling.data.error) {
            toast(<Success>{canceling.data.data}</Success>);
            getAllData({ status, startDate, endDate });
          } else {
            throw new Error(canceling.data.error);
          }
        } else {
          throw new Error(errorMessage("membatalkan pembayaran"));
        }
      } catch (error) {
        toast(<Failed>{error.message}</Failed>);
        setState({
          ...state,
          isLoading: false,
        });
      }
    }
  };

  const onChangeSearch = (e) => {
    setSearch(e.target.value);
    setPage(1);
  };

  console.log("pendaftaraaan", status);

  return (
    <Private {...props}>
      <Breadcrumb data={state.breadcrumb} />
      <div className="wrapper table-wrapper">
        <Grid container className="mb-3" spacing={3} justify="space-between">
          <Grid item xs={12} lg={4}>
            <button
              className={`button-filter ${status === "today" ? "active" : ""}`}
              onClick={() => {
                setStatus("today");
                getAllData({ status: "today", startDate, endDate });
              }}
            >
              Bulan ini
            </button>
            <button
              className={`button-filter ${status === "date" ? "active" : ""}`}
              onClick={handleClick}
            >
              Tanggal
            </button>
            <Popover
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              PaperProps={{
                elevation: 2,
              }}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
            >
              <div className="p-3 d-flex align-items-end">
                <MaterialDateRange
                  label="Periode"
                  startDate={startDate}
                  endDate={endDate}
                  startPlaceholder="Dari tanggal"
                  endPlaceholder="Sampai tanggal"
                  setStartDate={(e) => setStartDate(e)}
                  setEndDate={(e) => setEndDate(e)}
                />
                <ButtonPrimary
                  className="ml-2"
                  style={{ width: "fit-content" }}
                  onClick={() => {
                    setStatus("date");
                    handleClose();
                    getAllData({ status: "date", startDate, endDate });
                  }}
                >
                  Filter
                </ButtonPrimary>
              </div>
            </Popover>
          </Grid>
          <Grid item xs={12} lg={4}>
            <TextField
              value={search}
              placeholder="Cari Pembayaran"
              onChange={(e) => onChangeSearch(e)}
            />
          </Grid>
        </Grid>
        <div className="table-title">
          Daftar Pembayaran{" "}
          {status === "today"
            ? moment(new Date()).format("MMMM YYYY")
            : `${moment(new Date(startDate)).format("DD MMMM YYYY")} - ${moment(
                new Date(endDate)
              ).format("DD MMMM YYYY")}`}
        </div>
        <MaterialTable
          columns={[
            {
              title: "Tanggal & Waktu",
              field: "created_at",
              render: (rowData) => (
                <div
                  style={{
                    color: rowData.status === "valid" ? "#2a2a2a" : "#80848D",
                  }}
                >
                  {moment(new Date(rowData.created_at)).format(
                    "DD MMMM YYYY hh:mm:ss"
                  )}
                </div>
              ),
            },
            {
              title: "No. Resi",
              field: "nomor_resi",
              render: (rowData) => (
                <Fragment>
                  {rowData.status === "valid" ? (
                    <div>
                      <a
                        href={`/pembayaran/resi/${rowData.nomor_resi}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="link-underline text-bold"
                      >
                        {rowData.nomor_resi}
                      </a>

                      {/* <button onClick={() => cetakResi(rowData.nomor_resi)}>
                        <span className="link-underline text-bold">
                          {rowData.nomor_resi}
                        </span>
                      </button> */}
                    </div>
                  ) : (
                    <div style={{ color: "#80848D" }}>{rowData.nomor_resi}</div>
                  )}
                </Fragment>
              ),
            },
            {
              title: "Nama Siswa",
              field: "nama_siswa",
              render: (rowData) => (
                <div
                  style={{
                    color: rowData.status === "valid" ? "#2a2a2a" : "#80848D",
                  }}
                >
                  {rowData.nama_siswa}
                </div>
              ),
            },
            {
              title: "Pembayaran",
              field: "items",
              width: 200,
              render: (rowData) => {
                return (
                  <div
                    style={{
                      color: rowData.status === "valid" ? "#2a2a2a" : "#80848D",
                    }}
                  >
                    {rowData.items.map((item, index) => {
                      const objKey = Object.keys(item);

                      return (
                        <div key={index}>
                          {objKey.map((key, idx) => {
                            return (
                              <div key={idx} className="mb-3">
                                <div>
                                  <span className="text-bold">
                                    {item[key].tipe}
                                  </span>{" "}
                                  - {item[key].nama}
                                </div>
                                {(item[key].tipe === "Sekolah" ||
                                  item[key].tipe === "Kursus") && (
                                  <div>
                                    {moment(
                                      new Date(item[key].bulan_mulai)
                                    ).format("MMMM YYYY")}{" "}
                                    -{" "}
                                    {moment(
                                      new Date(item[key].bulan_selesai)
                                    ).format("MMMM YYYY")}{" "}
                                  </div>
                                )}
                                <div style={{ color: "#80848D" }}>
                                  {item[key].jumlah}
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      );
                    })}
                  </div>
                );
              },
            },
            {
              title: "Jumlah Pembayaran",
              field: "jumlah",
              render: (rowData) => (
                <div
                  style={{
                    color: rowData.status === "valid" ? "#2a2a2a" : "#80848D",
                  }}
                >
                  {!!rowData.diskon && (
                    <div style={{ fontSize: 12 }}>
                      Diskon: {priceFormat(rowData.diskon)}
                    </div>
                  )}
                  <div>{priceFormat(rowData.jumlah)}</div>
                </div>
              ),
            },
            {
              title: "",
              hidden: user?.user.role === "Kasir",
              render: (rowData) => {
                return (
                  <div className="table-action">
                    {rowData.status === "valid" ? (
                      <ButtonSecondary
                        icon="cancel_presentation"
                        color="#DC6E6E"
                        borderColor="#DC6E6E"
                        onClick={() => batalkanPembayaran(rowData.nomor_resi)}
                        style={{ padding: "0px 16px" }}
                      >
                        Batal
                      </ButtonSecondary>
                    ) : (
                      <div className="d-block" style={{ color: "#80848D" }}>
                        <div className="text-bold">
                          DIBATALKAN <br />
                        </div>
                        <div>Alasan: </div>
                        <div>{rowData.reason}</div>
                      </div>
                    )}
                  </div>
                );
              },
            },

            {
              title: "id",
              field: "id",
              hidden: true,
            },
            {
              title: "kursus",
              field: "kursus",
              hidden: true,
            },
          ]}
          isLoading={state.isLoading}
          data={state.kelas}
          options={{
            toolbar: false,
            padding: "dense",
            pageSize: 10,
          }}
          components={{
            Pagination: (props) => {
              // console.log("pagination props", props);
              return (
                <td>
                  <TablePagination
                    onChangePage={(e) => setPage(e)}
                    total={state.total}
                    page={page}
                    itemShow={props.rowsPerPage}
                  />
                </td>
              );
            },
          }}
        />
      </div>
      <Toastr />
    </Private>
  );
};

export default DaftarPembayaran;
