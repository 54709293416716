import React from 'react';
import { Link } from 'react-router-dom';

const LinkOutlined = props => {
    return (
        <Link
            className={`link-outlined ${props.className}`}
            style={props.style}
            to={props.address}
        >
            {props.icon && <i className="material-icons">{props.icon}</i>}
            {props.children}
        </Link>
    );
};

export default LinkOutlined;