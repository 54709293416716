import React from "react";

const TextField = props => {
  return (
    <>
      {props.label && (
        <label htmlFor={props.name} className="input-label">
          {props.required && <span style={{ color: "#FF615B" }}>*</span>}
          {props.label}
        </label>
      )}
      <input
        value={props.value}
        name={props.name}
        style={props.style}
        className={`input-default ${props.className}`}
        id={props.id}
        type={props.type || "text"}
        placeholder={props.placeholder}
        required={props.required || null}
        onChange={props.onChange}
        onKeyUp={props.onKeyUp}
        minLength={props.minLength}
        max={props.max}
        disabled={props.disabled}
      />
    </>
  );
};

export default TextField;