/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useCallback, Fragment } from "react";
import Private from "../../../layout/Private";
import Breadcrumb from "../../../component/Navigation/Breadcrumb";
import { Grid } from "@material-ui/core";
import queryString from "query-string";
// import ButtonSecondary from "../../../../component/Button/ButtonSecondary";
import Axios from "axios";
import { toast } from "react-toastify";
import Failed from "../../../component/Misc/Failed";
import { useRouteMatch, useLocation } from "react-router-dom";
// import { cancelMessage, errorMessage } from "../../../../helper/Static";
import Toastr from "../../../component/Misc/Toastr";
import { findByKelasURL, assignKeKelasURL } from "../../../api/siswa";
import MaterialTable from "material-table";
import StatusTag from "../../../component/Misc/StatusTag";
import moment from "moment";
import LinkOutlined from "../../../component/Button/LinkOutlined";
import Loading from "../../../component/Misc/Loading";
import { findIdKelasURL, findKelasByPendidikanURL } from "../../../api/kelas";
import Modal from "../../../component/Content/Modal";
import SingleSelect from "../../../component/Field/SingleSelect";
import ButtonPrimary from "../../../component/Button/ButtonPrimary";
import { listPendidikanURL } from "../../../api/sekolah";
import Success from "../../../component/Misc/Success";
import ButtonLoading from "../../../component/Button/ButtonLoading";

const DetailKelas = (props) => {
  const match = useRouteMatch();
  const location = useLocation();
  const query = queryString.parse(location.search);

  const [siswa, setSiswa] = useState({
    listSiswa: [],
    siswaLoading: false,
  });
  const [dataPendidikan, setDataPendidikan] = useState([]);
  const [dataKelas, setDataKelas] = useState([]);
  const [pendidikan, setPendidikan] = useState(null);
  const [kelas, setKelas] = useState(null);
  const [open, setOpen] = useState(false);
  const [mutasiId, setMutasiId] = useState(null);
  const [mutasiLoading, setMutasiLoading] = useState(false);
  const [pendidikanLoading, setPendidikanLoading] = useState(false);
  const [kelasLoading, setKelasLoading] = useState(false);

  const [state, setState] = useState({
    detailKelas: {},
    isLoading: false,
    breadcrumb: [
      {
        name: "EEC",
        url: "/dashboard",
        active: false,
      },
      {
        name: "Kelas",
        url: "/kelas-list",
        active: false,
      },
      {
        name: "Daftar Kelas",
        url: "/kelas-list/daftar",
        active: false,
      },
      {
        name: "",
        url: "/kelas-list/daftar",
        active: true,
      },
    ],
  });

  const getDetailKelas = async () => {
    setState({
      ...state,
      isLoading: true,
    });
    try {
      const kelas = await Axios.get(findIdKelasURL(match.params.id));

      if (kelas.data.error) {
        throw new Error(kelas.data.error);
      }

      if (kelas) {
        setState({
          ...state,
          detailKelas: kelas.data.data,
          breadcrumb: state.breadcrumb.map((data, i) => {
            if (i === 3) {
              return {
                ...data,
                name: kelas.data.data.nama,
                url: `/siswa/daftar/${match.params.id}`,
              };
            }
            return data;
          }),
          isLoading: false,
        });
      } else {
        throw new Error("Tidak dapat memuat detail kelas");
      }
    } catch (error) {
      setState({
        ...state,
        isLoading: false,
      });
      toast(<Failed>{error.message}</Failed>);
    }
  };

  const getAllSiswaByKelas = async () => {
    setSiswa({
      ...state,
      siswaLoading: true,
    });
    try {
      const kelas = await Axios.get(findByKelasURL(match.params.id));

      if (kelas.data.error) {
        throw new Error(kelas.data.error);
      }

      if (kelas) {
        setSiswa({
          ...state,
          listSiswa: kelas.data.data,
          siswaLoading: false,
        });
      } else {
        throw new Error("Tidak dapat memuat daftar siswa");
      }
    } catch (error) {
      setSiswa({
        ...state,
        siswaLoading: false,
      });
      toast(<Failed>{error.message}</Failed>);
    }
  };

  useEffect(() => {
    getDetailKelas();
    getAllSiswaByKelas();
  }, []);

  const getAllPendidikan = async () => {
    const urlParam = `${listPendidikanURL}`;

    try {
      setPendidikanLoading(true)
      const sekolah = await Axios.get(urlParam);
      if (sekolah) {
        console.log("tahun ajaran", sekolah);
        setDataPendidikan(
          sekolah.data.data.map((data) => ({
            value: data.id,
            label: data.nama,
          }))
        );
      } else {
        throw new Error("Tidak dapat memuat pendidikan!");
      }
    } catch (error) {
      toast(<Failed>{error.message}</Failed>);
    } finally {
      setPendidikanLoading(false)
    }
  };



  useEffect(() => {
    if (open) {
      getAllPendidikan();
    }
  }, [open]);

  const getAllKelasByPendidikan = useCallback(async (id) => {
    try {
      setKelasLoading(true)

      const fetch = await Axios.get(findKelasByPendidikanURL(id));
      if (fetch) {
        console.log("data", fetch);
        setDataKelas(
          fetch.data.data.map((res, i) => ({
            value: res.id,
            label: res.nama,
          })) || []
        );
      } else {
        throw new Error("Tidak dapat memuat kelas!");
      }
    } catch (error) {
      toast(<Failed>{error.message}</Failed>);
    } finally {
      setKelasLoading(false)
    }
  });

  useEffect(() => {
    if (pendidikan) {
      getAllKelasByPendidikan(pendidikan.value);
    }
  }, [pendidikan]);

  const mutasiSiswa = async (e) => {
    e.preventDefault(e);
    console.log("kelas", kelas);
    console.log("pendidikan", pendidikan);
    console.log("mutasi id", mutasiId);

    setMutasiLoading(true);

    try {
      const submit = await Axios.post(assignKeKelasURL, {
        id_siswa: mutasiId,
        id_kelas: kelas.value,
        id_pendidikan: pendidikan.value,
        type: "pindah"
      });

      if(submit.data.error) {
        throw new Error(submit.data.error)
      }

      if (submit) {
        setMutasiLoading(false);
        setKelas(null);
        setPendidikan(null);
        setMutasiId(null);
        setOpen(false);
        getAllSiswaByKelas();
        toast(<Success>Berhasil memutasikan siswa</Success>);
      }
    } catch (error) {
      setMutasiLoading(false);
      toast(<Failed>{error.message}</Failed>);
    }
  };

  return (
    <Private {...props}>
      <Breadcrumb data={state.breadcrumb} />
      <div className="wrapper-form edit">
        <Grid container justify="space-between" alignItems="center">
          <Grid item>
            <div className="header-title">
              {query?.state === "edit" ? "EDIT" : ""} DATA KELAS
            </div>
          </Grid>
          {query?.state !== "edit" ? (
            <Grid item>
              <LinkOutlined
                address={{
                  pathname: `/kelas-list/daftar/${match.params.id}`,
                  search: `state=edit`,
                }}
                icon="edit"
                color="#25345A"
              >
                EDIT
              </LinkOutlined>
            </Grid>
          ) : (
            <Grid item>
              <LinkOutlined
                address={{
                  pathname: `/kelas-list/daftar/${match.params.id}`,
                }}
                icon="arrow_back"
                color="#25345A"
              >
                KEMBALI
              </LinkOutlined>
            </Grid>
          )}
        </Grid>
      </div>

      <div className="wrapper table-wrapper">
        {state.isLoading ? (
          <Loading />
        ) : (
          <React.Fragment>
            <div className="container">
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <div className="color-grey font-sm">Nama Kelas</div>
                  <div className="text-bold">{state.detailKelas.nama}</div>
                </Grid>

                {state.detailKelas.pendidikan && (
                  <Grid item xs={12} md={6}>
                    <div className="color-grey font-sm">
                      Durasi Pembelajaran
                    </div>
                    <div className="text-bold">
                      {state.detailKelas.pendidikan.durasi_pembelajaran} bulan
                    </div>
                  </Grid>
                )}

                <Grid item xs={12} md={6}>
                  <div className="color-grey font-sm">
                    {state.detailKelas.kursus ? "Kursus" : "Pendidikan"}
                  </div>
                  <div className="text-bold">
                    {state.detailKelas.kursus?.nama}
                    {state.detailKelas.pendidikan?.nama}
                  </div>
                </Grid>

                {state.detailKelas.kursus && (
                  <Grid item xs={12} md={6}>
                    <div className="color-grey font-sm">
                      Jadwal Pembelajaran
                    </div>
                    <div className="text-bold">
                      {moment(
                        new Date(state.detailKelas.kursus.tanggal_mulai)
                      ).format("MMMM YYYY")}{" "}
                      -{" "}
                      {moment(
                        new Date(state.detailKelas.kursus.tanggal_selesai)
                      ).format("MMMM YYYY")}
                    </div>
                  </Grid>
                )}

                <Grid item xs={12} md={6}>
                  <div className="color-grey font-sm">Jumlah Siswa</div>
                  <div className="text-bold">
                    {state.detailKelas.kelas_siswa_count}
                  </div>
                </Grid>

                {/* <Grid item xs={12} md={6}>
                  <div className="color-grey font-sm">Tahun Ajaran</div>
                  <div className="text-bold">{state.detailKelas.alamat}</div>
                </Grid> */}
              </Grid>
            </div>
            <div className="separator-border pt-4 mb-4"></div>
            <p className="font-lg text-bold">Daftar Siswa</p>
            <MaterialTable
              columns={[
                {
                  title: "NIS",
                  field: "nis",
                },

                {
                  title: "Nama",
                  field: "nama_siswa",
                  width: 300,
                },
                {
                  title: "Status",
                  field: "status",
                  render: (rowData) => {
                    return (
                      <StatusTag
                        background={
                          rowData.status === "aktif" ? null : "#80848D"
                        }
                      >
                        {rowData.status === "aktif" ? "Aktif" : "Non-aktif"}
                      </StatusTag>
                    );
                  },
                },
                {
                  field: "id",
                  hidden: query.state !== "edit",
                  render: (rowData) => {
                    return (
                      <Fragment>
                        {rowData.status === "aktif" && <button
                        className="d-flex"
                        onClick={() => {
                          setOpen(true);
                          setMutasiId(rowData.id);
                        }}
                        type="button"
                      >
                        <i className="material-icons table-edit mr-2 color-midnight-blue">
                          exit_to_app
                        </i>
                        <span
                          className="color-midnight-blue link-underline text-bold"
                          style={{ transform: "translate(-8px, 3px)" }}
                        >
                          Mutasi Siswa
                        </span>
                      </button>}
                      </Fragment>
                    );
                  },
                },
              ]}
              isLoading={siswa.siswaLoading}
              data={siswa.listSiswa}
              options={{
                toolbar: false,
                padding: "dense",
                pageSize: 10,
                paging: false,
              }}
            />
          </React.Fragment>
        )}
      </div>

      <Modal
        open={open}
        handleClose={() => {
          setOpen(false);
          setMutasiId(null);
          setKelas(null);
        }}
        title="Mutasi Siswa"
      >
        <form onSubmit={(e) => mutasiSiswa(e)}>
          <div style={{ minWidth: 418 }}>
            <SingleSelect
              name="pendidikan"
              label="Pendidikan"
              placeholder="Isikan Pendidikan"
              className="mb-4"
              value={pendidikan}
              onChange={(e) => setPendidikan(e)}
              options={dataPendidikan}
              isLoading={pendidikanLoading}
              required
            />
            <SingleSelect
              name="kelas"
              label="Kelas"
              placeholder="Isikan Kelas"
              className="mb-4"
              value={kelas}
              onChange={(e) => setKelas(e)}
              options={dataKelas}
              isLoading={kelasLoading}
              required
            />
            {mutasiLoading ? (
              <ButtonLoading>Memproses...</ButtonLoading>
            ) : (
              <ButtonPrimary type="submit">Mutasi Siswa</ButtonPrimary>
            )}
          </div>
        </form>
      </Modal>

      <Toastr />
    </Private>
  );
};

export default DetailKelas;
