import checkEnv from "./index";

const baseRoute = checkEnv();

// GET
const listMainPenagihanURL = `${baseRoute}/penagihan/main-list`;

const detailPenagihanURL = (id) => {
  return `${baseRoute}/penagihan/detail-list/${id}`;
};

const invoiceURL = `${baseRoute}/penagihan/invoice`;


export {
    listMainPenagihanURL,
    detailPenagihanURL,
    invoiceURL
};
