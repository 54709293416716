/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useContext, useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import ButtonSecondary from "../../../../component/Button/ButtonSecondary";
import ButtonPrimary from "../../../../component/Button/ButtonPrimary";
import RegistrasiSiswaContext from "../../../../context/RegisterSiswa/RegisterSiswaContext";
import { createSiswaURL } from "../../../../api/siswa";
import ButtonLoading from "../../../../component/Button/ButtonLoading";
import { toast } from "react-toastify";
import moment from "moment";
import Failed from "../../../../component/Misc/Failed";
import TableCekRegistrasi from './TableCekRegistrasi';

const CekRegistrasi = (props) => {
  const [loading, setLoading] = useState(false);
  const siswaContext = useContext(RegistrasiSiswaContext);
  const [data, setData] = useState({
    loading: false,
    table: [],
  })

  const checkUlangRegistrasi = useCallback(async () => {
    if(props.active) {
     setData({...data, loading: true})
      const checking = await siswaContext.checkRegistrasi();
    
    if(!checking.error) {
      const manipulateData = [
        {
          type: 'Sekolah',
          data: checking.data.sekolah
        },
        {
          type: 'Kursus',
          data: checking.data.kursus
        },
        {
          type: 'Lain - lain',
          data: checking.data.lain
        }
      ]
      setData({...checking.data, table: manipulateData, loading: false});
    }
    }
  }, [props.active])

  useEffect(() => {
    checkUlangRegistrasi()
  }, [checkUlangRegistrasi])

  const submit = async () => {
    setLoading(true);

    try {
      const creating = await siswaContext.submitSiswa(createSiswaURL);
      console.log("creating", creating);
      if (!creating.error) {
        setLoading(false);
        siswaContext.onChangeArray("createdData", creating.data);
        siswaContext.onChangeArray("isCreated", true);
      } else {
        siswaContext.onChangeArray("isCreated", false);
        throw creating.error;
      }
    } catch (error) {
      setLoading(false);
      toast(<Failed>{error}</Failed>);
    }
  };

  return (
    <div style={{ width: "100%" }}>
      <div className="container pt-4 pb-4">
        <div
          className="text-bold color-ocean-blue mb-4"
          style={{ fontSize: 18 }}
        >
          Data Siswa
        </div>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <div className="color-grey font-sm">Nama Lengkap</div>
            <div className="text-bold">
              {siswaContext.dataSiswa.nama_siswa}
            </div>
          </Grid>

          <Grid item xs={12} md={6}>
            <div className="color-grey font-sm">Jenis Kelamin</div>
            <div className="text-bold">
              {siswaContext.dataSiswa.jenis_kelamin === "lk"
                ? "Laki - laki"
                : "Perempuan"}
            </div>
          </Grid>

          <Grid item xs={12} md={6}>
            <div className="color-grey font-sm">Tempat Lahir</div>
            <div className="text-bold">
              {siswaContext.dataSiswa.tempat_lahir}
            </div>
          </Grid>

          <Grid item xs={12} md={6}>
            <div className="color-grey font-sm">Tanggal Lahir</div>
            <div className="text-bold">
              {moment(
                new Date(siswaContext.dataSiswa.tanggal_lahir)
              ).format("DD MMMM YYYY")}
            </div>
          </Grid>

          <Grid item xs={12} md={6}>
            <div className="color-grey font-sm">Nama Orang Tua</div>
            <div className="text-bold">
              {siswaContext.dataSiswa.nama_ortu}
            </div>
          </Grid>

          <Grid item xs={12} md={6}>
            <div className="color-grey font-sm">Alamat</div>
            <div className="text-bold">
              {siswaContext.dataSiswa.alamat}
            </div>
          </Grid>
        </Grid>
        <div className="separator-border pt-4 mb-4"></div>

        <TableCekRegistrasi data={data} />
        <Grid className="mt-4" container spacing={3} justify="flex-end">
          <Grid item>
            <ButtonSecondary
              onClick={() => props.setBackPanel(1)}
              icon="chevron_left"
            >
              Kembali
            </ButtonSecondary>
          </Grid>
          <Grid item>
            {loading ? (
              <ButtonLoading>Memproses...</ButtonLoading>
            ) : (
              <ButtonPrimary onClick={submit}>Register Siswa</ButtonPrimary>
            )}
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default CekRegistrasi;
