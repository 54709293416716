/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useCallback, useEffect, useState } from "react";
import Private from "../../../layout/Private";
import Breadcrumb from "../../../component/Navigation/Breadcrumb";
import { Grid } from "@material-ui/core";
import DashboardChart from "./Chart";
import DashboardPieChart from "./DashboardPieChart";
import Axios from "axios";
import {
  countSiswaKursusURL,
  countSiswaSekolahURL,
  countTagihanTertunda,
  countTotalSiswaURL,
  pendapatanPengeluaranURL,
} from "../../../api/dashboard";
import Loading from "../../../component/Misc/Loading";
import SingleSelect from "../../../component/Field/SingleSelect";
import { listPendidikanURL } from "../../../api/sekolah";
import { toast } from "react-toastify";
import Failed from "../../../component/Misc/Failed";
import moment from "moment";
import PenagihanTable from "./PenagihanTable";
import priceFormat from "../../../helper/PriceFormat";

const Dashboard = (props) => {
  const [state] = useState({
    breadcrumb: [
      {
        name: "EEC",
        url: "/dashboard",
        active: false,
      },
      {
        name: "Beranda",
        url: "/dashboard",
        active: true,
      },
    ],
  });

  const [totalSiswa, setTotalSiswa] = useState({
    total: 0,
    as_per: "-",
    loading: false,
  });

  const [totalTagihan, setTotalTagihan] = useState({
    total: 0,
    as_per: moment(new Date()).format("MMMM YYYY"),
    loading: false,
  });

  const [siswaSekolah, setSiswaSekolah] = useState({
    isLoading: false,
    data: [],
  });

  const [siswaKursus, setSiswaKursus] = useState({
    isLoading: false,
    as_per: "-",
    jumlah_siswa: 0,
    data: [],
  });

  const [chartData, setChartData] = useState({
    isLoading: true,
    data: [],
    totalPengeluaran: 0,
    totalPendapatan: 0,
  });

  const [listPendidikan, setListPendidikan] = useState({
    isLoading: true,
    data: [
      {
        value: 'All',
        label: 'All'
      }
    ],
  });

  const [selectedPendidikan, setSelectedPendidikan] = useState(null);

  const getChartData = useCallback(async () => {
    try {
      const thisYear = moment(new Date()).format("YYYY");
      const chart = await Axios.get(pendapatanPengeluaranURL(thisYear));
      let pendapatan = [];
      const pendapatanData = chart.data.data.pendapatan;
      const pengeluaranData = chart.data.data.pengeluaran;

      for (const property in pendapatanData) {
        const merged = {
          name: moment(`1/${property}/2020`, "D/M/YYYY").format("MMMM"),
          Pendapatan: pendapatanData[property],
          Pengeluaran: pengeluaranData[property],
        };
        pendapatan.push(merged);
      }

      setChartData({
        ...chartData,
        totalPengeluaran: chart.data.data.totalPengeluaran,
        totalPendapatan: chart.data.data.totalPendapatan,
        data: pendapatan,
        isLoading: false,
      });
    } catch (error) {
      toast(<Failed>{error.message}</Failed>);
      // setSiswaSekolah({
      //   ...siswaSekolah,
      //   isLoading: false,
      // });
    }
  }, []);

  useEffect(() => {
    getChartData();
  }, [getChartData]);

  const getTotalSiswa = useCallback(async () => {
    try {
      setTotalSiswa({
        ...totalSiswa,
        loading: true,
      });
      const siswa = await Axios.get(countTotalSiswaURL);

      if (siswa.data.error) {
        throw new Error(siswa.data.error);
      }

      setTotalSiswa({
        ...siswa.data.data,
        loading: false,
      });
    } catch (error) {
      toast(<Failed>{error.message}</Failed>);
      setTotalSiswa({
        ...totalSiswa,
        loading: false,
      });
    }
  }, []);

  useEffect(() => {
    getTotalSiswa();
  }, [getTotalSiswa]);

  const getTotalTagihan = useCallback(async () => {
    try {
      setTotalTagihan({
        ...totalTagihan,
        loading: true,
      });
      const tagihan = await Axios.get(countTagihanTertunda);

      if (tagihan.data.error) {
        throw new Error(tagihan.data.error);
      }

      setTotalTagihan({
        ...totalTagihan,
        total: tagihan.data.data.total.total || 0,
        loading: false,
      });
    } catch (error) {
      toast(<Failed>{error.message}</Failed>);
      setTotalTagihan({
        ...totalTagihan,
        loading: false,
      });
    }
  }, []);

  useEffect(() => {
    getTotalTagihan();
  }, [getTotalTagihan]);

  const getSiswaSekolah = useCallback(async () => {
    try {
      const siswa = await Axios.get(countSiswaSekolahURL);

      if (siswa.data.error) {
        throw new Error(siswa.data.error);
      }

      let arr = [];
      const pieData = siswa.data.data.pendidikan;
      for (const property in pieData) {
        arr.push({ value: pieData[property], name: property });
      }

      setSiswaSekolah((s) => ({
        ...s,
        ...siswa.data.data,
        data: arr,
        isLoading: false,
      }));
    } catch (error) {
      toast(<Failed>{error.message}</Failed>);
      setSiswaSekolah({
        ...siswaSekolah,
        isLoading: false,
      });
    }
  }, []);

  useEffect(() => {
    getSiswaSekolah();
  }, [getSiswaSekolah]);

  const getSiswaKursus = useCallback(async (pendidikan) => {
    try {
      setSiswaKursus((s) => ({
        ...s,
        isLoading: true,
      }));

      const siswa = await Axios.get(countSiswaKursusURL(pendidikan));

      if (siswa.data.error) {
        throw new Error(siswa.data.error);
      }

      let arr = [];
      const pieData = siswa.data.data.kursus;
      for (const property in pieData) {
        arr.push({ value: pieData[property], name: property });
      }

      setSiswaKursus((s) => ({
        ...s,
        ...siswa.data.data,
        data: arr,
        isLoading: false,
      }));
    } catch (error) {
      toast(<Failed>{error.message}</Failed>);
      setSiswaKursus({
        ...siswaSekolah,
        isLoading: false,
      });
    }
  }, []);

  useEffect(() => {
    if (selectedPendidikan) {
      getSiswaKursus(selectedPendidikan.value);
    }
  }, [getSiswaKursus, selectedPendidikan]);

  const getAllPendidikan = async () => {
    const urlParam = `${listPendidikanURL}`;

    try {
      setListPendidikan({
        ...listPendidikan,
        isLoading: false,
      });
      const sekolah = await Axios.get(urlParam);
      if (sekolah) {
        const manipulateData = sekolah.data.data.map((data) => ({
          value: data.id,
          label: data.nama,
        }));
        setListPendidikan({
          isLoading: false,
          data: listPendidikan.data.concat(manipulateData),
        });
        setSelectedPendidikan(listPendidikan.data[0] || null);
      } else {
        throw new Error("Tidak dapat memuat pendidikan!");
      }
    } catch (error) {
      toast(<Failed>{error.message}</Failed>);
      setListPendidikan({
        ...listPendidikan,
        isLoading: false,
      });
    }
  };

  useEffect(() => {
    getAllPendidikan();
  }, []);

  console.log("dashboard", siswaKursus);

  return (
    <div>
      <Private {...props}>
        <Breadcrumb data={state.breadcrumb} />

        <Grid container>
          <Grid item xs={12} md={6}>
            <div className="wrapper">
              <div className="d-flex align-items-center">
                <i className="material-icons dashboard mr-2">people</i>
                <span className="color-grey font-lg text-bold mr-2">SISWA</span>
              </div>
              <div className="color-grey mt-1 font-sm">
                Per {totalSiswa.as_per}{" "}
              </div>
              <div className="color-dawn-blue font-xl mt-1 text-bold">
                {totalSiswa.total} Siswa
              </div>
            </div>
          </Grid>
          <Grid item xs={12} md={6}>
            <div className="wrapper">
              <div className="d-flex align-items-center" style={{ height: 28 }}>
                <i className="material-icons dashboard mr-2">assessment</i>
                <span className="color-grey font-lg text-bold mr-2">
                  TAGIHAN TERTUNDA
                </span>
              </div>
              <div className="color-grey mt-1 font-sm">
                Per {totalTagihan.as_per}
              </div>
              <div className="color-dawn-blue font-xl mt-1 text-bold">
                {priceFormat(totalTagihan.total)}
              </div>
            </div>
          </Grid>
        </Grid>

        <Grid container>
          <Grid item xs={12}>
            <div className="wrapper">
              <div className="d-flex align-items-center">
                <i className="material-icons dashboard mr-2">assessment</i>
                <span className="color-grey font-lg text-bold mr-2">
                  PENDAPATAN & PENGELUARAN
                </span>
              </div>
              <div className="color-grey mt-1 font-sm">
                Per {moment(new Date()).format("MMMM YYYY")}
              </div>

              <Grid container spacing={6} className="mt-2">
                <Grid item>
                  <div className="dashboard-chips">Pendapatan</div>
                  <div className="color-dawn-blue font-xl mt-1 text-bold">
                    {priceFormat(chartData.totalPendapatan)}
                  </div>
                </Grid>
                <Grid item>
                  <div className="dashboard-chips">Pangeluaran</div>
                  <div className="color-dawn-blue font-xl mt-1 text-bold">
                    {priceFormat(chartData.totalPengeluaran)}
                  </div>
                </Grid>
              </Grid>

              <div style={{ marginTop: 40 }}>
                {chartData.isLoading ? (
                  <Loading />
                ) : (
                  <DashboardChart data={chartData.data} />
                )}
              </div>
            </div>
          </Grid>
        </Grid>

        <Grid container>
          <Grid item xs={12} md={6} style={{ marginBottom: 32 }}>
            <div className="wrapper" style={{ height: "100%" }}>
              <div className="d-flex align-items-center" style={{ height: 42 }}>
                <i className="material-icons dashboard mr-2">people</i>
                <span className="color-grey font-lg text-bold mr-2">SISWA</span>
                <div className="dashboard-chips">Sekolah</div>
              </div>
              {siswaSekolah.as_per && (
                <div>
                  <div className="color-grey mt-1 font-sm">
                    Per {siswaSekolah.as_per}
                  </div>
                  <div className="color-dawn-blue font-xl mt-1 text-bold">
                    {siswaSekolah.jumlah_siswa} Siswa
                  </div>
                </div>
              )}

              {siswaSekolah.isLoading ? (
                <Loading />
              ) : (
                <Fragment>
                  {!!siswaSekolah.data.length && (
                    <DashboardPieChart data={siswaSekolah.data} />
                  )}
                </Fragment>
              )}
            </div>
          </Grid>
          <Grid item xs={12} md={6} style={{ marginBottom: 32 }}>
            <div className="wrapper" style={{ height: "100%" }}>
              <div className="d-flex align-items-center justify-content-between">
                <div className="d-flex align-items-center">
                  <i className="material-icons dashboard mr-2">people</i>
                  <span className="color-grey font-lg text-bold mr-2">
                    SISWA
                  </span>
                  <div className="dashboard-chips">Kursus</div>
                </div>
                <div style={{ width: 100 }}>
                  <SingleSelect
                    name="pendidikan"
                    value={selectedPendidikan}
                    onChange={(e) => setSelectedPendidikan(e)}
                    options={listPendidikan.data}
                    isLoading={listPendidikan.isLoading}
                    required
                  />
                </div>
              </div>
              {siswaKursus.as_per && (
                <Fragment>
                  {siswaKursus.data.length ? (
                    <Fragment>
                      <div className="color-grey mt-1 font-sm">
                        Per {siswaKursus.as_per}
                      </div>
                      <div className="color-dawn-blue font-xl mt-1 text-bold">
                        {siswaKursus.jumlah_siswa} Siswa
                      </div>{" "}
                    </Fragment>
                  ) : (
                    <div
                      className="color-grey font-italic"
                      style={{ margin: "35px 0px 15px" }}
                    >
                      Tidak ada data siswa di kursus
                    </div>
                  )}
                </Fragment>
              )}

              {!siswaKursus.isLoading ? (
                <Fragment>
                  {selectedPendidikan && (
                    <DashboardPieChart data={siswaKursus.data} height={400} />
                  )}
                </Fragment>
              ) : (
                <Loading />
              )}
            </div>
          </Grid>
        </Grid>

        <Grid container>
          <Grid item xs={12}>
            <div className="wrapper table-wrapper">
              <div className="d-flex align-items-center">
                <i className="material-icons dashboard mr-2">assessment</i>
                <span className="color-grey font-lg text-bold mr-2">
                  PENAGIHAN
                </span>
              </div>
              <div className="color-grey mt-1 font-sm">
                Per {moment(new Date()).format("MMMM YYYY")}
              </div>
              <PenagihanTable />
            </div>
          </Grid>
        </Grid>
      </Private>
    </div>
  );
};

export default Dashboard;
