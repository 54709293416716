import React from "react";
import { ThemeProvider, createMuiTheme } from "@material-ui/core";
import Router from "./route/Route";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import id from "date-fns/locale/id";
import "moment/locale/id";

// Base Framework
import "jquery";
// import 'bootstrap';
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "react-datepicker/dist/react-datepicker.css";
import "react-toastify/dist/ReactToastify.min.css";

import "./assets/scss/style.scss";
import moment from "moment";
import Axios from "axios";
import UserProvider from "./context/User/UserProvider";

const breakpointValues = {
  xs: 475,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
};

const theme = createMuiTheme({
  breakpoints: { values: breakpointValues },
  typography: {
    fontFamily: "'Nunito', sans-serif",
    fontSize: 14,
  },
});

// Date localization
registerLocale("es", id);
setDefaultLocale("es");
moment.locale("id");

// Global Request Header
const token = localStorage.getItem("id");
if (token) {
  Axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
}

function App() {
  return (
    <ThemeProvider theme={theme}>
      <UserProvider>
        <Router />
      </UserProvider>
    </ThemeProvider>
  );
}

export default App;
