import React from 'react';

const Public = props => {
    const token = localStorage.getItem("id");

    if(token) {
        props.history.push("/dashboard")
    }

    return (
        <div>
            {props.children}
        </div>
    );
};

export default Public;