import React, { useState } from "react";

const ToggleField = props => {
    const [show, setShow] = useState(false)

  return (
    <>
      {props.label && (
        <label htmlFor={props.name} className="input-label">
          {props.required && <span style={{ color: "#FF615B" }}>*</span>}
          {props.label}
        </label>
      )}
      {show ? <input
        value={props.value}
        name={props.name}
        style={props.style}
        className={`input-default ${props.className}`}
        id={props.id}
        type={props.type || "text"}
        placeholder={props.placeholder}
        required={props.required || null}
        onChange={props.onChange}
      /> : <button className="button-toggle" onClick={() => {
        setShow(!show)
        if(props.checkToggle) {
            props.checkToggle(!show)
        }
      }}>
          <span>{props.toggleText}</span>
          </button>}
    </>
  );
};

export default ToggleField;