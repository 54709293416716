/* eslint-disable react-hooks/exhaustive-deps */
import Axios from "axios";
import MaterialTable from "material-table";
import moment from "moment";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { listMainPenagihanURL } from "../../../api/penagihan";
import Failed from "../../../component/Misc/Failed";
import PanelContext from "../../../context/KenaikanKelas/PanelContext";
import PanelProvider from "../../../context/KenaikanKelas/PanelProvider";
import priceFormat from "../../../helper/PriceFormat";
import DetailPanel from "../Akunting/Penagihan/DetailPanel";

const DetailIcon = (props) => (
  <div className="d-flex align-items-center">
    <i className="material-icons color-ocean-blue">
      {props.open ? "expand_less" : "expand_more"}
    </i>
    <div className="text-bold color-ocean-blue font-md">
      {props.open ? "Tutup" : "Detail"}
    </div>
  </div>
);

const PenagihanTable = () => {
  const context = useContext(PanelContext);
  const [state, setState] = useState({
    data: [],
    isLoading: false,
  })

  const getAllData = useCallback(async () => {
    setState({
      ...state,
      isLoading: true,
    });

    const urlParam = `${listMainPenagihanURL}${`?bulan=${moment(new Date()).format('MM')}`}`;

    try {
      const fetch = await Axios.get(urlParam);
      if (fetch) {
        const manipulateData =
          fetch.data.data?.map((res, i) => ({
            ...res,
            checked: false,
            index: i,
            detail: [],
          })) || [];

        setState({
          ...state,
          data: manipulateData,
          total: fetch.data.total,
          isLoading: false,
        });

        context.onChange("data", manipulateData);

      } else {
        throw new Error("Tidak dapat memuat daftar penagihan!");
      }
    } catch (error) {
      setState({
        ...state,
        isLoading: false,
      });
      toast(<Failed>{error.message}</Failed>);
    }
  }, []);

  useEffect(() => {
    getAllData();
  }, [getAllData]);

  return (
    <div>
      <MaterialTable
        columns={[
          {
            title: "Nama Siswa",
            field: "nama_siswa",
            width: 228,
            render: (rowData) => <div>{rowData.nama_siswa}</div>,
          },
          {
            width: 250,
            title: "Bulan Penagihan",
            field: "bulan_penagihan",
          },
          {
            width: 300,
            title: "Pendidikan",
            field: "pendidikan",
          },
          {
            title: "Jumlah",
            field: "jumlah",
            render: (rowData) => <div>{priceFormat(rowData.jumlah)}</div>,
          },
        ]}
        isLoading={state.isLoading}
        data={context.data}
        // onRowClick={(event, rowData, togglePanel) => {
        //   console.log("data", rowData);
        //   getDetailData(rowData.id_siswa);
        //   togglePanel();
        // }}
        options={{
          toolbar: false,
          paging: false,
          padding: "dense",
          pageSize: 10,
          actionsColumnIndex: -1,
          detailPanelColumnAlignment: "right",
          rowStyle: (rowData) => {
            // console.log("row data", rowData);
            if (rowData.tableData.id % 2 === 0) {
              return { backgroundColor: "#F6F9FF" };
            }

            return { backgroundColor: "#fff" };
          },
        }}
        detailPanel={[
          {
            icon: () => <DetailIcon />,
            openIcon: () => <DetailIcon open />,
            render: (rowData) => {
              return (
                <DetailPanel
                  dashboard
                  id={rowData.id_siswa}
                  index={rowData.tableData.id}
                  checked={rowData.checked}
                />
              );
            },
          },
        ]}
      />
    </div>
  );
};

export default (props) => {
  return (
    <PanelProvider>
      <PenagihanTable {...props} />
    </PanelProvider>
  );
};

