import checkEnv from "./index";

const baseRoute = checkEnv();

// POST
const createPengeluaranURL = `${baseRoute}/pengeluaran/create`;

const updatePengeluaranURL = (id) => {
    return `${baseRoute}/pengeluaran/update/${id}`;
  };

const deletePengeluaranURL = (id) => {
  return `${baseRoute}/pengeluaran/delete/${id}`;
};

// GET
const listPengeluaranURL = `${baseRoute}/pengeluaran/get-all`;

const findIdPengeluaranURL = (id) => {
  return `${baseRoute}/pengeluaran/find/${id}`;
};

export {
  listPengeluaranURL,
  createPengeluaranURL,
  updatePengeluaranURL,
  deletePengeluaranURL,
  findIdPengeluaranURL,
};
