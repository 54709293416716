import React from "react";
import MaterialTable from "material-table";
import priceFormat from "../../../../helper/PriceFormat";

const TableSummary = (props) => {
  console.log("summary props", props);
  return (
    <div className="table-wrapper table-pembayaran-summary">
      {props.spp && (
        <MaterialTable
          columns={[
            {
              title: "Pembayaran",
              field: "type",
              width: 460,
              headerStyle: {
                fontWeight: 700,
                color: "#2a2a2a",
                backgroundColor: "#F3F3F3",
              },
              render: (rowData) => (
                <div className="mb-1">
                  <div className="text-bold">{rowData.type}</div>
                  {rowData.data.map((data, index) => {
                    return <div key={data.paket}>{data.paket}</div>;
                  })}
                </div>
              ),
            },
            {
              title: "Qty",
              field: "data",
              width: 300,
              headerStyle: {
                fontWeight: 700,
                color: "#2a2a2a",
                backgroundColor: "#F3F3F3",
              },

              render: (rowData) => (
                <div className="mt-3 mb-1">
                  {rowData.data.map((data) => {
                    return (
                      <div key={data.nama}>
                        {data.checked ? data.diff : 0} x {data.biaya_per_bulan}
                      </div>
                    );
                  })}
                </div>
              ),
            },
            {
              title: "Jumlah",
              field: "data",
              headerStyle: {
                fontWeight: 700,
                color: "#2a2a2a",
                backgroundColor: "#F3F3F3",
              },

              render: (rowData) => (
                <div className="mt-3 mb-1">
                  {rowData.data.map((data) => {
                    const total =
                      (data.checked ? data.diff : 0) *
                      data.biaya_per_bulan_angka;
                    return <div key={data.nama}>{priceFormat(total)}</div>;
                  })}
                </div>
              ),
            },
          ]}
          isLoading={props.data.loading}
          data={props.data.spp}
          options={{
            toolbar: false,
            padding: "dense",
            pageSize: 10,
            paging: false,
          }}
        />
      )}
      {props.lain && (
        <div className="mt-2">
          <MaterialTable
            columns={[
              {
                title: "Pembayaran",
                field: "nama",
                width: 460,
                headerStyle: {
                  fontWeight: 700,
                  color: "#2a2a2a",
                  backgroundColor: "#F3F3F3",
                },
                render: (rowData) => (
                  <div>
                    {rowData.tableData.id + 1}. {rowData.nama}
                  </div>
                ),
              },
              {
                title: "Qty",
                field: "harga",
                width: 300,
                headerStyle: {
                  fontWeight: 700,
                  color: "#2a2a2a",
                  backgroundColor: "#F3F3F3",
                },
                render: (rowData) => (
                  <div>1 x {priceFormat(rowData.harga || 0)}</div>
                ),
              },
              {
                title: "Jumlah",
                field: "harga",
                headerStyle: {
                  fontWeight: 700,
                  color: "#2a2a2a",
                  backgroundColor: "#F3F3F3",
                },
                render: (rowData) => (
                  <div>{priceFormat(rowData.harga || 0)}</div>
                ),
              },
            ]}
            isLoading={props.data.loading}
            data={props.data.lain}
            options={{
              toolbar: false,
              padding: "dense",
              pageSize: 10,
              paging: false,
            }}
          />
        </div>
      )}
    </div>
  );
};

export default TableSummary;
