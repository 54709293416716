import React, { useState, useContext } from "react";
import Sidebar from "../component/Navigation/Sidebar";
import MainContent from "../component/Content/MainContent";
import UserContext from "../context/User/UserContext";
import Loading from "../component/Misc/Loading";
import checkRole from "../route/CheckRole";
import { Redirect } from "react-router-dom";

const Private = (props) => {
  // const history = useHistory();
  const user = useContext(UserContext);
  const [state, setState] = useState({
    sidebar: "sidebar-show",
    main: "content-wide",
  });

  const getSidebar = (value) => {
    setState({
      ...state,
      sidebar: value ? "sidebar-show" : "sidebar-hide",
      main: value ? "content-wide" : "content-shrink",
    });
  };

  // const goToBase = () => {
  //   history.push(`${checkRole(user.user.role, props.group).baseRoute}`);
  // };

  if (user.isLoading) {
    return <Loading />;
  }

  // window.location.assign(checkRole(user.user.role, props.group).baseRoute)

  return (
    <React.Fragment>
      {checkRole(user.user.role, props.group).status ? (
        <div className="private-layout">
          <Sidebar status={state} sidebar={(val) => getSidebar(val)} />
          <MainContent {...props} status={state}>
            {props.children}
          </MainContent>
        </div>
      ) : (
        <Redirect
          to={{
            pathname: checkRole(user.user.role, props.group).baseRoute,
          }}
        />
      )}
    </React.Fragment>
  );
};

export default Private;
