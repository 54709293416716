import React, { useState } from "react";
import Private from "../../../../layout/Private";
import Breadcrumb from "../../../../component/Navigation/Breadcrumb";
import { Grid } from "@material-ui/core";
import ButtonSecondary from "../../../../component/Button/ButtonSecondary";
import ButtonPrimary from "../../../../component/Button/ButtonPrimary";
import Axios from "axios";
import moment from "moment";
import { toast } from "react-toastify";
import Failed from "../../../../component/Misc/Failed";
import ButtonLoading from "../../../../component/Button/ButtonLoading";
import { useHistory } from "react-router-dom";
import { cancelMessage, errorMessage } from "../../../../helper/Static";
import TextField from "../../../../component/Field/TextField";
import Toastr from "../../../../component/Misc/Toastr";
import SelectDate from "../../../../component/Field/SelectDate";
import { createPengeluaranURL } from "../../../../api/pengeluaran";

const TambahPengeluaran = props => {
  const history = useHistory();

  const [state, setState] = useState({
    nama_pengeluaran: "",
    nomor_struk: "",
    tgl_pengeluaran: new Date(),
    jumlah_pengeluaran: "",
    breadcrumb: [
      {
        name: "EEC",
        url: "/dashboard",
        active: false,
      },
      {
        name: "Akunting",
        url: "/akunting",
        active: false,
      },
      {
        name: "Pengeluaran",
        url: "/akunting/pengeluaran",
        active: false,
      },
      {
        name: "Tambah Pengeluaran",
        url: "/akunting/pengeluaran/baru",
        active: true,
      },
    ],
  });

  const onChange = (value, name) => {
    setState({
      ...state,
      [name]: value,
    });
  };

  const cancel = () => {
    if (window.confirm(cancelMessage(`tambah pengeluaran baru`))) {
      history.push("/akunting/pengeluaran");
    }
  };

  const submitPengeluran = async (e) => {
    e.preventDefault();
    setState({
      ...state,
      loading: true,
    });

    try {
      const submit = await Axios.post(createPengeluaranURL, {
        nama_pengeluaran: state.nama_pengeluaran,
        tgl_pengeluaran: moment(new Date(state.tgl_pengeluaran)).format('YYYY-MM-DD'),
        nomor_struk: state.nomor_struk,
        jumlah_pengeluaran: state.jumlah_pengeluaran
      });

      console.log("submit", submit);

      if (submit) {
        if (!submit.data.error) {
          history.push({
            pathname: "/akunting/pengeluaran",
            toast: "Berhasil menambahkan pengeluaran baru",
          });
          setState({
            ...state,
            loading: false,
          });
        } else {
          throw submit.data.error;
        }
      } else {
        throw errorMessage("membuat pengeluaran");
      }
    } catch (error) {
      toast(<Failed>{error}</Failed>);
      setState({
        ...state,
        loading: false,
      });
    }
  };

  console.log("state sekolah", state);
  return (
    <Private {...props}>
      <Breadcrumb data={state.breadcrumb} />
      <div className="wrapper-form">
        <div className="header-title">TAMBAH PENGELUARAN</div>
      </div>
      <form onSubmit={(e) => submitPengeluran(e)}>
        <div className="wrapper">
          <div className="container">
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <TextField
                  name="nama_pengeluaran"
                  label="Nama Pengeluaran"
                  placeholder="Isikan Nama Pengeluaran"
                  className="mb-2"
                  value={state.nama_pengeluaran}
                  onChange={(e) => onChange(e.target.value, "nama_pengeluaran")}
                  required
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  name="nomor_struk"
                  label="No. Struk"
                  placeholder="Isikan No. Struk"
                  className="mb-2"
                  value={state.nomor_struk}
                  onChange={(e) => onChange(e.target.value, "nomor_struk")}
                  required
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <SelectDate
                   name="tgl_pengeluaran"
                   label="Tanggal Pengeluaran"
                   className="mb-2"
                   value={state.tgl_pengeluaran}
                   onChange={(value) => onChange(value, "tgl_pengeluaran")}
                   dateFormat="dd MMMM yyyy"
                   required
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  type="number"
                  name="jumlah_pengeluaran"
                  label="Jumlah Pengeluaran"
                  placeholder="Isikan Jumlah Pengeluaran"
                  className="mb-2"
                  value={state.jumlah_pengeluaran}
                  onChange={(e) => onChange(e.target.value, "jumlah_pengeluaran")}
                  required
                />
              </Grid>
            </Grid>
            <Grid className="mt-4" container spacing={3} justify="flex-end">
              <Grid item>
                <ButtonSecondary onClick={cancel}>Batalkan</ButtonSecondary>
              </Grid>
              <Grid item>
                {state.loading ? (
                  <ButtonLoading>Memproses...</ButtonLoading>
                ) : (
                  <ButtonPrimary type="submit">Tambah Pengeluaran</ButtonPrimary>
                )}
              </Grid>
            </Grid>
          </div>
        </div>
      </form>
      <Toastr />
    </Private>
  );
};

export default TambahPengeluaran;
