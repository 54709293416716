/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from "react";
import Private from "../../../../layout/Private";
import Breadcrumb from "../../../../component/Navigation/Breadcrumb";
import { Grid } from "@material-ui/core";
import ButtonSecondary from "../../../../component/Button/ButtonSecondary";
import ButtonPrimary from "../../../../component/Button/ButtonPrimary";
import Axios from "axios";
import { toast } from "react-toastify";
import Failed from "../../../../component/Misc/Failed";
import ButtonLoading from "../../../../component/Button/ButtonLoading";
import { useHistory, useRouteMatch } from "react-router-dom";
import {
  cancelMessage,
  errorMessage,
  deleteMessage,
} from "../../../../helper/Static";
import TextField from "../../../../component/Field/TextField";
import SelectDate from "../../../../component/Field/SelectDate";
import Loading from "../../../../component/Misc/Loading";
import Toastr from "../../../../component/Misc/Toastr";
import { findIdPengeluaranURL, updatePengeluaranURL, deletePengeluaranURL } from "../../../../api/pengeluaran";
import moment from "moment";
import UserContext from "../../../../context/User/UserContext";

const EditPengeluaran = props => {
  const match = useRouteMatch();
  const history = useHistory();
  const user = useContext(UserContext)

  const [state, setState] = useState({
    nama_pengeluaran: "",
    nomor_struk: "",
    tgl_pengeluaran: new Date(),
    jumlah_pengeluaran: "",
    loading: false,
    breadcrumb: [
      {
        name: "EEC",
        url: "/dashboard",
        active: false,
      },
      {
        name: "Akunting",
        url: "/akunting",
        active: false,
      },
      {
        name: "Pengeluaran",
        url: "/akunting/pengeluaran",
        active: false,
      },
      {
        name: "Edit Pengeluaran",
        url: "/akunting/pengeluaran/edit",
        active: true,
      },
    ],
  });

  const getCurrentData = async () => {
    setState({
      ...state,
      isLoading: true,
    });
    try {
      const fetch = await Axios.get(findIdPengeluaranURL(match.params.id));
      if (!fetch.data.error) {
        setState({
          ...state,
          nama_pengeluaran: fetch.data.data.nama_pengeluaran,
          tgl_pengeluaran: new Date(fetch.data.data.tgl_pengeluaran),
          jumlah_pengeluaran: fetch.data.data.jumlah_pengeluaran,
          nomor_struk: fetch.data.data.nomor_struk,
          isLoading: false,
        });
      } else {
        const errorText = "Error";
        throw errorText;
      }
    } catch (error) {
      setState({
        ...state,
        isLoading: false,
      });
      toast(<Failed>{error}</Failed>);
    }
  };

  useEffect(() => {
    getCurrentData();
  }, []);

  const onChange = (value, name) => {
    setState({
      ...state,
      [name]: value,
    });
  };

  const cancel = () => {
    if (window.confirm(cancelMessage(`edit pengeluaran`))) {
      history.push("/akunting/pengeluaran");
    }
  };

  const submitSekolah = async (e) => {
    e.preventDefault();
    setState({
      ...state,
      loading: true,
    });

    try {
      const submit = await Axios.post(updatePengeluaranURL(match.params.id), {
        nama_pengeluaran: state.nama_pengeluaran,
        tgl_pengeluaran: moment(new Date(state.tgl_pengeluaran)).format('YYYY-MM-DD'),
        nomor_struk: state.nomor_struk,
        jumlah_pengeluaran: state.jumlah_pengeluaran
      });

      console.log("submit", submit);

      if (submit) {
        if (!submit.data.error) {
          history.push({
            pathname: "/akunting/pengeluaran",
            toast: "Berhasil mengedit pengeluaran",
          });
          setState({
            ...state,
            loading: false,
          });
        } else {
          throw submit.data.error;
        }
      } else {
        throw errorMessage("mengedit pengeluaran");
      }
    } catch (error) {
      toast(<Failed>{error}</Failed>);
      setState({
        ...state,
        loading: false,
      });
    }
  };

  const deleteSekolah = async () => {
    if (window.confirm(deleteMessage(`pengeluaran ${state.nama_pengeluaran}`))) {
      try {
        const deleting = await Axios.post(deletePengeluaranURL(match.params.id));
        if (deleting.data) {
          if (!deleting.data.error) {
            history.push({
              pathname: "/akunting/pengeluaran",
              toast: "Berhasil menghapus pengeluaran",
            });
          } else {
            throw deleting.data.error;
          }
        } else {
          throw errorMessage("menghapus pengeluaran");
        }
      } catch (error) {
        toast(<Failed>{error}</Failed>);
        setState({
          ...state,
          isLoading: false,
        });
      }
    }
  };

  console.log("state sekolah", state);
  return (
    <Private {...props}>
      <Breadcrumb data={state.breadcrumb} />
      <div className="wrapper-form">
        <Grid container justify="space-between" alignItems="center">
          <Grid item>
            <div className="header-title">EDIT PENGELUARAN</div>
          </Grid>
          {user?.user.role !== "Kasir" && <Grid item>
            <ButtonSecondary
              onClick={deleteSekolah}
              icon="delete"
              color="#DC6E6E"
            >
              HAPUS
            </ButtonSecondary>
          </Grid>}
        </Grid>
      </div>
      <form onSubmit={(e) => submitSekolah(e)}>
        <div className="wrapper">
          {state.isLoading ? <Loading /> : <div className="container">
          <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <TextField
                  name="nama_pengeluaran"
                  label="Nama Pengeluaran"
                  placeholder="Isikan Nama Pengeluaran"
                  className="mb-2"
                  value={state.nama_pengeluaran}
                  onChange={(e) => onChange(e.target.value, "nama_pengeluaran")}
                  required
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  name="nomor_struk"
                  label="No. Struk"
                  placeholder="Isikan No. Struk"
                  className="mb-2"
                  value={state.nomor_struk}
                  onChange={(e) => onChange(e.target.value, "nomor_struk")}
                  required
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <SelectDate
                   name="tgl_pengeluaran"
                   label="Tanggal Pengeluaran"
                   className="mb-2"
                   value={state.tgl_pengeluaran}
                   onChange={(value) => onChange(value, "tgl_pengeluaran")}
                   dateFormat="dd MMMM yyyy"
                   required
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  type="number"
                  name="jumlah_pengeluaran"
                  label="Jumlah Pengeluaran"
                  placeholder="Isikan Jumlah Pengeluaran"
                  className="mb-2"
                  value={state.jumlah_pengeluaran}
                  onChange={(e) => onChange(e.target.value, "jumlah_pengeluaran")}
                  required
                />
              </Grid>
            </Grid>
            <Grid className="mt-4" container spacing={3} justify="flex-end">
              <Grid item>
                <ButtonSecondary onClick={cancel}>Batalkan</ButtonSecondary>
              </Grid>
              <Grid item>
                {state.loading ? (
                  <ButtonLoading>Memproses...</ButtonLoading>
                ) : (
                  <ButtonPrimary type="submit">Simpan Perubahan</ButtonPrimary>
                )}
              </Grid>
            </Grid>
          </div>}
        </div>
      </form>
      <Toastr />
    </Private>
  );
};

export default EditPengeluaran;
