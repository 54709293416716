import checkEnv from "./index";

const baseRoute = checkEnv();

const countTotalSiswaURL = `${baseRoute}/dashboard/count-total-siswa`
const countSiswaSekolahURL = `${baseRoute}/dashboard/count-siswa-sekolah-number`;
const countTagihanTertunda = `${baseRoute}/dashboard/count-tagihan-tertunda`;
const countSiswaKursusURL = id => `${baseRoute}/dashboard/count-siswa-kursus-number/${id}`
const pendapatanPengeluaranURL = year => `${baseRoute}/dashboard/pendapatan-pengeluaran/${year}`

export {
    countSiswaSekolahURL,
    countSiswaKursusURL,
    pendapatanPengeluaranURL,
    countTotalSiswaURL,
    countTagihanTertunda
}