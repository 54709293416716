import React from "react";
import MaterialTable from "material-table";
import { FormGroup, FormControlLabel, Checkbox } from "@material-ui/core";
import DateRangePembayaran from "./DateRangePembayaran";
import priceFormat from "../../../../helper/PriceFormat";

const TablePembayaran = (props) => {
  return (
    <div className="table-wrapper">
      <MaterialTable
        columns={[
          {
            title: "Paket",
            field: "type",
            width: 350,
            render: (rowData) => (
              <div className="mb-1">
                <div className="text-bold">{rowData.type}</div>
                <FormGroup
                  aria-label="tahun-ajaran-status"
                  name="tahun-ajaran-status"
                  style={{ flexDirection: "row" }}
                >
                  {rowData.data.map((data) => {
                    const newData = { ...data, type: rowData.type };
                    return (
                      <FormControlLabel
                        key={data.nama}
                        value="aktif"
                        control={
                          <Checkbox
                            checked={data.checked}
                            disableRipple
                            color="default"
                            onChange={() =>
                              props.onChangePayableData(
                                newData,
                                "checked",
                                !data.checked
                              )
                            }
                          />
                        }
                        label={data.paket}
                      />
                    );
                  })}
                </FormGroup>
              </div>
            ),
          },

          {
            title: "Biaya Per Bulan",
            field: "data",
            render: (rowData) => (
              <div className="mt-3 mb-1">
                {rowData.data.map((data) => {
                  return (
                    <div key={data.nama} style={{ padding: "12px 0px" }}>
                      {data.biaya_per_bulan}
                    </div>
                  );
                })}
              </div>
            ),
          },
          {
            title: "Pembayaran Bulan",
            field: "data",
            width: 300,
            render: (rowData) => (
              <div className="mt-2 mb-1">
                {rowData.data.map((data) => {
                  const newData = { ...data, type: rowData.type };

                  return (
                    <div key={data.nama} style={{ padding: "6px 0px 6px" }}>
                      <DateRangePembayaran
                        startDate={data.start_pay_month}
                        endDate={data.end_pay_month}
                        setStartDate={(e) => {
                          console.log("change start", e);
                          props.onChangePayableData(
                            newData,
                            "start_pay_month",
                            e
                          );
                        }}
                        setEndDate={(e) => {
                          console.log("change end", e);
                          props.onChangePayableData(
                            newData,
                            "end_pay_month",
                            e
                          );
                        }}
                        minDate={new Date(data.tanggal_mulai)}
                        maxDate={new Date(data.tanggal_selesai)}
                      />
                      {/* <DateRange
                        name="durasi-pembelajaran"
                        startDate={new Date(data.start_pay_month)}
                        endDate={new Date(data.end_pay_month)}
                        setStartDate={(value) =>
                          props.onChange(value, "start_pay_month")
                        }
                        setEndDate={(value) =>
                          props.onChange(value, "end_pay_month")
                        }
                        dateFormat="MMMM yyyy"
                        showMonthYearPicker
                        maxDate={new Date(data.tanggal_selesai)}
                        required
                      /> */}
                    </div>
                  );
                })}
              </div>
            ),
          },
          {
            title: "Jumlah",
            field: "data",
            render: (rowData) => (
              <div className="mt-3 mb-1">
                {rowData.data.map((data, index) => {
                  const total =
                    (rowData.data[index].start_pay_month &&
                    rowData.data[index].end_pay_month &&
                    data.checked
                      ? data.diff
                      : 0) * data.biaya_per_bulan_angka;
                  return (
                    <div key={data.nama} style={{ padding: "12px 0px" }}>
                      {priceFormat(total)}
                    </div>
                  );
                })}
              </div>
            ),
          },
        ]}
        isLoading={props.data.loading}
        data={props.data.spp}
        options={{
          toolbar: false,
          padding: "dense",
          pageSize: 10,
          paging: false,
        }}
      />
    </div>
  );
};

export default TablePembayaran;
