/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import Private from "../../../../layout/Private";
import Breadcrumb from "../../../../component/Navigation/Breadcrumb";
import { Grid } from "@material-ui/core";
import ButtonSecondary from "../../../../component/Button/ButtonSecondary";
import ButtonPrimary from "../../../../component/Button/ButtonPrimary";
import Axios from "axios";
import { toast } from "react-toastify";
import Failed from "../../../../component/Misc/Failed";
import ButtonLoading from "../../../../component/Button/ButtonLoading";
import { useHistory } from "react-router-dom";
import { cancelMessage, errorMessage } from "../../../../helper/Static";
import TextField from "../../../../component/Field/TextField";
import { listPendidikanConcatURL, listMaxTingkatURL } from "../../../../api/sekolah";
import SingleSelect from "../../../../component/Field/SingleSelect";
import { createKelasURL } from "../../../../api/kelas";
import Toastr from "../../../../component/Misc/Toastr";

const TambahKelas = props => {
  const history = useHistory();

  const [type, setType] = useState("");
  const [maxLength, setMaxLength] = useState(null)
  const [state, setState] = useState({
    jumlah_siswa: "",
    pendidikan: "",
    tingkat: "",
    nama: "",
    breadcrumb: [
      {
        name: "EEC",
        url: "/dashboard",
        active: false,
      },
      {
        name: "Pengaturan",
        url: "/pengaturan",
        active: false,
      },
      {
        name: "Kelas",
        url: "/pengaturan/kelas",
        active: false,
      },
      {
        name: "Tambah Kelas",
        url: "/pengaturan/kelas/baru",
        active: true,
      },
    ],
  });

  const onChange = async (value, name) => {
    setState({
      ...state,
      [name]: value,
    });

    if (name === "pendidikan") {
     
      if (value.value.indexOf("kursus") !== -1) {
        setType("kursus");
      } else {
        setType("sekolah");
        const maxTingkat = await Axios.get(listMaxTingkatURL(value.value.substring(8, value.value.length)));
        console.log('max tingkat', maxTingkat)
        setMaxLength(maxTingkat.data.data.jumlah_tingkat)
      }
    }
  };

  const getListPendidikan = async () => {
    setState({
      ...state,
      isLoading: true,
    });
    try {
      const sekolah = await Axios.get(listPendidikanConcatURL);
      if (sekolah) {
        setState({
          ...state,
          listPendidikan: sekolah.data.data,
          isLoading: false,
        });
      } else {
        const errorText = "Error";
        throw errorText;
      }
    } catch (error) {
      setState({
        ...state,
        isLoading: false,
      });
      toast(<Failed>{error}</Failed>);
    }
  };

  useEffect(() => {
    getListPendidikan();
  }, []);

  const cancel = () => {
    if (window.confirm(cancelMessage(`tambah kelas baru`))) {
      history.push("/pengaturan/kelas");
    }
  };

  const submitKursus = async (e) => {
    e.preventDefault();
    setState({
      ...state,
      loading: true,
    });

    try {
      const submit = await Axios.post(createKelasURL, {
        nama: state.nama,
        pendidikan: state.pendidikan.value,
        jumlah_siswa: parseInt(state.jumlah_siswa) || 0,
        tingkat: parseInt(state.tingkat) || 0,
      });

      console.log("submit", submit);

      if (submit) {
        if (!submit.data.error) {
          history.push({
            pathname: "/pengaturan/kelas",
            toast: "Berhasil menambahkan kelas baru",
          });
          setState({
            ...state,
            loading: false,
          });
        } else {
          throw new Error(submit.data.error);
        }
      } else {
        throw new Error(errorMessage("membuat kelas"));
      }
    } catch (error) {
      toast(<Failed>{error.message}</Failed>);
      setState({
        ...state,
        loading: false,
      });
    }
  };

  console.log("state sekolah", state);
  return (
    <Private {...props}>
      <Breadcrumb data={state.breadcrumb} />
      <div className="wrapper-form">
        <div className="header-title">TAMBAH KELAS</div>
      </div>
      <form onSubmit={(e) => submitKursus(e)}>
        <div className="wrapper">
          <div className="container">
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <TextField
                  name="nama_kelas"
                  label="Nama Kelas"
                  placeholder="Isikan Nama"
                  className="mb-2"
                  value={state.nama}
                  onChange={(e) => onChange(e.target.value, "nama")}
                  required
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <SingleSelect
                  type="number"
                  name="pendidikan"
                  label="Pendidikan"
                  placeholder="Isikan Pendidikan"
                  className="mb-2"
                  value={state.pendidikan}
                  onChange={(e) => onChange(e, "pendidikan")}
                  options={state.listPendidikan}
                  required
                />
              </Grid>

              {type !== "kursus" && (
                <Grid item xs={12} md={6}>
                  <TextField
                    type="number"
                    name="tingkat"
                    label="Tingkat"
                    placeholder="Isikan Tingkat"
                    className="mb-2"
                    value={state.tingkat}
                    onChange={(e) => onChange(e.target.value, "tingkat")}
                    max={maxLength}
                    required
                  />
                </Grid>
              )}

              {/* <Grid item xs={12} md={6}>
                <TextField
                  type="number"
                  name="jumlah_siswa"
                  label="Jumlah Siswa"
                  placeholder="Isikan Jumlah Siswa"
                  className="mb-2"
                  value={state.jumlah_siswa}
                  onChange={(e) => onChange(e.target.value, "jumlah_siswa")}
                  required
                />
              </Grid> */}
            </Grid>
            <Grid className="mt-4" container spacing={3} justify="flex-end">
              <Grid item>
                <ButtonSecondary onClick={cancel}>Batalkan</ButtonSecondary>
              </Grid>
              <Grid item>
                {state.loading ? (
                  <ButtonLoading>Memproses...</ButtonLoading>
                ) : (
                  <ButtonPrimary type="submit">Tambah Kelas</ButtonPrimary>
                )}
              </Grid>
            </Grid>
          </div>
        </div>
      </form>
      <Toastr />
    </Private>
  );
};

export default TambahKelas;