import checkEnv from "./index";

const baseRoute = checkEnv();

// POST
const createPembayaranURL = `${baseRoute}/pembayaran/create`;

// const updatePengeluaranURL = (id) => {
//     return `${baseRoute}/pengeluaran/update/${id}`;
//   };

// const deletePengeluaranURL = (id) => {
//   return `${baseRoute}/pengeluaran/delete/${id}`;
// };

// GET
const listPembayaranURL = `${baseRoute}/pembayaran/get-all`;

const getPayableURL = (id) => {
  return `${baseRoute}/pembayaran/get-payable/${id}`;
};

const batalkanPembayaranURL = (id) => {
  return `${baseRoute}/pembayaran/batalkan-pembayaran/${id}`;
};

const cetakResiURL = (resi) => {
  return  `${baseRoute}/pembayaran/cetak-resi/${resi}`; 
}

export {
    getPayableURL,
    listPembayaranURL,
    createPembayaranURL,
    batalkanPembayaranURL,
    cetakResiURL
};
