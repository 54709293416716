import decode from "jwt-decode";
import Axios from "axios";

const checkAuth = () => {
    try {
        const token = localStorage.getItem("id");
        if(token) {
            const decoded = decode(token)

            // Global Request Header
            console.log('token app', token)
            Axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;

            // Check JWT Expiration
            const currentTime = Date.now() / 1000;
            if (decoded.exp < currentTime) {
                localStorage.clear();
                localStorage.setItem('error', 'Sesi telah berakhir. Mohon login kembali')
                const errorText = {
                    data: null,
                    error: "Sesi telah berakhir. Mohon login kembali"
                }
                throw errorText;
            }
            
            console.log("DECODED", decoded)
            return {
                data: decoded,
                error: null
            }
        } else {
            const errorText = {
                data: null,
                error: "No Token Provided"
            }
            throw errorText;
        }
    } catch(error) {
        return error
    }
}

export default checkAuth;