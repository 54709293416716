/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useCallback } from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Font,
  Image,
  PDFViewer,
} from "@react-pdf/renderer";
import moment from "moment";
import logoInvoice from "../../../assets/img/logo-invoice.png";
import Axios from "axios";
import { cetakResiURL } from "../../../api/pembayaran";
import { useRouteMatch } from "react-router-dom";
import Loading from "../../../component/Misc/Loading";

Font.register({
  family: "Open Sans",
  fonts: [
    {
      src:
        "https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-regular.ttf",
    },
    {
      src:
        "https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-600.ttf",
      fontWeight: 600,
    },
  ],
});

// Create styles
const styles = StyleSheet.create({
  page: {
    paddingTop: 16,
    paddingBottom: 16,
    paddingHorizontal: 16,
    fontSize: 10,
    fontFamily: "Open Sans",
  },
  sectionTitle: {
    width: "100%",
    textAlign: "center",
    marginBottom: 10,
    fontWeight: 600,
    textTransform: "uppercase",
    fontSize: 8,
  },
  sectionContent: {
    width: "100%",
    textAlign: "left",
    // marginBottom: 10,
    paddingHorizontal: 12,
    fontSize: 8,
  },
  sectionHeader: {
    fontSize: 6,
  },
  flexRow: {
    display: "flex",
    flexDirection: "row",
    marginBottom: 4,
  },
  flexRowPay: {
    display: "flex",
    flexDirection: "row",
    marginBottom: 4,
  },
  smallWidth: {
    width: 72,
  },
  widthXl: {
    width: "60%",
  },
  widthXs: {
    width: "15%",
  },
  widthSm: {
    width: "40%",
  },
  widthSm2: {
    width: "45%",
  },
  textRight: {
    textAlign: "right",
  },
  borderDash: {
    height: 10,
    borderBottom: "1px solid #000",
    marginBottom: 8,
  },
  borderHeader: {
    height: 4,
    borderBottom: "1px solid #000",
    marginBottom: 4,
    width: "80%",
  },
  logo: {
    height: 22,
    width: 37,
  },
  fontXs: {
    fontSize: 7,
  },
  fontSm: {
    fontSize: 10,
  },
});

// Create Document Component
const ResiLandscape = (props) => {
  const [resi, setResi] = useState(null);
  const [loading, setLoading] = useState(true);
  // const [error, setError] = useState(null);
  const match = useRouteMatch();
  console.log("match", match);

  const getResiData = useCallback(async (no) => {
    try {
      const getResi = await Axios.get(cetakResiURL(no));

      if (getResi.data.error) {
        throw new Error(getResi.data.error);
      }
      setResi(getResi.data.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      // setError(error)
    }
  }, []);

  useEffect(() => {
    console.log("hallo");
    getResiData(match.params.resi);
  }, [match.params.resi, getResiData]);

  console.log("resi pdf", resi);

  if (loading) {
    return <Loading />;
  }

  return (
    <PDFViewer>
      <Document title={`${resi.nama_siswa} - ${resi.no}`}>
        <Page size="A5" orientation="landscape" style={styles.page}>
          <View style={{ ...styles.flexRow, marginHorizontal: -8 }}>
            {[0, 1].map((data) => (
              <View
                key={data}
                style={{ width: "50%", fontSize: 10, paddingHorizontal: 8 }}
              >
                <View style={styles.sectionHeader}>
                  <View style={styles.sectionTitle}>
                    <Text>PAYMENT SLIP</Text>
                  </View>
                  <View style={styles.flexRow}>
                    <View style={styles.widthXs}>
                      <Image src={logoInvoice} style={styles.logo} />
                    </View>
                    <View style={{ width: "37%", ...styles.fontXs }}>
                      <Text style={{ ...styles.textBold, lineHeight: 1.5 }}>
                        Yayasan Eka Edukasi Cahaya
                      </Text>
                      <Text style={{ lineHeight: 1.5 }}>
                        PG - TK - SD - SMP
                      </Text>
                      {/* <View style={styles.borderHeader} /> */}
                    </View>
                    <View style={{ width: "48%", ...styles.fontXs }}>
                      <Text style={{ ...styles.textBold, lineHeight: 1.5 }}>
                        Everyone Education Centre
                      </Text>
                      <Text style={{ lineHeight: 1.5 }}>
                        English - Computer - Mandarin - Tutorial
                      </Text>
                      {/* <View style={styles.borderHeader} /> */}
                    </View>
                  </View>
                  <View style={{ fontSize: 7, marginLeft: "15%" }}>
                    <Text style={{ lineHeight: 1.5 }}>
                      Jl. Imam Bonjol No 152, Selatpanjang, Riau
                    </Text>
                    <Text style={{ lineHeight: 1.5 }}>HP: 0823 8888 5522</Text>
                  </View>
                  <View style={styles.borderDash} />

                  <View style={{ fontSize: 8 }}>
                    <View style={styles.flexRow}>
                      <Text style={styles.smallWidth}>No#</Text>
                      <Text>: {resi.no}</Text>
                    </View>
                    <View style={styles.flexRow}>
                      <Text style={styles.smallWidth}>Nama Siswa</Text>
                      <Text>:</Text>
                      <Text
                        style={{
                          textOverflow: "wrap",
                          marginLeft: 2,
                          width: 150,
                        }}
                      >
                        {" "}
                        {resi.nama_siswa}
                      </Text>
                    </View>
                    <View style={styles.flexRow}>
                      <Text style={styles.smallWidth}>Kasir</Text>
                      <Text>: {resi.kasir}</Text>
                    </View>
                    <View style={styles.flexRow}>
                      <Text style={styles.smallWidth}>Tanggal</Text>
                      <Text>
                        :{" "}
                        {moment(new Date(resi.tanggal)).format(
                          "DD-MM-YYYY hh:mm"
                        )}
                      </Text>
                    </View>
                  </View>
                </View>
                <View style={styles.borderDash} />
                <View style={styles.sectionContent}>
                  {resi.detail?.map((content, i) => {
                    return (
                      <View key={i} style={styles.flexRowPay}>
                        <View style={styles.widthXl}>
                          <Text>{content.detail}</Text>
                          <Text style={{ marginLeft: 10 }}>
                            {content.range}
                          </Text>
                        </View>
                        <View
                          style={{ ...styles.widthSm, ...styles.textRight }}
                        >
                          <Text>{content.total}</Text>
                        </View>
                      </View>
                    );
                  })}
                </View>
                <View style={styles.borderDash} />
                <View style={styles.sectionContent}>
                  <View style={styles.flexRowPay}>
                    <View style={styles.widthXl}>
                      <Text>Subtotal -- {resi.detail?.length} item</Text>
                    </View>
                    <View style={{ ...styles.widthSm, ...styles.textRight }}>
                      <Text>{resi.subtotal}</Text>
                    </View>
                  </View>
                  <View style={styles.flexRowPay}>
                    <View style={styles.widthXl}>
                      <Text>Diskon</Text>
                    </View>
                    <View style={{ ...styles.widthSm, ...styles.textRight }}>
                      <Text>{resi.diskon}</Text>
                    </View>
                  </View>
                  <View style={styles.flexRowPay}>
                    <View style={styles.widthXl}>
                      <Text>Total</Text>
                    </View>
                    <View style={{ ...styles.widthSm, ...styles.textRight }}>
                      <Text>{resi.total}</Text>
                    </View>
                  </View>
                </View>

                <View style={{ textAlign: "right", marginVertical: 16 }}>
                  <Text
                    style={{
                      marginRight: 8,
                      textDecoration: "underline",
                      fontSize: 8,
                    }}
                  >
                    Signed by {resi.kasir}
                  </Text>
                </View>

                <View style={styles.sectionTitle}>
                  <Text>THANK YOU</Text>
                </View>

                {/* {data === 0 && <View style={styles.borderDash} />} */}
              </View>
            ))}
          </View>
        </Page>
      </Document>
    </PDFViewer>
  );
};

export default ResiLandscape;
