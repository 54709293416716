/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useState, useEffect, useContext } from "react";
import MaterialTable from "material-table";
import { Checkbox } from "@material-ui/core";
import priceFormat from "../../../../helper/PriceFormat";
import Axios from "axios";
import { detailPenagihanURL } from "../../../../api/penagihan";
import { toast } from "react-toastify";
import Failed from "../../../../component/Misc/Failed";
import PanelContext from "../../../../context/KenaikanKelas/PanelContext";

const DetailPanel = (props) => {
  const context = useContext(PanelContext);
  console.log("details", context.data[props.index].detail);

  const [detail, setDetail] = useState({
    data: [],
    isLoading: false,
  });

  const getDetailData = useCallback(
    async (status) => {
      setDetail({
        ...detail,
        isLoading: true,
      });

      try {
        if (!context.data[props.index].detail.length) {
          console.log('fetch')
          const fetch = await Axios.get(detailPenagihanURL(props.id));
          if (fetch) {
            const manipulateData =
              fetch.data.data.map((res, i) => ({
                ...res,
                checked: status,
              })) || [];

            setDetail({
              ...detail,
              data: manipulateData,
              isLoading: false,
            });
            context.onChangePanel(props.id, "detail", manipulateData);
          } else {
            throw new Error("Tidak dapat memuat data.");
          }
        } else {
          const manipulateData =
          context.data[props.index].detail.map((res, i) => ({
              ...res,
              checked: status,
            })) || [];
          context.onChangePanel(props.id, "detail", manipulateData);

          setDetail({
            ...detail,
            isLoading: false,
          });
        }
      } catch (error) {
        setDetail({
          ...detail,
          isLoading: false,
        });
        toast(<Failed>{error.message}</Failed>);
      }
    },
    [props.id]
  );

  useEffect(() => {
    console.log("checked", props.checked);
    getDetailData(props.checked);
  }, [getDetailData, props.checked]);

  const selectDetail = (id, status) => {
    const manipulateData = detail.data.map((res) => {
      if (res.id_item === id) {
        return {
          ...res,
          checked: status,
        };
      }

      return res;
    });

    setDetail({
      ...detail,
      data: manipulateData,
    });

    context.onChangePanel(props.id, "detail", manipulateData);
  };

  // console.log("detail props", props);

  return (
    <div className="table-penagihan-panel detail-panel">
      <MaterialTable
        columns={[
          {
            title: "",
            field: "checked",
            width: 30,
            hidden: props.dashboard,
            render: (rowData) => (
              <div>
                <Checkbox
                  style={{ padding: "0px 8px" }}
                  color="default"
                  checked={rowData.checked}
                  onChange={() =>
                    selectDetail(rowData.id_item, !rowData.checked)
                  }
                  name={rowData.nama_siswa}
                />
              </div>
            ),
          },
          {
            title: "Nama Siswa",
            field: "nama_siswa",
            width: 228,
            render: (rowData) => <div>{rowData.nama_siswa}</div>,
          },
          {
            width: 250,
            title: "Bulan Penagihan",
            field: "bulan_penagihan",
          },
          {
            width: 300,
            title: "Pendidikan",
            field: "pendidikan",
          },
          {
            title: "Jumlah",
            field: "jumlah",
            render: (rowData) => <div>{priceFormat(rowData.jumlah)}</div>,
          },
        ]}
        isLoading={detail.isLoading}
        data={context.data[props.index]?.detail || []}
        options={{
          toolbar: false,
          padding: "dense",
          pageSize: 10,
          actionsColumnIndex: -1,
          detailPanelColumnAlignment: "right",
          header: false,
          paging: false,
          emptyRowsWhenPaging: false,
          rowStyle: { backgroundColor: "rgba(255, 245, 208, 0.25)" },
        }}
      />
    </div>
  );
};

export default DetailPanel;
