import React from "react";
import { ToastContainer } from "react-toastify";

const Toastr = props => {
  return (
    <ToastContainer
      position={props.position || "bottom-right"}
      autoClose={5000}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnVisibilityChange
      pauseOnHover
      hideProgressBar
    />
  );
};

export default Toastr;
