/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from "react";
import Axios from "axios";
import { FormGroup, FormControlLabel, Checkbox, Grid, makeStyles } from "@material-ui/core";
import { useCallback } from "react";
import { listKursusGroupedURL } from "../../../../api/kursus";
import { toast } from "react-toastify";
import Failed from "../../../../component/Misc/Failed";
import usePrevious from "../../../../helper/usePrevious";
import { isEqual } from "lodash";
import RegistrasiSiswaContext from "../../../../context/RegisterSiswa/RegisterSiswaContext";
import { kursusInitial } from "./SiswaInitialData";

const useStyles = makeStyles({
  root: {
    color: '#000000',
    "&$checked": {
      color: "#000000",

      "&:hover": {
        backgroundColor: `rgba(0,0,0, 0.04)`,
      },
    },
    "&:hover": {
      backgroundColor: `rgba(0,0,0, 0.04)`,
    },
  },
  checked: {},
});

const PaketKursus = props => {
  const classes = useStyles();
  const siswaContext = useContext(RegistrasiSiswaContext)
  const [kursus, setKursus] = useState({
    isLoading: false,
    data: kursusInitial,
    inventori: [
      {
        nama: "Sepatu",
        harga: 30000,
      },
      {
        nama: "Buku",
        harga: 40000,
      },
    ],
  });


  const getAllData = useCallback(async () => {
    setKursus({
      ...kursus,
      isLoading: true,
    });

    const urlParam = `${listKursusGroupedURL}`;

    try {
      const kursus = await Axios.get(urlParam);
      if (kursus) {
        // console.log("tahun ajaran", kursus);
        if (!kursus.data.error) {
          setKursus({
            ...kursus,
            data: kursus.data.data,
            isLoading: false,
          });
        } else {
          throw new Error(kursus.data.error);
        }
      } else {
  
        throw new Error('Tidak dapat mengambil data kursus.');
      }
    } catch (error) {
      setKursus({
        ...kursus,
        isLoading: false,
      });
      toast(<Failed>{error.message}</Failed>);
    }
  }, []);

  useEffect(() => {
    getAllData();
  }, [getAllData]);

  const onChangeList = (type, value) => {
    setKursus({
      data: kursus.data.map((list) => {
        if (list.type === type) {
          return {
            ...list,
            list: list.list.map((item) => {
              if (item.value === value) {
                return {
                  ...item,
                  checked: !item.checked,
                };
              }
              return item;
            }),
          };
        }
        return list;
      }),
    });
  };

  const onChangeType = (type, checked) => {
    setKursus({
      data: kursus.data.map((list) => {
        if (list.type === type) {
          return {
            ...list,
            list: list.list.map((item) => {
              return {
                ...item,
                checked: checked ? false : true,
              };
            }),
          };
        }
        return list;
      }),
    });
  };

  // Flat Kursus Array
  const getCheckedCourses = () => {
      if (kursus.data.length) {
        const kursusMap = (data) => {
          const reducedList = data.list.reduce((result, option) => {
            if (option.checked) {
              return result.concat(option.value);
            }
            return result;
          }, []);
          return reducedList;
        };
        const chained = kursus.data.map(kursusMap);
  
        return chained.flat(Infinity);
      }
      return [];
  }

  const prevKursus = usePrevious(getCheckedCourses());

  // Send kurus data to Parent
  useEffect(() => {
    if(!isEqual(getCheckedCourses(), prevKursus)){
    props.setKursus(getCheckedCourses())
    }
  }, [prevKursus])

  useEffect(() => {
    if(siswaContext.isReset) {
      setKursus({
        ...kursus,
        data: kursusInitial
      })
    }
  }, [siswaContext.isReset])

  return (
    <div className="container pt-4 pb-4">
      <div className="text-bold text-uppercase color-ocean-blue mb-4">
        Kursus / Pelatihan
      </div>
      <div>
        <Grid container spacing={3}>
          {kursus.data.map((data) => {
            const checkAllChecked = data.list.filter((list) => list.checked);
            return (
              <Grid item xs={12} md={6} key={data.type}>
                <div className="pr-3 pl-3">
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="default"
                          checked={checkAllChecked.length === data.list.length}
                          onChange={() => onChangeType(data.type, checkAllChecked.length === data.list.length)}
                          name={data.type}
                         
                        />
                      }
                      label={data.type}
                    />
                  </FormGroup>
                </div>
                <div className="kursus-border">
                  <FormGroup>
                    {data.list.map((list) => {
                      return (
                        <FormControlLabel
                          key={list.value}
                          control={
                            <Checkbox
                              color="default"
                              checked={list.checked}
                              onChange={() =>
                                onChangeList(data.type, list.value)
                              }
                              name={list.value.toString()}
                              classes={{
                                root: classes.root,
                                checked: classes.checked,
                              }}
                            />
                          }
                          label={list.label}
                        />
                      );
                    })}
                  </FormGroup>
                </div>
              </Grid>
            );
          })}
        </Grid>
      </div>
    </div>
  );
};

export default PaketKursus;
