import checkEnv from "./index";

// POST
const createUserURL = `${checkEnv()}/user/create`;

const updateUserURL = (id) => {
    return `${checkEnv()}/user/update/${id}`;
  };

const deleteUserURL = (id) => {
  return `${checkEnv()}/user/delete/${id}`;
};

// GET
const userBasicDataURL = `${checkEnv()}/user/basic-data`;
const userGetAllURL = `${checkEnv()}/user/get-all`;

const userFindByIdURL = (id) => {
    return `${checkEnv()}/user/find/${id}`;
  };

// Role
const roleGetAllURL = `${checkEnv()}/role/get-all`;

export {
    userBasicDataURL,
    userGetAllURL,
    userFindByIdURL,
    createUserURL,
    updateUserURL,
    deleteUserURL,
    roleGetAllURL,
};
