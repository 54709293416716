/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import Private from "../../../../layout/Private";
import Breadcrumb from "../../../../component/Navigation/Breadcrumb";
import { Grid } from "@material-ui/core";
import ButtonSecondary from "../../../../component/Button/ButtonSecondary";
import ButtonPrimary from "../../../../component/Button/ButtonPrimary";
import Axios from "axios";
import { toast } from "react-toastify";
import Failed from "../../../../component/Misc/Failed";
import ButtonLoading from "../../../../component/Button/ButtonLoading";
import { useHistory, useRouteMatch } from "react-router-dom";
import { cancelMessage, errorMessage, deleteMessage } from "../../../../helper/Static";
import TextField from "../../../../component/Field/TextField";
import DateRange from "../../../../component/Field/DateRange";
import { findIdKursusURL, updateKursusURL, deleteKursusURL } from "../../../../api/kursus";
import { listPendidikanURL } from "../../../../api/sekolah";
import SingleSelect from "../../../../component/Field/SingleSelect";
import moment from "moment";
import Loading from "../../../../component/Misc/Loading";
import Toastr from "../../../../component/Misc/Toastr";

const EditKursus = props => {
    const match = useRouteMatch();
    const history = useHistory();

  const [list, setList] = useState({
      listPendidikan: [],
      isLoading: true,
  })

  const [state, setState] = useState({
    jumlah_kelas: "",
    pendidikan: "",
    durasi: "",
    biaya: "",
    nama: "",
    tanggal_mulai: new Date(),
    tanggal_selesai: new Date(),
    tenggat_pembayaran: new Date(),
    status: "aktif",
    breadcrumb: [
      {
        name: "EEC",
        url: "/dashboard",
        active: false,
      },
      {
        name: "Pengaturan",
        url: "/pengaturan",
        active: false,
      },
      {
        name: "Kursus",
        url: "/pengaturan/kursus",
        active: false,
      },
      {
        name: "Edit Kursus",
        url: "/pengaturan/kursus/edit",
        active: true,
      },
    ],
    
  });

  const onChange = (value, name) => {
    setState({
      ...state,
      [name]: value,
    });
  };

  const getCurrentData = async () => {
      console.log('current Data')
    setState({
      ...state,
      dataLoading: true,
    });
    try {
      const sekolah = await Axios.get(findIdKursusURL(match.params.id));
      if (!sekolah.data.error) {
          const checkPendidikan = sekolah.data.data.pendidikan && { value: sekolah.data.data.pendidikan.id,
            label: sekolah.data.data.pendidikan.nama }
        setState({
          ...state,
          nama: sekolah.data.data.nama,
          pendidikan: {...checkPendidikan},
          tanggal_mulai: new Date(sekolah.data.data.tanggal_mulai),
          tanggal_selesai: new Date(sekolah.data.data.tanggal_selesai),
          biaya: sekolah.data.data.biaya,
          jumlah_kelas: sekolah.data.data.jumlah_kelas,
          tenggat_pembayaran: new Date(sekolah.data.data.tenggat_pembayaran),
          dataLoading: false,
        });
      } else {
        const errorText = "Error";
        throw errorText;
      }
    } catch (error) {
      setState({
        ...state,
        dataLoading: false,
      });
      toast(<Failed>{error}</Failed>);
    }
  };

  const getListPendidikan = async () => {
    console.log('pendidikan')

    setList({
      ...list,
      isLoading: true,
    });
    try {
      const sekolah = await Axios.get(listPendidikanURL);
      if (sekolah) {
        setList({
          ...list,
          listPendidikan: sekolah.data.data.map(school => {
            return {
              value: school.id,
              label: school.nama
            }
          }),
          isLoading: false,
        });
      } else {
        const errorText = "Error";
        throw errorText;
      }
    } catch (error) {
      setState({
        ...state,
        isLoading: false,
      });
      toast(<Failed>{error}</Failed>);
    }
  };

  useEffect(() => {
    getListPendidikan();
    getCurrentData();
  }, []);

  const cancel = () => {
    if (window.confirm(cancelMessage(`edit kursus`))) {
      history.push("/pengaturan/kursus");
    }
  };

  const submitKursus = async (e) => {
    e.preventDefault();
    setState({
      ...state,
      loading: true,
    });

    try {
      const submit = await Axios.post(updateKursusURL(match.params.id), {
        nama: state.nama,
        biaya: parseInt(state.biaya),
        id_pendidikan: parseInt(state.pendidikan.value),
        tanggal_mulai: moment(new Date(state.tanggal_mulai)).format('YYYY-MM-DD'),
        tanggal_selesai: moment(new Date(state.tanggal_selesai)).format('YYYY-MM-DD'),
        jumlah_kelas: 0,
        tenggat_pembayaran: moment(new Date(state.tenggat_pembayaran)).format('0000-MM-DD')
      });

      console.log("submit", submit);

      if (submit) {
        if (!submit.data.error) {
          history.push({
            pathname: "/pengaturan/kursus",
            toast: "Berhasil mengedit kursus",
          });
          setState({
            ...state,
            loading: false,
          });
        } else {
          throw submit.data.error;
        }
      } else {
        throw errorMessage("mengedit kursus");
      }
    } catch (error) {
      toast(<Failed>{error}</Failed>);
      setState({
        ...state,
        loading: false,
      });
    }
  };

  const deleteData = async () => {
    if (window.confirm(deleteMessage(`kursus ${state.nama}`))) {
      try {
        const deleting = await Axios.post(deleteKursusURL(match.params.id));
        if (deleting.data) {
          if (!deleting.data.error) {
            history.push({
              pathname: "/pengaturan/kursus",
              toast: "Berhasil menghapus kursus",
            });
          } else {
            throw deleting.data.error;
          }
        } else {
          throw errorMessage("menghapus kursus");
        }
      } catch (error) {
        toast(<Failed>{error}</Failed>);
        setState({
          ...state,
          isLoading: false,
        });
      }
    }
  };


  console.log("state edit kursus", state);
  console.log("list edit kursus", list);

  return (
    <Private {...props}>
      <Breadcrumb data={state.breadcrumb} />
      <div className="wrapper-form">
      <Grid container justify="space-between">
          <Grid item>
            <div className="header-title">EDIT KURSUS</div>
          </Grid>
          <Grid item>
            <ButtonSecondary
              onClick={deleteData}
              icon="delete"
              color="#DC6E6E"
            >
              HAPUS
            </ButtonSecondary>
          </Grid>
        </Grid>
      </div>
      <form onSubmit={(e) => submitKursus(e)}>
        <div className="wrapper">
          {state.dataLoading || state.isLoading ? <Loading /> : <div className="container">
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <TextField
                  name="nama"
                  label="Nama"
                  placeholder="Isikan Nama"
                  className="mb-2"
                  value={state.nama}
                  onChange={(e) => onChange(e.target.value, "nama")}
                  required
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <SingleSelect
                  type="number"
                  name="pendidikan"
                  label="Pendidikan"
                  placeholder="Isikan Pendidikan"
                  className="mb-2"
                  value={state.pendidikan}
                  onChange={(e) => onChange(e, "pendidikan")}
                  options={list.listPendidikan}
                  required
                  isDisabled
                />
              </Grid>
              <Grid item xs={12} md={6}>
              <DateRange
                  label="Jadwal Pembelajaran"
                  name="durasi-pembelajaran"
                  startDate={state.tanggal_mulai}
                  endDate={state.tanggal_selesai}
                  setStartDate={(value) => onChange(value, "tanggal_mulai")}
                  setEndDate={(value) => onChange(value, "tanggal_selesai")}
                  dateFormat="MMMM yyyy"
                  showMonthYearPicker
                  required
                />
              </Grid>
              {/* <Grid item xs={12} md={6}>
                <TextField
                  type="number"
                  name="jumlah_kelas"
                  label="Jumlah Kelas"
                  placeholder="Isikan Jumlah Kelas"
                  className="mb-2"
                  value={state.jumlah_kelas}
                  onChange={(e) => onChange(e.target.value, "jumlah_kelas")}
                  required
                />
              </Grid> */}
              {/* <Grid item xs={12} md={6}>
                <SelectDate
                  type="number"
                  name="tenggat_pembayaran"
                  label="Tenggat Pembayaran"
                  className="mb-2"
                  value={state.tenggat_pembayaran}
                  onChange={(value) => onChange(value, "tenggat_pembayaran")}
                  dateFormat="dd MMMM"
                  required
                />
              </Grid> */}
              <Grid item xs={12} md={6}>
                <TextField
                  type="number"
                  name="biaya"
                  label="Biaya"
                  placeholder="Isikan Biaya"
                  className="mb-2"
                  value={state.biaya}
                  onChange={(e) => onChange(e.target.value, "biaya")}
                  required
                />
              </Grid>

            </Grid>
            <Grid className="mt-4" container spacing={3} justify="flex-end">
              <Grid item>
                <ButtonSecondary onClick={cancel}>Batalkan</ButtonSecondary>
              </Grid>
              <Grid item>
                {state.loading ? (
                  <ButtonLoading>Memproses...</ButtonLoading>
                ) : (
                  <ButtonPrimary type="submit">Simpan Perubahan</ButtonPrimary>
                )}
              </Grid>
            </Grid>
          </div>}
        </div>
      </form>
      <Toastr />
    </Private>
  );
};

export default EditKursus;
