import React, { Component } from "react";
import PanelContext from "./PanelContext";

class PanelProvider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      submit: false,
      loading: false,
    };
  }

  onChange = (name, value) => {
    this.setState({
      [name]: value,
    });
  };

  onChangePanel = (id, name, value) => {
    this.setState((prevState) => ({
      data: prevState.data.map((kelas) => {
        if (kelas.id === id || kelas.id_siswa === id) {
          return {
            ...kelas,
            [name]: value,
          };
        }
        return kelas;
      }),
    }));
  };

  render() {
    const { data, submit, loading } = this.state;
    return (
      <PanelContext.Provider
        value={{
          data,
          submit,
          loading,
          onChange: (name, value) => this.onChange(name, value),
          onChangePanel: (id, name, value) => this.onChangePanel(id, name, value)
        }}
      >
        {this.props.children}
      </PanelContext.Provider>
    );
  }
}

export default PanelProvider;
