import React from "react";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import id from "date-fns/locale/id";

function DateRangePembayaran(props) {
 
  return (
    <div className="date-range material">
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={id}>
      <DatePicker
        variant="inline"
        placeholder="Dari bulan"
        value={props.startDate}
        onChange={props.setStartDate}
        format="MMMM yyyy"
        views={["year", "month"]}
        openTo="month"
        maxDate={props.maxDate}
        minDate={props.minDate}
        maxDateMessage={<span></span>}
        disabled={props.disabled}
      />
       <span className="date-range-separator"> - </span>
      <DatePicker
        placeholder="Sampai bulan"
        variant="inline"
        value={props.endDate}
        onChange={props.setEndDate}
        format="MMMM yyyy"
        views={["year", "month"]}
        openTo="month"
        maxDate={props.maxDate}
        minDate={props.minDate}
        maxDateMessage={<span></span>}
        disabled={props.disabled}
      />
    </MuiPickersUtilsProvider>
    </div>
  );
}

export default DateRangePembayaran;
