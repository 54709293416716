/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, Fragment, useCallback } from "react";
import Private from "../../../../layout/Private";
import Breadcrumb from "../../../../component/Navigation/Breadcrumb";
import {
  Grid,
  RadioGroup,
  FormControlLabel,
  Radio,
  makeStyles,
  Switch,
} from "@material-ui/core";
import ButtonSecondary from "../../../../component/Button/ButtonSecondary";
import ButtonPrimary from "../../../../component/Button/ButtonPrimary";
import Axios from "axios";
import { toast } from "react-toastify";
import Failed from "../../../../component/Misc/Failed";
import ButtonLoading from "../../../../component/Button/ButtonLoading";
import { useHistory, useRouteMatch } from "react-router-dom";
import { cancelMessage, errorMessage } from "../../../../helper/Static";
import TextField from "../../../../component/Field/TextField";
import SelectDate from "../../../../component/Field/SelectDate";
import moment from "moment";
import Loading from "../../../../component/Misc/Loading";
import Toastr from "../../../../component/Misc/Toastr";
import {
  assignKeKelasURL,
  changeStatusURL,
  listDetailSiswaURL,
  updateSiswaURL,
} from "../../../../api/siswa";
import MaterialTable from "material-table";
import Modal from "../../../../component/Content/Modal";
import SingleSelect from "../../../../component/Field/SingleSelect";
import Success from "../../../../component/Misc/Success";
import { listPendidikanURL } from "../../../../api/sekolah";
import { findKelasByPendidikanURL } from "../../../../api/kelas";
// import StatusTag from "../../../../component/Misc/StatusTag";

const useStyles = makeStyles({
  radio: {
    "&$checked": {
      color: "#2a2a2a",

      "&:hover": {
        backgroundColor: `rgba(0,0,0, 0.04)`,
      },
    },
    "&:hover": {
      backgroundColor: `rgba(0,0,0, 0.04)`,
    },
  },
  switchBase: {
    "&$checked": {
      color: "#fff",
    },
    "&$checked + $track": {
      backgroundColor: "#FFB038",
      opacity: 1,
    },
  },
  thumb: {
    boxShadow: "0px 0px 2px rgba(0, 0, 0, 0.15)",
    border: "1px solid #E1E1E1",
  },
  label: {
    fontSize: 14,
  },
  checked: {},
  track: {},
});

const UpdateSiswa = (props) => {
  const classes = useStyles();
  const match = useRouteMatch();
  const history = useHistory();

  const [open, setOpen] = useState(false);
  const [dataPendidikan, setDataPendidikan] = useState([]);
  const [dataKelas, setDataKelas] = useState([]);
  const [pendidikan, setPendidikan] = useState(null);
  const [kelas, setKelas] = useState(null);
  const [mutasiLoading, setMutasiLoading] = useState(false);
  const [pendidikanLoading, setPendidikanLoading] = useState(false);
  const [kelasLoading, setKelasLoading] = useState(false);
  const [type, setType] = useState("");

  const [state, setState] = useState({
    nama_siswa: "",
    nama_ortu: "",
    tanggal_lahir: "",
    tempat_lahir: "",
    alamat: "",
    status: "aktif",
    nis: "",
    breadcrumb: [
      {
        name: "EEC",
        url: "/dashboard",
        active: false,
      },
      {
        name: "Siswa",
        url: "/siswa/register",
        active: false,
      },
      {
        name: "Daftar",
        url: "/siswa/daftar",
        active: false,
      },
      {
        name: "Galih Pambudi",
        url: "/siswa/daftar/12",
        active: false,
      },
      {
        name: "Edit",
        url: "/siswa/daftar/12/edit",
        active: true,
      },
    ],
  });

  const handleClickOpen = (data) => {
    setOpen(true);

    if (!data.kelas) {
      setType("naik kelas");
      setPendidikan({
        label: data.pendidikan,
        value: data.id_pendidikan,
      });
    } else {
      setType("pindah");
    }
  };

  const onChange = (value, name) => {
    setState({
      ...state,
      [name]: value,
    });
  };

  const getCurrentData = async () => {
    setState({
      ...state,
      dataLoading: true,
    });
    try {
      const siswa = await Axios.get(listDetailSiswaURL(match.params.id));
      if (!siswa.data.error) {
        setState({
          ...state,
          nama_siswa: siswa.data.data.nama_lengkap,
          nis: siswa.data.data.nis,
          jenis_kelamin:
            siswa.data.data.jenis_kelamin === "Perempuan" ? "pr" : "lk",
          tanggal_lahir: new Date(siswa.data.data.tanggal_lahir),
          tempat_lahir: siswa.data.data.tempat_lahir,
          nama_ortu: siswa.data.data.nama_orang_tua,
          alamat: siswa.data.data.alamat,
          daftar_pendidikan: siswa.data.data.daftar_pendidikan,
          breadcrumb: state.breadcrumb.map((data, i) => {
            if (i === 3) {
              return {
                ...data,
                name: siswa.data.data.nama_lengkap,
                url: `/siswa/daftar/${match.params.id}`,
              };
            }
            return data;
          }),
          dataLoading: false,
        });
      } else {
        const errorText = "Error";
        throw errorText;
      }
    } catch (error) {
      setState({
        ...state,
        dataLoading: false,
      });
      toast(<Failed>{error}</Failed>);
    }
  };

  useEffect(() => {
    getCurrentData();
  }, []);

  const getAllPendidikan = async () => {
    const urlParam = `${listPendidikanURL}`;

    try {
      setPendidikanLoading(true);
      const sekolah = await Axios.get(urlParam);
      if (sekolah) {
        setDataPendidikan(
          sekolah.data.data.map((data) => ({
            value: data.id,
            label: data.nama,
          }))
        );
      } else {
        throw new Error("Tidak dapat memuat pendidikan!");
      }
    } catch (error) {
      toast(<Failed>{error.message}</Failed>);
    } finally {
      setPendidikanLoading(false);
    }
  };

  useEffect(() => {
    if (open) {
      getAllPendidikan();
    }
  }, [open]);

  const getAllKelasByPendidikan = useCallback(async (id) => {
    try {
      setKelasLoading(true);
      const fetch = await Axios.get(findKelasByPendidikanURL(id));
      if (fetch) {
        setDataKelas(
          fetch.data.data.map((res, i) => ({
            value: res.id,
            label: res.nama,
          })) || []
        );
      } else {
        throw new Error("Tidak dapat memuat kelas!");
      }
    } catch (error) {
      toast(<Failed>{error.message}</Failed>);
    } finally {
      setKelasLoading(false);
    }
  });

  useEffect(() => {
    if (pendidikan) {
      getAllKelasByPendidikan(pendidikan.value);
    }
  }, [pendidikan]);

  const cancel = () => {
    if (window.confirm(cancelMessage(`edit kursus`))) {
      history.push("/pengaturan/kursus");
    }
  };

  const submitKursus = async (e) => {
    e.preventDefault();
    setState({
      ...state,
      loading: true,
    });

    try {
      const submit = await Axios.post(updateSiswaURL(match.params.id), {
        nama_siswa: state.nama_siswa,
        nama_ortu: state.nama_ortu,
        tempat_lahir: state.tempat_lahir,
        alamat: state.alamat,
        jenis_kelamin: state.jenis_kelamin,
        tanggal_lahir: moment(new Date(state.tanggal_lahir)).format(
          "YYYY-MM-DD"
        ),
      });

      if (submit) {
        if (!submit.data.error) {
          history.push({
            pathname: "/siswa/daftar",
            toast: "Berhasil mengedit siswa",
          });
          setState({
            ...state,
            loading: false,
          });
        } else {
          throw submit.data.error;
        }
      } else {
        throw errorMessage("mengedit siswa");
      }
    } catch (error) {
      toast(<Failed>{error}</Failed>);
      setState({
        ...state,
        loading: false,
      });
    }
  };

  const onChangeStatus = (rowData, value) => {
    setState({
      ...state,
      daftar_pendidikan: state.daftar_pendidikan.map((data) => {
        if (rowData === data.id) {
          return {
            ...data,
            status: value,
          };
        }
        return data;
      }),
    });
    changeStatus(rowData.id)
  };

  const changeStatus = async (id) => {

    try {
      const submit = await Axios.get(changeStatusURL(match.params.id, id));

      if (submit) {
        if (!submit.data.error) {
          toast(<Success>Berhasil mengubah status siswa!</Success>);
          getCurrentData()
        } else {
          throw submit.data.error;
        }
      } else {
        throw new Error(errorMessage("mengedit siswa"));
      }
    } catch (error) {
      toast(<Failed>{error.message}</Failed>);
    }
  };

  const mutasiSiswa = async (e) => {
    e.preventDefault(e);
    setMutasiLoading(true);

    try {
      const submit = await Axios.post(assignKeKelasURL, {
        id_siswa: match.params.id,
        id_kelas: kelas.value,
        id_pendidikan: pendidikan.value,
        type,
      });

      if (submit.data.error) {
        throw new Error(submit.data.error);
      }

      if (submit) {
        setKelas(null);
        setPendidikan(null);
        setOpen(false);
        toast(<Success>Berhasil memutasikan siswa</Success>);
      }
    } catch (error) {
      toast(<Failed>{error.message}</Failed>);
    } finally {
      setMutasiLoading(false);
    }
  };

  return (
    <Private {...props}>
      <Breadcrumb data={state.breadcrumb} />
      <div className="wrapper-form">
        <Grid container justify="space-between">
          <Grid item>
            <div className="header-title">
              EDIT DATA SISWA {state.nis && `- ${state.nis}`}
            </div>
          </Grid>
        </Grid>
      </div>

      <div className="wrapper table-wrapper">
        {state.dataLoading || state.isLoading ? (
          <Loading />
        ) : (
          <Fragment>
            <form onSubmit={(e) => submitKursus(e)}>
              <div className="container">
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <TextField
                      name="nama_siswa"
                      label="Nama Lengkap"
                      placeholder="Isikan Nama Lengkap"
                      className="mb-2"
                      value={state.nama_siswa}
                      onChange={(e) => onChange(e.target.value, "nama_siswa")}
                      required
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <label
                      htmlFor="tahun-ajaran-status"
                      className="input-label"
                    >
                      <span style={{ color: "#FF615B" }}>*</span>
                      <span>Jenis Kelamin</span>
                    </label>
                    <RadioGroup
                      aria-label="tahun-ajaran-status"
                      name="tahun-ajaran-status"
                      value={state.jenis_kelamin}
                      onChange={(e) =>
                        onChange(e.target.value, "jenis_kelamin")
                      }
                      style={{ flexDirection: "row" }}
                    >
                      <FormControlLabel
                        value="lk"
                        control={
                          <Radio
                            disableRipple
                            classes={{
                              root: classes.radio,
                              checked: classes.checked,
                            }}
                          />
                        }
                        label="Laki-laki"
                        style={{ width: "fit-content" }}
                      />
                      <FormControlLabel
                        value="pr"
                        control={
                          <Radio
                            disableRipple
                            classes={{
                              root: classes.radio,
                              checked: classes.checked,
                            }}
                          />
                        }
                        label="Perempuan"
                        style={{ width: "fit-content" }}
                      />
                    </RadioGroup>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      name="tempat_lahir"
                      label="Tempat Lahir"
                      placeholder="Contoh: Bandung"
                      className="mb-2"
                      value={state.tempat_lahir}
                      onChange={(e) => onChange(e.target.value, "tempat_lahir")}
                      required
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <SelectDate
                      label="Tanggal Lahir"
                      className="mb-2"
                      value={state.tanggal_lahir}
                      onChange={(e) => onChange(e, "tanggal_lahir")}
                      placeholder="Pilih Tanggal Lahir"
                      showYearDropdown
                      required
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      name="nama_ortu"
                      label="Nama Orang Tua"
                      placeholder="Isikan Nama Orang Tua"
                      className="mb-2"
                      value={state.nama_ortu}
                      onChange={(e) => onChange(e.target.value, "nama_ortu")}
                      required
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      name="alamat"
                      label="Alamat"
                      placeholder="Isikan Alamat"
                      className="mb-2"
                      value={state.alamat}
                      onChange={(e) => onChange(e.target.value, "alamat")}
                      required
                    />
                  </Grid>
                </Grid>
                <Grid className="mt-4" container spacing={3} justify="flex-end">
                  <Grid item>
                    <ButtonSecondary onClick={cancel}>Batalkan</ButtonSecondary>
                  </Grid>
                  <Grid item>
                    {state.loading ? (
                      <ButtonLoading>Memproses...</ButtonLoading>
                    ) : (
                      <ButtonPrimary type="submit">
                        Simpan Perubahan
                      </ButtonPrimary>
                    )}
                  </Grid>
                </Grid>
              </div>
            </form>

            <div className="separator-border pt-4 mb-4"></div>
            <p className="font-sm">Terdaftar sebagai siswa di</p>
            <MaterialTable
              columns={[
                {
                  title: "Pendidikan",
                  field: "pendidikan",
                  width: 250,
                },
                {
                  title: "Kelas",
                  field: "kelas",
                  render: (rowData) => (
                    <Fragment>
                      {rowData.kelas ? (
                        <div className="mb-1 link-underline text-bold">
                          {rowData.kelas}
                        </div>
                      ) : (
                        <div className="color-grey text-sm">
                          Belum memilih kelas (tingkat {rowData.tingkat})
                        </div>
                      )}
                    </Fragment>
                  ),
                },
                {
                  title: "Tahun Ajaran",
                  field: "tahun_ajaran",
                  width: 250,
                },
                {
                  title: "Status",
                  field: "status",
                  width: 200,
                  render: (rowData) => {
                    return (
                      <div>
                        <Switch
                          classes={{
                            switchBase: classes.switchBase,
                            thumb: classes.thumb,
                            track: classes.track,
                            checked: classes.checked,
                          }}
                          size="small"
                          checked={rowData.status === "aktif"}
                          onChange={() =>
                            onChangeStatus(
                              rowData,
                              rowData.status === "aktif" ? "non-aktif" : "aktif",
                            )
                          }
                        />
                        <p
                          className={`sentence-case text-bold ${
                            rowData.status === "aktif"
                              ? "color-secondary"
                              : "color-grey"
                          }`}
                        >
                          {rowData.status}
                        </p>
                      </div>
                    );
                  },
                },

                {
                  title: "",
                  render: (rowData) => {
                    return (
                      <div className="table-action">
                        {((rowData.kelas && rowData.status === "aktif") ||
                          !rowData.kelas) && (
                          <button
                            className="d-flex"
                            onClick={() => handleClickOpen(rowData)}
                            type="button"
                          >
                            <i className="material-icons table-edit mr-2 color-midnight-blue">
                              exit_to_app
                            </i>
                            <span
                              className="color-midnight-blue link-underline text-bold"
                              style={{ transform: "translate(-8px, 3px)" }}
                            >
                              {rowData.kelas ? "Mutasi Siswa" : "Pilih Kelas"}
                            </span>
                          </button>
                        )}
                      </div>
                    );
                  },
                },
                {
                  title: "id",
                  field: "id",
                  hidden: true,
                },
                {
                  title: "kursus",
                  field: "kursus",
                  hidden: true,
                },
              ]}
              isLoading={state.isLoading}
              data={state.daftar_pendidikan}
              options={{
                toolbar: false,
                padding: "dense",
                pageSize: 10,
                paging: false,
              }}
            />
          </Fragment>
        )}
      </div>

      <Modal
        open={open}
        handleClose={() => {
          setOpen(false);
          setKelas(null);
        }}
        title="Mutasi Siswa"
      >
        <form onSubmit={(e) => mutasiSiswa(e)}>
          <div style={{ minWidth: 418 }}>
            <SingleSelect
              name="pendidikan"
              label="Pendidikan"
              placeholder="Isikan Pendidikan"
              className="mb-4"
              value={pendidikan}
              onChange={(e) => setPendidikan(e)}
              options={dataPendidikan}
              isLoading={pendidikanLoading}
              isDisabled={type === "naik kelas"}
              required
            />
            <SingleSelect
              name="kelas"
              label="Kelas"
              placeholder="Isikan Kelas"
              className="mb-4"
              value={kelas}
              onChange={(e) => setKelas(e)}
              options={dataKelas}
              required
              isLoading={kelasLoading}
            />
            {mutasiLoading ? (
              <ButtonLoading>Memproses...</ButtonLoading>
            ) : (
              <ButtonPrimary type="submit">Mutasi Siswa</ButtonPrimary>
            )}
          </div>
        </form>
      </Modal>
      <Toastr />
    </Private>
  );
};

export default UpdateSiswa;
