import React, { Component } from "react";
import Axios from "axios";
import { errorMessage } from "../../helper/Static";
import RegistrasiSiswaContext from "./RegisterSiswaContext";
import { cekUlangRegistrasiURL } from "../../api/siswa";
import moment from "moment";

class RegisterSiswaProvider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataSiswa: {
        nama_siswa: "",
        jenis_kelamin: "lk",
        tempat_lahir: "",
        tanggal_lahir: null,
        nama_ortu: "",
        alamat: "",
      },
      sekolah: {
        tahun_ajaran: "",
        jenjang_pendidikan: "",
        kelas: "",
      },
      kursus: [],
      inventori: [],
      createdData: {},
      activePanel: 1,
      isLoading: true,
      isCreated: false,
      isReset: false,
    };
  }

  onChangeObject = (type, name, value) => {
    console.log("on change object", type, name, value);
    this.setState((prevState) => ({
      [type]: {
        ...prevState[type],
        [name]: value,
      },
    }));
  };

  onChangeArray = (name, value) => {
    this.setState({
      [name]: value,
    });
  };

  createSiswa = async (url) => {
    const { sekolah, dataSiswa, kursus, inventori } = this.state;

    try {
      // Prepare submit data
      const checkInventory = inventori.length && { inventori };
      const checkKursus = kursus.length && { kursus };

      const siswaData = {
        ...dataSiswa,
        tanggal_lahir: moment(new Date(dataSiswa.tanggal_lahir)).format('YYYY-MM-DD')
      }
  

      const submit = await Axios.post(url, {
        ...sekolah,
        ...siswaData,
        ...checkInventory,
        ...checkKursus,
      });

      console.log("submit", submit);

      if (submit) {
        if (!submit.data.error) {
          return {
            error: null,
            type: null,
            data: submit.data.data,
          };
        } else {
          const errorData = {
            error: submit.data.error,
            type: "submit",
            data: null,
          };
          throw errorData;
        }
      } else {
        const errorData = {
          error: errorMessage("membuat kelas"),
          type: "submit",
          data: null,
        };
        throw errorData;
      }
    } catch (error) {
      return error;
    }
  };

  checkRegistrasi = async () => {
    const { sekolah, dataSiswa, kursus, inventori } = this.state;

    try {
      // Prepare submit data
      const checkInventory = inventori.length && { inventori };
      const checkKursus = kursus.length && { kursus };

      const submit = await Axios.post(cekUlangRegistrasiURL, {
        ...sekolah,
        ...dataSiswa,
        ...checkInventory,
        ...checkKursus,
      });

      console.log("submit", submit);

      if (submit) {
        if (!submit.data.error) {
          return {
            error: null,
            type: null,
            data: submit.data.data,
          };
        } else {
          const errorData = {
            error: submit.data.error,
            type: "submit",
            data: null,
          };
          throw errorData;
        }
      } else {
        const errorData = {
          error: errorMessage("membuat kelas"),
          type: "submit",
          data: null,
        };
        throw errorData;
      }
    } catch (error) {
      return error;
    }
  };

  reset = () => {
    this.setState({
      dataSiswa: {
        nama_siswa: "",
        jenis_kelamin: "lk",
        tempat_lahir: "",
        tanggal_lahir: null,
        nama_ortu: "",
        alamat: "",
      },
      sekolah: {
        tahun_ajaran: "",
        jenjang_pendidikan: "",
        kelas: "",
      },
      kursus: [],
      inventori: [],
      createdData: {},
      activePanel: 1,
      isLoading: true,
      isCreated: false,
    });
  };

  render() {
    const {
      sekolah,
      dataSiswa,
      kursus,
      inventori,
      isSubmit,
      activePanel,
      isReset,
      isCreated,
      createdData,
    } = this.state;

    return (
      <RegistrasiSiswaContext.Provider
        value={{
          sekolah,
          dataSiswa,
          kursus,
          inventori,
          isSubmit,
          activePanel,
          isCreated,
          createdData,
          isReset,
          reset: this.reset,
          checkRegistrasi: this.checkRegistrasi,
          submitSiswa: (url) => this.createSiswa(url),
          onChangeArray: (name, value) => this.onChangeArray(name, value),
          onChangeObject: (type, name, value) =>
            this.onChangeObject(type, name, value),
        }}
      >
        {this.props.children}
      </RegistrasiSiswaContext.Provider>
    );
  }
}

export default RegisterSiswaProvider;
