/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useState, useEffect, Fragment } from "react";
import {
    Page,
    Text,
    View,
    Document,
    StyleSheet,
    PDFViewer,
    Image,
    Font,
} from "@react-pdf/renderer";
import queryString from "query-string";
import logoInvoice from "../../../../assets/img/logo-invoice.png";
import Axios from "axios";
import { listLaporanURL } from "../../../../api/laporan";
import Loading from "../../../../component/Misc/Loading";
import { useLocation } from "react-router-dom";
import moment from "moment";
import priceFormat from "../../../../helper/PriceFormat";
import LinkOutlined from "../../../../component/Button/LinkOutlined";
import successImage from "../../../../assets/img/success-image.svg";

Font.register({
    family: "Open Sans",
    fonts: [
        {
            src:
                "https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-regular.ttf",
        },
        {
            src:
                "https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-600.ttf",
            fontWeight: 600,
        },
    ],
});
// Create styles
const styles = StyleSheet.create({
    page: {
        paddingTop: 40,
        paddingBottom: 40,
        paddingHorizontal: 32,
        fontSize: 10,
        fontFamily: "Open Sans",
    },
    sectionTitle: {
        width: "100%",
        textAlign: "center",
        marginBottom: 10,
    },
    sectionHeader: {
        width: "100%",
        textAlign: "left",
        marginBottom: 10,
    },
    sectionInfo: {
        width: "100%",
        textAlign: "left",
        marginBottom: 24,
        marginTop: 54,
    },
    sectionNote: {
        width: "40%",
        textAlign: "left",
        marginBottom: 10,
        marginTop: 32,
    },
    sectionTable: {
        width: "100%",
        textAlign: "left",
        marginBottom: 10,
    },
    flexRow: {
        display: "flex",
        flexDirection: "row",
        marginBottom: 4,
    },
    title: {
        fontSize: 16,
        fontWeight: 600,
        color: "#1E3C67",
        textAlign: "right",
        marginBottom: 8,
    },
    logo: {
        height: 56,
        width: 99,
    },
    smallWidth: {
        width: 64,
    },
    widthXl: {
        width: "60%",
    },
    widthXl2: {
        width: "50%",
    },
    widthXl3: {
        width: "40%",
    },
    widthSm: {
        width: "35%",
    },
    widthXs: {
        width: "7.5%",
    },
    widthSm2: {
        width: "20%",
    },
    widthXs2: {
        width: "12.5%",
    },
    colorSadGrey: {
        color: "#80848D",
    },
    textRight: {
        textAlign: "right",
    },
    textBold: {
        fontWeight: 600,
    },
    tableHeader: {
        backgroundColor: "#1E3C67",
        padding: "4px 8px",
        color: "#fff",
    },
    tableContent: {
        padding: "4px 8px",
    },
    tableFooter: {
        padding: "8px 8px",
        backgroundColor: "#F3F3F3",
    },
    tableBorder: {
        borderBottom: "1px solid #E1E1E1",
    },
    line: {
        height: 24,
        borderBottom: "1px solid #E1E1E1",
        width: "40%",
    },
});

// Create Document Component
const LaporanPdf = (props) => {
    console.log("resi pdf", props);
    const location = useLocation();
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(null);
    const [query, setQuery] = useState(null)

    const getAllData = useCallback(async (query) => {

        try {
            const fetch = await Axios.get(listLaporanURL, {
                params: {
                    ...query,
                },
            });
            if (!fetch.data.error) {
                let pdfData = fetch.data.data.list
                if (query.pengeluaran && !query.pendapatan) {
                    pdfData = fetch.data.data.list.filter(data => data.pengeluaran)
                }
                if (query.pendapatan && !query.pengeluaran) {
                    pdfData = fetch.data.data.list.filter(data => data.pemasukan)
                }
                setData({
                    ...fetch.data.data,
                    list: pdfData,
                })
                setLoading(false)

            } else {
                throw new Error(fetch.data.error);
            }
        } catch (error) {
            setLoading(false)
            setError(error)
        }
    }, []);

    useEffect(() => {
        if (query) {
            getAllData(query);
        }
    }, [getAllData, query]);

    useEffect(() => {
        const query = queryString.parse(location.search);
        setQuery({
            ...query,
            pendapatan: query.pendapatan === 'true',
            pengeluaran: query.pengeluaran === 'true',
        })
    }, [])

    console.log('data', data)
    console.log('query', query)


    if (loading) {
        return <Loading />;
    }

    if (error) {
        return  <div className="wrapper" style={{margin: '32px auto', height: 'calc(100vh - 64px)', width: '90vw' }}>
        <div className="siswa-created" style={{ height: '100%' }}>
          <img src={successImage} alt="success" />
          <div className="mt-4">
           
            {error.message}
          </div>
          <div className="mt-4">
            <LinkOutlined address="/akunting/laporan">Kembali ke Laporan</LinkOutlined>
          </div>
        </div>
      </div>
    }

    return (
        <PDFViewer>
            <Document>
                <Page size="A4" style={styles.page}>
                    <View style={styles.sectionHeader}>
                        <View style={styles.flexRow}>
                            <View style={styles.widthXl3}>
                                <Image src={logoInvoice} style={styles.logo} />
                            </View>
                            <View style={styles.widthXl}>
                                <Text style={styles.title}>LAPORAN
                                {query.pendapatan && ' PENDAPATAN'}
                                    {query.pendapatan && query.pengeluaran && ' &'}
                                    {query.pengeluaran && ' PENGELUARAN'}</Text>
                                <View style={{ ...styles.flexRow, display: 'flex', justifyContent: 'flex-end' }}>
                                    <Text style={{ ...styles.colorSadGrey }}>Periode</Text>
                                    <Text style={{ ...styles.textBold, marginLeft: 8 }}>{data.periode}</Text>
                                </View>

                            </View>
                        </View>
                    </View>

                    <View style={styles.sectionTable}>
                        <View style={styles.flexRow}>
                            <View style={{ ...styles.widthXs, ...styles.tableHeader }}>
                                <Text style={styles.textBold}>No</Text>
                            </View>
                            <View style={{ ...styles.widthSm2, ...styles.tableHeader }}>
                                <Text style={styles.textBold}>Tanggal</Text>
                            </View>
                            <View style={{ ...styles.widthSm, ...styles.tableHeader }}>
                                <Text style={styles.textBold}>Nama</Text>
                            </View>
                            <View style={{ ...styles.widthSm2, ...styles.tableHeader }}>
                                <Text style={styles.textBold}>
                                    {query.pendapatan && !query.pengeluaran && 'No. Struk'}
                                    {query.pengeluaran && !query.pendapatan && 'No. Struk'}
                                    {query.pendapatan && query.pengeluaran && 'Pendapatan'}
                                </Text>
                            </View>
                            <View style={{ ...styles.widthSm2, ...styles.tableHeader }}>
                                <Text style={styles.textBold}>
                                    {query.pendapatan && !query.pengeluaran && 'Jumlah'}
                                    {query.pengeluaran && !query.pendapatan && 'Jumlah'}
                                    {query.pendapatan && query.pengeluaran && 'Pengeluaran'}
                                </Text>
                            </View>
                        </View>
                        {data.list?.map((item, index) => {
                            return (
                                <View key={index} style={{ ...styles.flexRow, ...styles.tableBorder }}>
                                    <View style={{ ...styles.widthXs, ...styles.tableContent }}>
                                        <Text>{index + 1}</Text>
                                    </View>
                                    <View style={{ ...styles.widthSm2, ...styles.tableContent }}>
                                        <Text>{moment(new Date(item.tanggal)).format('DD/MM/YYYY')}</Text>
                                    </View>
                                    <View style={{ ...styles.widthSm, ...styles.tableContent }}>
                                        <Text>{item.nama}</Text>
                                        {query.pendapatan && query.pengeluaran && <Text style={{ ...styles.colorSadGrey }}>{item.no_struk}</Text>}

                                    </View>
                                    <View style={{ ...styles.widthSm2, ...styles.tableContent }}>
                                        {query.pendapatan && !query.pengeluaran && <Text>{item.no_struk}</Text>}
                                        {query.pengeluaran && !query.pendapatan && <Text>{item.no_struk}</Text>}
                                        {query.pendapatan && query.pengeluaran && <Text>{item.pemasukan ? priceFormat(item.pemasukan) : '-'}</Text>}

                                    </View>
                                    <View style={{ ...styles.widthSm2, ...styles.tableContent }}>
                                        {query.pendapatan && !query.pengeluaran && <Text>{priceFormat(item.pemasukan)}</Text>}
                                        {query.pengeluaran && !query.pendapatan && <Text>{priceFormat(item.pengeluaran)}</Text>}
                                        {query.pendapatan && query.pengeluaran && <Text>{item.pengeluaran ? priceFormat(item.pengeluaran) : '-'}</Text>}

                                    </View>
                                </View>
                            )
                        })}

                        <View style={{...styles.flexRow, marginBottom: 0, marginTop: 8}}>
                            <View style={{ ...styles.widthXl, ...styles.tableFooter }}>
                                {/* <Text>1</Text> */}
                            </View>
                            <View style={{ ...styles.widthSm2, ...styles.tableFooter }}>
                                <Text style={styles.textBold}>
                                    {query.pendapatan && !query.pengeluaran && 'Total'}
                                    {query.pengeluaran && !query.pendapatan && 'Total'}
                                    {query.pendapatan && query.pengeluaran && 'Total Pendapatan'}
                                </Text>
                            </View>
                            <View style={{ ...styles.widthSm2, ...styles.tableFooter }}>
                                <Text style={styles.textBold}>
                                    {query.pendapatan && !query.pengeluaran && priceFormat(data.totalPendapatan)}
                                    {query.pengeluaran && !query.pendapatan && priceFormat(data.totalPengeluaran)}
                                    {query.pendapatan && query.pengeluaran && priceFormat(data.totalPendapatan)}
                                </Text>
                            </View>

                        </View>
                        {query.pendapatan && query.pengeluaran && <Fragment>
                            <View style={{...styles.flexRow, marginBottom: 0}}>
                                <View style={{ ...styles.widthXl, ...styles.tableFooter }}>
                                    {/* <Text>1</Text> */}
                                </View>
                                <View style={{ ...styles.widthSm2, ...styles.tableFooter }}>
                                    <Text style={styles.textBold}>Total Pengeluaran</Text>
                                </View>
                                <View style={{ ...styles.widthSm2, ...styles.tableFooter }}>
                                    <Text style={styles.textBold}>{priceFormat(data.totalPengeluaran)}</Text>
                                </View>
                            </View>
                            <View style={styles.flexRow}>
                                <View style={{ ...styles.widthXl, ...styles.tableFooter }}>
                                    {/* <Text>1</Text> */}
                                </View>
                                <View style={{ ...styles.widthSm2, ...styles.tableFooter }}>
                                    <Text style={styles.textBold}>-</Text>
                                </View>
                                <View style={{ ...styles.widthSm2, ...styles.tableFooter }}>
                                    <Text style={styles.textBold}>{priceFormat(data.total)}</Text>
                                </View>
                            </View>
                        </Fragment>}

                    </View>
                </Page>
            </Document>
        </PDFViewer>
    );
};

export default LaporanPdf;
