import React, { useCallback, useContext, useEffect, useState } from "react";
import { Popper, Fade, Paper } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import UserContext from "../../context/User/UserContext";
import Axios from "axios";
import { currentTahunAjaranURL } from "../../api/tahunAjaran";
import Failed from "../Misc/Failed";
import { toast } from "react-toastify";
import moment from "moment";

const Navbar = (props) => {
  const user = useContext(UserContext);
  const history = useHistory();
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);
  const [tahunAjaran, setTahunAjaran] = useState(localStorage.getItem('ta'));

  const handleClick = (e) => {
    setAnchorEl(e.currentTarget);
    setOpen(!open);
  };

  const logout = () => {
    localStorage.clear();
    history.push("/");
  };

  const getCurrentPeriod = useCallback(async () => {
    try {
      const period = await Axios.get(currentTahunAjaranURL);
      const oldTa = localStorage.getItem('ta');
      let merged = "";
      if (period.data.data.length) {
        merged = `${moment(new Date(period.data.data[0].tanggal_mulai)).format(
          "MMMM YYYY"
        )} - ${moment(new Date(period.data.data[0].tanggal_selesai)).format(
          "MMMM YYYY"
        )}`;
      }
      setTahunAjaran(merged);
      if(oldTa !== merged) {
      localStorage.setItem('ta', merged)
      }
    } catch (error) {
      toast(<Failed>{error.message}</Failed>);
    }
  }, []);

  useEffect(() => {
    getCurrentPeriod();
  }, [getCurrentPeriod]);

  console.log("user", tahunAjaran);

  return (
    <div className="navbar">
      <div className="navbar-main">
        {tahunAjaran && <div className="navbar-period">TA {tahunAjaran}</div>}
  
        <span className="md-none">
          Selamat Datang di EEC School Management System
        </span>
      </div>
      <div>
        <button className="navbar-menu-button" onClick={(e) => handleClick(e)}>
          Hi, {user.user.name} <i className="material-icons">arrow_drop_down</i>
        </button>
        <Popper
          open={open}
          anchorEl={anchorEl}
          placement="bottom-end"
          transition
        >
          {({ TransitionProps }) => (
            <Fade {...TransitionProps} timeout={350}>
              <Paper>
                <div className="navbar-menu">
                  <button onClick={logout}>Logout</button>
                </div>
              </Paper>
            </Fade>
          )}
        </Popper>
      </div>
    </div>
  );
};

export default Navbar;
