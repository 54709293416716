/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useCallback } from "react";
import Breadcrumb from "../../../../component/Navigation/Breadcrumb";
import Private from "../../../../layout/Private";
import TablePagination from "../../../../component/Navigation/TablePagination";
import MaterialTable from "material-table";
import { Grid, Tooltip } from "@material-ui/core";
import LinkOutlined from "../../../../component/Button/LinkOutlined";
import { useLocation, Link } from "react-router-dom";
import { toast } from "react-toastify";
import Failed from "../../../../component/Misc/Failed";
import Success from "../../../../component/Misc/Success";
import Toastr from "../../../../component/Misc/Toastr";
import Axios from "axios";
import { errorMessage, deleteMessage } from "../../../../helper/Static";
import { deleteKelasURL, listKelasURL } from "../../../../api/kelas";
import TextField from "../../../../component/Field/TextField";
import useDebounce from "../../../../helper/useDebounce";

const Kelas = props => {
  const location = useLocation();
  const [state, setState] = useState({
    breadcrumb: [
      {
        name: "EEC",
        url: "/dashboard",
        active: false,
      },
      {
        name: "Pengaturan",
        url: "/pengaturan",
        active: false,
      },
      {
        name: "Kelas",
        url: "/pengaturan/kelas",
        active: true,
      },
    ],
    kelas: [],
    total: '',
    isLoading: true,
  });

  const [page, setPage] = useState(1);
  const [search, setSearch] = useState('');
  const debouncedSearch = useDebounce(search, 500);

  const getAllData = useCallback(async () => {
    setState({
      ...state,
      isLoading: true,
    });

    const urlParam = `${listKelasURL}?page=${page}${search ? `&search=${debouncedSearch}` : ''}`

    try {
      const kelas = await Axios.get(urlParam);
      if (kelas) {
        // console.log("tahun ajaran", kelas);
        setState({
          ...state,
          kelas: kelas.data.data,
          total: kelas.data.total,
          isLoading: false,
        });
      } else {
        const errorText = "Error";
        throw errorText;
      }
    } catch (error) {
      setState({
        ...state,
        isLoading: false,
      });
      toast(<Failed>{error}</Failed>);
    }
  }, [page, debouncedSearch]);

  useEffect(() => {
    getAllData();
  }, [getAllData]);

  useEffect(() => {
    if (location.toast) {
      toast(<Success>{location.toast}</Success>);
    }
  }, [])

  const deleteKelas = async (id, text) => {
    if (window.confirm(deleteMessage(`kelas ${text}`))) {
      try {
        const deleting = await Axios.post(deleteKelasURL(id));
        if (deleting) {
          if (!deleting.data.error) {
            toast(<Success>{deleting.data.data}</Success>);
            getAllData();
          } else {
            throw deleting.data.error;
          }
        } else {
          throw errorMessage("menghapus kelas");
        }
      } catch (error) {
        toast(<Failed>{error}</Failed>);
        setState({
          ...state,
          isLoading: false,
        });
      }
    }
  };

  const onChangeSearch = (e) => {
    setSearch(e.target.value);
    setPage(1);
  }

  return (
    <Private {...props}>
      <Breadcrumb data={state.breadcrumb} />
      <div className="wrapper table-wrapper">
        <Grid container className="mb-3" spacing={3} justify="flex-end">
          <Grid item>
            <LinkOutlined address="/pengaturan/kelas/baru" icon="add">
              TAMBAH KELAS
            </LinkOutlined>
          </Grid>
          <Grid item xs={12} lg={4}>
            <TextField 
              value={search}
              placeholder="Cari Kelas"
              onChange={e => onChangeSearch(e)}
            />
          </Grid>
        </Grid>
        <div className="table-title">Daftar Kelas</div>
        <MaterialTable
          columns={[
            {
              title: "Nama Kelas",
              field: "nama",
            },
            {
              title: "Tingkat",
              field: "tingkat",
              render: (rowData) => <div>{rowData.tingkat || "-"}</div>,
            },
            {
              title: "Pendidikan",
              field: "pendidikan",
              render: (rowData) => <div>{rowData.pendidikan?.nama || rowData.kursus?.nama}</div>,
            },
            {
              title: "",
              render: (rowData) => {
                return (
                  <div className="table-action">
                    <Tooltip title="Edit kelas" arrow>
                      <Link to={`/pengaturan/kelas/${rowData.id}/edit`}>
                        <i className="material-icons table-edit">edit</i>
                      </Link>
                    </Tooltip>
                    <Tooltip title="Hapus kelas" arrow>
                      <button
                        onClick={() =>
                          deleteKelas(rowData.id, rowData.nama)
                        }
                      >
                        <i className="material-icons table-delete">delete</i>
                      </button>
                    </Tooltip>
                  </div>
                );
              },
            },

            {
              title: "id",
              field: "id",
              hidden: true,
            },
            {
              title: "kursus",
              field: "kursus",
              hidden: true,
            },
          ]}
          isLoading={state.isLoading}
          data={state.kelas}
          options={{
            toolbar: false,
            padding: "dense",
            pageSize: 10,
          }}
          components={{
            Pagination: (props) => {
              // console.log("pagination props", props);
              return (
                <td>
                  <TablePagination
                    onChangePage={(e) => setPage(e)}
                    total={state.total}
                    page={page}
                    itemShow={props.rowsPerPage}
                  />
                </td>
              );
            },
          }}
        />
      </div>
      <Toastr />
    </Private>
  );
};

export default Kelas;
