const administratorMenu = [
  {
    name: "Beranda",
    url: "/dashboard",
    icon: "dashboard",
    selected: false,
    open: false,
    subMenu: []
  },
  {
    name: "Siswa",
    url: "/siswa",
    icon: "people",
    selected: false,
    open: false,
    subMenu: [
      {
        name: "Registrasi Siswa",
        url: "/siswa/registrasi",
        selected: false
      },
      {
        name: "Daftar Siswa",
        url: "/siswa/daftar",
        selected: false
      }
    ]
  },
  {
    name: "Kelas",
    icon: "cast_connected",
    url: "/kelas-list",
    open: false,
    selected: false,
    subMenu: [
      {
        url: "/kelas-list/daftar",
        name: "Daftar Kelas",
        selected: false
      },
      {
        url: "/kelas-list/kenaikan",
        name: "Kenaikan Kelas",
        selected: false
      }
    ]
  },
  {
    name: "Pembayaran",
    url: "/pembayaran",
    icon: "payment",
    open: false,
    selected: false,
    subMenu: [
      {
        name: "Pembayaran Baru",
        url: "/pembayaran/baru",

        selected: false
      },
      {
        name: "Daftar Pembayaran",
        url: "/pembayaran/daftar",

        selected: false
      }
    ]
  },
  {
    name: "Akunting",
    url: "/akunting",
    icon: "insert_chart",
    open: false,
    selected: false,
    subMenu: [
      {
        url: "/akunting/penagihan",
        name: "Penagihan",
        selected: false
      },
      {
        name: "Pengeluaran",
        url: "/akunting/pengeluaran",
        selected: false
      },
      {
        name: "Laporan",
        url: "/akunting/laporan",
        selected: false
      }
    ]
  },
  {
    name: "Pengaturan",
    icon: "settings",
    url: "/pengaturan",
    open: false,
    selected: false,
    subMenu: [
      {
        name: "Tahun Ajaran",
        url: "/pengaturan/tahun-ajaran",
        selected: false
      },
      {
        name: "Sekolah",
        url: "/pengaturan/sekolah",
        selected: false
      },
      {
        url: "/pengaturan/kursus",
        name: "Kursus",
        selected: false
      },
      {
        url: "/pengaturan/kelas",
        name: "Kelas",
        selected: false
      },
      {
        url: "/pengaturan/user",
        name: "User",
        selected: false
      }
    ]
  }
];

const supervisorMenu = [
  {
    name: "Siswa",
    url: "/siswa",
    icon: "people",
    selected: false,
    open: false,
    subMenu: [
      {
        name: "Registrasi Siswa",
        url: "/siswa/registrasi",
        selected: false
      },
      {
        name: "Daftar Siswa",
        url: "/siswa/daftar",
        selected: false
      }
    ]
  },
  {
    name: "Kelas",
    icon: "cast_connected",
    url: "/kelas-list",
    open: false,
    selected: false,
    subMenu: [
      {
        url: "/kelas-list/daftar",
        name: "Daftar Kelas",
        selected: false
      },
      {
        url: "/kelas-list/kenaikan",
        name: "Kenaikan Kelas",
        selected: false
      }
    ]
  },
  {
    name: "Pembayaran",
    url: "/pembayaran",
    icon: "payment",
    open: false,
    selected: false,
    subMenu: [
      {
        name: "Pembayaran Baru",
        url: "/pembayaran/baru",
        selected: false
      },
      {
        name: "Daftar Pembayaran",
        url: "/pembayaran/daftar",
        selected: false
      }
    ]
  },
  {
    name: "Akunting",
    url: "/akunting",
    icon: "insert_chart",
    open: false,
    selected: false,
    subMenu: [
      {
        url: "/akunting/penagihan",
        name: "Penagihan",
        selected: false
      },
      {
        name: "Pengeluaran",
        url: "/akunting/pengeluaran",
        selected: false
      },
    ]
  },
];

const kasirMenu = [
  {
    name: "Siswa",
    url: "/siswa",
    icon: "people",
    selected: false,
    open: false,
    subMenu: [
      {
        name: "Registrasi Siswa",
        url: "/siswa/registrasi",
        selected: false
      },
      {
        name: "Daftar Siswa",
        url: "/siswa/daftar",
        selected: false
      }
    ]
  },
  {
    name: "Pembayaran",
    url: "/pembayaran",
    icon: "payment",
    open: false,
    selected: false,
    subMenu: [
      {
        name: "Pembayaran Baru",
        url: "/pembayaran/baru",

        selected: false
      },
      {
        name: "Daftar Pembayaran",
        url: "/pembayaran/daftar",

        selected: false
      }
    ]
  },
  {
    name: "Akunting",
    url: "/akunting",
    icon: "insert_chart",
    open: false,
    selected: false,
    subMenu: [
      {
        url: "/akunting/penagihan",
        name: "Penagihan",
        selected: false
      },
      {
        name: "Pengeluaran",
        url: "/akunting/pengeluaran",
        selected: false
      },
    ]
  },
];

export {
  supervisorMenu,
  administratorMenu,
  kasirMenu
};
