const errorMessage = type => {
    return `Tidak dapat ${type}. Mohon coba kembali!`;
}

const deleteMessage = type => {
    return `Anda akan menghapus ${type}?`;
}

const cancelMessage = type => {
    return `Anda akan membatalkan ${type}?`;
}

export {
    errorMessage,
    deleteMessage,
    cancelMessage
}