import React from "react";
import Pagination from "react-js-pagination";

const TablePagination = props => {
  console.log("pagination props", props);

  let itemShowingStart;
  if (props.page > 1) {
    itemShowingStart = (props.page - 1) * props.itemShow + 1;
  } else {
    itemShowingStart = 1;
  }

  let itemShowingEnd;
  if (props.itemShow * props.page > props.total) {
    itemShowingEnd = props.total;
  } else {
    itemShowingEnd = props.itemShow * props.page;
  }

  return (
    <div className="table-footer">
      <div className="table-footer__item">
        Menampilkan {itemShowingStart} hingga {itemShowingEnd} dari{" "}
        {props.total} entri
      </div>
      <Pagination
        linkClassPrev="pagination-prev"
        linkClassNext="pagination-next"
        hideFirstLastPages
        prevPageText={<div>Sebelumnya</div>}
        nextPageText={<div>Selanjutnya</div>}
        activePage={props.page}
        itemsCountPerPage={parseInt(props.itemShow, 10)}
        totalItemsCount={parseInt(props.total, 10)}
        onChange={props.onChangePage}
      />
    </div>
  );
};

export default TablePagination;
