import React from "react";

const StatusTag = (props) => {
  return (
    <div
      className="status-tag"
      style={{
        background: "#FFAA2C" || props.background,
        color: "#fff" || props.color,
      }}
    >
      {props.children}
    </div>
  );
};

export default StatusTag;
