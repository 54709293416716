/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import moment from "moment";
import Private from "../../../../layout/Private";
import Breadcrumb from "../../../../component/Navigation/Breadcrumb";
import {
  Grid,
  RadioGroup,
  FormControlLabel,
  Radio,
  makeStyles,
} from "@material-ui/core";
import DateRange from "../../../../component/Field/DateRange";
import ButtonSecondary from "../../../../component/Button/ButtonSecondary";
import ButtonPrimary from "../../../../component/Button/ButtonPrimary";
import Axios from "axios";
import {
  findIdTahunAjaranURL,
  deleteTahunAjaranURL,
  updateTahunAjaranURL,
} from "../../../../api/tahunAjaran";
import { toast } from "react-toastify";
import Failed from "../../../../component/Misc/Failed";
import ButtonLoading from "../../../../component/Button/ButtonLoading";
import { useHistory, useRouteMatch } from "react-router-dom";
import { cancelMessage, deleteMessage, errorMessage } from "../../../../helper/Static";
import Loading from "../../../../component/Misc/Loading";
import Toastr from "../../../../component/Misc/Toastr";

const useStyles = makeStyles({
  radio: {
    "&$checked": {
      color: "#2a2a2a",

      "&:hover": {
        backgroundColor: `rgba(0,0,0, 0.04)`,
      },
    },
    "&:hover": {
      backgroundColor: `rgba(0,0,0, 0.04)`,
    },
  },
  checked: {},
});

const EditTahunAjaran = props => {
  const history = useHistory();
  const match = useRouteMatch();
  const classes = useStyles();

  const [state, setState] = useState({
    startDate: new Date(),
    endDate: new Date(),
    status: "aktif",
    breadcrumb: [
      {
        name: "EEC",
        url: "/dashboard",
        active: false,
      },
      {
        name: "Pengaturan",
        url: "/pengaturan",
        active: false,
      },
      {
        name: "Tahun Ajaran",
        url: "/pengaturan/tahun-ajaran",
        active: false,
      },
      {
        name: "Edit Tahun Ajaran",
        url: "/pengaturan/tahun-ajaran/edit",
        active: true,
      },
    ],
  });

  const getCurrentTahunAjaran = async () => {
    setState({
      ...state,
      isLoading: true,
    });
    try {
      const kursus = await Axios.get(findIdTahunAjaranURL(match.params.id));
      if (!kursus.data.error) {
        setState({
          ...state,
          startDate: new Date(kursus.data.data.tanggal_mulai),
          endDate: new Date(kursus.data.data.tanggal_selesai),
          status: kursus.data.data.status,
          // isLoading: false,
        });
      } else {
        const errorText = "Error";
        throw errorText;
      }
    } catch (error) {
      setState({
        ...state,
        isLoading: false,
      });
      toast(<Failed>{error}</Failed>);
    }
  };

  useEffect(() => {
    getCurrentTahunAjaran();
  }, []);

  const onChange = (value, name) => {
    setState({
      ...state,
      [name]: value,
    });
  };

  const cancel = () => {
    if (window.confirm(cancelMessage(`tambah tahun ajaran baru`))) {
      history.push("/pengaturan/tahun-ajaran");
    }
  };

  const submitTahunAjaran = async (e) => {
    e.preventDefault();
    setState({
      ...state,
      loading: true,
    });

    try {
      const submit = await Axios.post(updateTahunAjaranURL(match.params.id), {
        tanggal_mulai: moment(state.startDate).format("YYYY-MM"),
        tanggal_selesai: moment(state.endDate).format("YYYY-MM"),
        status: state.status,
      });

      console.log("submit", submit);

      if (submit) {
        if (!submit.data.error) {
          history.push({
            pathname: "/pengaturan/tahun-ajaran",
            toast: "Berhasil mengedit tahun ajaran baru",
          });
          setState({
            ...state,
            loading: false,
          });
        } else {
          throw submit.data.error;
        }
      } else {
        const errorText =
          "Tidak dapat mengedit tahun ajaran untuk saat ini. Mohon coba kembali!";
        throw errorText;
      }
    } catch (error) {
      toast(<Failed>{error}</Failed>);
      setState({
        ...state,
        loading: false,
      });
    }
  };
  
  const deleteTahunAjaran = async () => { 
    const text = `${moment(new Date(state.startDate)).format(
      "MMMM YYYY"
    )} - ${moment(new Date(state.endDate)).format(
      "MMMM YYYY"
    )}`;

    if (window.confirm(deleteMessage(`tahun ajaran ${text}`))) {
      try {
        const deleting = await Axios.post(deleteTahunAjaranURL(match.params.id));
        if (deleting) {
          if (!deleting.error) {
            history.push({
              pathname: "/pengaturan/tahun-ajaran",
              toast: "Berhasil menghapus tahun ajaran",
            });
          } else {
            throw deleting.data.error;
          }
        } else {
          throw errorMessage("menghapus tahun ajaran");
        }
      } catch (error) {
        toast(<Failed>{error}</Failed>);
        setState({
          ...state,
          isLoading: false,
        });
      }
    }
  }

  console.log("state Edit tahun ajaran", state);
  

  return (
    <Private {...props}>
      <Breadcrumb data={state.breadcrumb} />
      <div className="wrapper-form">
        <Grid container justify="space-between">
          <Grid item>
            <div className="header-title" >EDIT TAHUN AJARAN</div>
          </Grid>
          <Grid item>
            <ButtonSecondary onClick={deleteTahunAjaran}  icon="delete" color="#DC6E6E">
              HAPUS
            </ButtonSecondary>
          </Grid>
        </Grid>
      </div>
      <form onSubmit={(e) => submitTahunAjaran(e)}>
        <div className="wrapper">
         {state.isLoading ? <Loading /> :  <div className="container">
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <DateRange
                  label="Periode Tahun Ajaran"
                  name="tahun-ajaran"
                  startDate={state.startDate}
                  endDate={state.endDate}
                  setStartDate={(value) => onChange(value, "startDate")}
                  setEndDate={(value) => onChange(value, "endDate")}
                  dateFormat="MMMM yyyy"
                  showMonthYearPicker
                  required
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <label htmlFor="tahun-ajaran-status" className="input-label">
                  <span style={{ color: "#FF615B" }}>*</span>
                  <span>Status</span>
                </label>
                <RadioGroup
                  aria-label="tahun-ajaran-status"
                  name="tahun-ajaran-status"
                  value={state.status}
                  onChange={(e) => onChange(e.target.value, "status")}
                  style={{ flexDirection: "row" }}
                >
                  <FormControlLabel
                    value="aktif"
                    control={
                      <Radio
                        disableRipple
                        classes={{
                          root: classes.radio,
                          checked: classes.checked,
                        }}
                      />
                    }
                    label="Aktif"
                    style={{ width: "fit-content" }}
                  />
                  <FormControlLabel
                    value="non-aktif"
                    control={
                      <Radio
                        disableRipple
                        classes={{
                          root: classes.radio,
                          checked: classes.checked,
                        }}
                      />
                    }
                    label="Non-Aktif"
                    style={{ width: "fit-content" }}
                  />
                </RadioGroup>
              </Grid>
            </Grid>
            <Grid className="mt-4" container spacing={3} justify="flex-end">
              <Grid item>
                <ButtonSecondary onClick={cancel}>Batalkan</ButtonSecondary>
              </Grid>
              <Grid item>
                {state.loading ? (
                  <ButtonLoading>Memproses...</ButtonLoading>
                ) : (
                  <ButtonPrimary type="submit">
                    Simpan Perubahan
                  </ButtonPrimary>
                )}
              </Grid>
            </Grid>
          </div>}
        </div>
      </form>
      <Toastr />
    </Private>
  );
};

export default EditTahunAjaran;
