import React from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  // PDFViewer,
  Image,
  Font,
} from "@react-pdf/renderer";
import logoInvoice from "../../../../assets/img/logo-invoice.png";
import priceFormat from "../../../../helper/PriceFormat";

Font.register({
  family: "Open Sans",
  fonts: [
    {
      src:
        "https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-regular.ttf",
    },
    {
      src:
        "https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-600.ttf",
      fontWeight: 600,
    },
  ],
});
// Create styles
const styles = StyleSheet.create({
  page: {
    paddingTop: 40,
    paddingBottom: 40,
    paddingHorizontal: 40,
    fontSize: 12,
    fontFamily: "Open Sans",
  },
  sectionTitle: {
    width: "100%",
    textAlign: "center",
    marginBottom: 10,
  },
  sectionHeader: {
    width: "100%",
    textAlign: "left",
    marginBottom: 10,
  },
  sectionInfo: {
    width: "100%",
    textAlign: "left",
    marginBottom: 24,
    marginTop: 54,
  },
  sectionNote: {
    width: "40%",
    textAlign: "left",
    marginBottom: 10,
    marginTop: 32,
  },
  sectionTable: {
    width: "100%",
    textAlign: "left",
    marginBottom: 10,
  },
  flexRow: {
    display: "flex",
    flexDirection: "row",
    marginBottom: 4,
  },
  title: {
    fontSize: 24,
    fontWeight: 600,
    color: "#1E3C67",
    textAlign: "right",
    marginBottom: 8,
  },
  logo: {
    height: 56,
    width: 99,
  },
  smallWidth: {
    width: 64,
  },
  widthXl: {
    width: "60%",
  },
  widthXl2: {
    width: "50%",
  },
  widthSm: {
    width: "40%",
  },
  widthXs: {
    width: "10%",
  },
  widthSm2: {
    width: "30%",
  },
  widthXs2: {
    width: "20%",
  },
  colorSadGrey: {
    color: "#80848D",
  },
  textRight: {
    textAlign: "right",
  },
  textBold: {
    fontWeight: 600,
  },
  tableHeader: {
    backgroundColor: "#1E3C67",
    padding: "4px 16px",
    color: "#fff",
  },
  tableContent: {
    padding: "4px 16px",
  },
  tableFooter: {
    padding: "10px 16px",
    backgroundColor: "#F3F3F3",
    marginTop: 8,
  },
  tableBorder: {
    borderBottom: "1px solid #E1E1E1",
  },
  line: {
    height: 24,
    borderBottom: "1px solid #E1E1E1",
    width: "40%",
  },
});

// Create Document Component
const InvoicePenagihan = (props) => {
  console.log("resi pdf", props);
  return (
    // <PDFViewer>
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.sectionHeader}>
          <View style={styles.flexRow}>
            <View style={styles.widthXl}>
              <Image src={logoInvoice} style={styles.logo} />
            </View>
            <View style={styles.widthSm}>
              <Text style={styles.title}>INVOICE</Text>
              <View style={styles.flexRow}>
                <View style={{ ...styles.widthSm, ...styles.colorSadGrey }}>
                  <Text>Nomor</Text>
                </View>
                <View style={{ ...styles.widthXl, ...styles.textRight }}>
                  <Text>{props.data.referensi}</Text>
                </View>
              </View>
              <View style={styles.flexRow}>
                <View style={{ ...styles.widthSm, ...styles.colorSadGrey }}>
                  <Text>Tanggal</Text>
                </View>
                <View style={{ ...styles.widthXl, ...styles.textRight }}>
                  <Text>{props.data.tanggal}</Text>
                </View>
              </View>
            </View>
          </View>
        </View>

        <View style={styles.sectionInfo}>
          <View style={styles.flexRow}>
            <View style={styles.widthXl}>
              <Text style={styles.textBold}>Everyone Education Centre</Text>
              <Text>Jl. Imam Bonjol No. 152,</Text>
              <Text>Selatpanjang, Kab. Kep. Meranti, Riau 28753</Text>
              <Text>Telp. 022-7204958 - Fax. - </Text>
              <Text>eec1slp@yahoo.com</Text>
            </View>
            <View style={styles.widthSm}>
              <Text style={styles.textBold}>{props.data.nama}</Text>
              {/* <Text>Jl. Imam Bonjol No. xx,</Text>
                <Text>Selatpanjang, Kab. Kep. Meranti, Riau 28753</Text>
                <Text>(telp)</Text> */}
            </View>
          </View>
        </View>

        <View style={styles.sectionTable}>
          <View style={styles.flexRow}>
            <View style={{ ...styles.widthXs, ...styles.tableHeader }}>
              <Text style={styles.textBold}>No</Text>
            </View>
            <View style={{ ...styles.widthXl, ...styles.tableHeader }}>
              <Text style={styles.textBold}>Item</Text>
            </View>
            <View style={{ ...styles.widthSm2, ...styles.tableHeader }}>
              <Text style={styles.textBold}>Subtotal</Text>
            </View>
          </View>
          {props.data.invoice.map((inv, i) => {
            return (
              <View
                key={inv.sekolah}
                style={{ ...styles.flexRow, ...styles.tableBorder }}
              >
                <View style={{ ...styles.widthXs, ...styles.tableContent }}>
                  <Text>{i + 1}</Text>
                </View>
                <View style={{ ...styles.widthXl, ...styles.tableContent }}>
                  <Text style={styles.textBold}>Pendidikan: {inv.sekolah}</Text>
                  {inv.details.map((detail) => (
                    <Text style={{ marginTop: 8 }}>{detail.bulan}</Text>
                  ))}
                </View>
                <View style={{ ...styles.widthSm2, ...styles.tableContent, marginTop: 14 }}>
                  {inv.details.map((detail) => (
                    <Text style={{ marginTop: 8 }}>{detail.sub}</Text>
                  ))}
                </View>
              </View>
            );
          })}

          <View style={styles.flexRow}>
            <View style={{ ...styles.widthXl2, ...styles.tableFooter }}>
              {/* <Text>1</Text> */}
            </View>
            <View style={{ ...styles.widthXs2, ...styles.tableFooter }}>
              <Text style={styles.textBold}>Jumlah</Text>
            </View>
            <View style={{ ...styles.widthSm2, ...styles.tableFooter }}>
              <Text style={styles.textBold}>
                {priceFormat(props.data.total)}
              </Text>
            </View>
          </View>
        </View>

        <View styles={styles.sectionNote}>
          <Text style={{ fontSize: 8, marginTop: 16 }}>KETERANGAN</Text>
          <View style={styles.line} />
          <View style={styles.line} />
          <View style={styles.line} />
        </View>
      </Page>
    </Document>
    // </PDFViewer>
  );
};

export default InvoicePenagihan;
