import React from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import Login from "../pages/public/Login";
import Dashboard from "../pages/private/Dashboard/Dashboard";
import checkAuth from "./Authorization";
import RegistrasiSiswa from "../pages/private/Siswa/Registrasi/RegistrasiSiswa";
import DaftarSiswa from "../pages/private/Siswa/DaftarSiswa/DaftarSiswa";
import DaftarKelas from "../pages/private/Kelas/DaftarKelas";
import KenaikanKelas from "../pages/private/Kelas/KenaikanKelas";
import TahunAjaran from "../pages/private/Pengaturan/TahunAjaran/TahunAjaran";
import TambahTahunAjaran from "../pages/private/Pengaturan/TahunAjaran/TambahTahunAjaran";
import Sekolah from "../pages/private/Pengaturan/Sekolah/Sekolah";
import TambahSekolah from "../pages/private/Pengaturan/Sekolah/TambahSekolah";
import Kursus from "../pages/private/Pengaturan/Kursus/Kursus";
import Kelas from "../pages/private/Pengaturan/Kelas/Kelas";
import TambahKursus from "../pages/private/Pengaturan/Kursus/TambahKursus";
import EditTahunAjaran from "../pages/private/Pengaturan/TahunAjaran/EditTahunAjaran";
import EditSekolah from "../pages/private/Pengaturan/Sekolah/EditSekolah";
import EditKursus from "../pages/private/Pengaturan/Kursus/EditKursus";
import TambahKelas from "../pages/private/Pengaturan/Kelas/TambahKelas";
import EditKelas from "../pages/private/Pengaturan/Kelas/EditKelas";
import UserProvider from "../context/User/UserProvider";
import User from "../pages/private/Pengaturan/User/User";
import TambahUser from "../pages/private/Pengaturan/User/TambahUser";
import EditUser from "../pages/private/Pengaturan/User/EditUser";
import DetailsSiswa from "../pages/private/Siswa/DaftarSiswa/DetailSiswa";
import UpdateSiswa from "../pages/private/Siswa/DaftarSiswa/UpdateSiswa";
import PengeluaranList from "../pages/private/Akunting/Pengeluaran/PengeluaranList";
import TambahPengeluaran from "../pages/private/Akunting/Pengeluaran/TambahPengeluaran";
import EditPengeluaran from  "../pages/private/Akunting/Pengeluaran/EditPengeluaran";
import DaftarPenagihan from "../pages/private/Akunting/Penagihan/DaftarPenagihan";
import InvoicePenagihan from "../pages/private/Akunting/Penagihan/InvoicePenagihan";
import GenerateLaporan from "../pages/private/Akunting/Laporan/GenerateLaporan";
import BuatPembayaran from "../pages/private/Pembayaran/BuatPembayaran/BuatPembayaran";
import DaftarPembayaran from "../pages/private/Pembayaran/DaftarPembayaran/DaftarPembayaran";
import DetailKelas from "../pages/private/Kelas/DetailKelas";
import LaporanPdf from "../pages/private/Akunting/Laporan/LaporanPdf";
import ResiLandscape from "../pages/private/Pembayaran/ResiLandscape";

function PrivateRoute({ children, ...rest }) {
  return (
    <Route
      {...rest}
      render={({ location }) => {
        const data = checkAuth().data;
        if (data) {
          return <UserProvider data={data}>{children}</UserProvider>;
        }
        return (
          <Redirect
            to={{
              pathname: "/",
              state: { from: location },
            }}
          />
        );
      }}
    />
  );
}

const Router = (props) => {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/" component={Login} />
        
        {/* <Route exact path="/invoice" component={InvoicePenagihan} /> */}

        <PrivateRoute path="/dashboard">
          <Dashboard group="dashboard" {...props} />
        </PrivateRoute>
        <PrivateRoute exact path="/siswa/registrasi">
          <RegistrasiSiswa group="siswa" {...props} />
        </PrivateRoute>
        <PrivateRoute exact path="/siswa/daftar">
          <DaftarSiswa group="siswa" {...props} />
        </PrivateRoute>
        <PrivateRoute exact path="/siswa/daftar/:id">
          <DetailsSiswa group="siswa" {...props} />
        </PrivateRoute>
        <PrivateRoute exact path="/siswa/daftar/:id/edit">
          <UpdateSiswa group="siswa" {...props} />
        </PrivateRoute>

        <PrivateRoute exact path="/kelas-list/daftar">
          <DaftarKelas group="kelas-list" {...props} />
        </PrivateRoute>
        <PrivateRoute exact path="/kelas-list/daftar/:id">
          <DetailKelas group="kelas-list" {...props} />
        </PrivateRoute>
        <PrivateRoute path="/kelas-list/kenaikan">
          <KenaikanKelas group="kelas-list" {...props} />
        </PrivateRoute>

        <PrivateRoute path="/pembayaran/baru">
          <BuatPembayaran group="pembayaran" {...props} />
        </PrivateRoute>
        <PrivateRoute path="/pembayaran/daftar">
          <DaftarPembayaran group="pembayaran" {...props} />
        </PrivateRoute>
        <PrivateRoute path="/pembayaran/resi/:resi">
          <ResiLandscape group="pembayaran" {...props} />
        </PrivateRoute>

        <PrivateRoute exact path="/akunting/pengeluaran">
          <PengeluaranList group="pengeluaran" {...props} />
        </PrivateRoute>
        <PrivateRoute exact path="/akunting/pengeluaran/baru">
          <TambahPengeluaran group="pengeluaran" {...props} />
        </PrivateRoute>
        <PrivateRoute exact path="/akunting/pengeluaran/:id/edit">
          <EditPengeluaran group="pengeluaran" {...props} />
        </PrivateRoute>

        <PrivateRoute exact path="/akunting/penagihan">
          <DaftarPenagihan group="penagihan" {...props} />
        </PrivateRoute>
        <PrivateRoute exact path="/akunting/penagihan/invoice">
          <InvoicePenagihan group="penagihan" {...props} />
        </PrivateRoute>

        <PrivateRoute exact path="/akunting/laporan">
          <GenerateLaporan group="laporan" {...props} />
        </PrivateRoute>
        <PrivateRoute exact path="/akunting/laporan/pdf">
          <LaporanPdf group="laporan" {...props} />
        </PrivateRoute>

        <PrivateRoute exact path="/pengaturan/tahun-ajaran">
          <TahunAjaran group="tahun-ajaran" {...props} />
        </PrivateRoute>
        <PrivateRoute path="/pengaturan/tahun-ajaran/baru">
          <TambahTahunAjaran group="tahun-ajaran" {...props} />
        </PrivateRoute>
        <PrivateRoute path="/pengaturan/tahun-ajaran/:id/edit">
          <EditTahunAjaran group="tahun-ajaran" {...props} />
        </PrivateRoute>

        <PrivateRoute exact path="/pengaturan/sekolah">
          <Sekolah group="sekolah" {...props} />
        </PrivateRoute>
        <PrivateRoute path="/pengaturan/sekolah/baru">
          <TambahSekolah group="sekolah" {...props} />
        </PrivateRoute>
        <PrivateRoute path="/pengaturan/sekolah/:id/edit">
          <EditSekolah group="sekolah" {...props} />
        </PrivateRoute>

        <PrivateRoute exact path="/pengaturan/kursus">
          <Kursus group="kursus" {...props} />
        </PrivateRoute>
        <PrivateRoute path="/pengaturan/kursus/baru">
          <TambahKursus group="kursus" {...props} />
        </PrivateRoute>
        <PrivateRoute path="/pengaturan/kursus/:id/edit">
          <EditKursus group="kursus" {...props} />
        </PrivateRoute>

        <PrivateRoute exact path="/pengaturan/kelas">
          <Kelas group="kelas" {...props} />
        </PrivateRoute>
        <PrivateRoute path="/pengaturan/kelas/baru">
          <TambahKelas group="kelas" {...props} />
        </PrivateRoute>
        <PrivateRoute path="/pengaturan/kelas/:id/edit">
          <EditKelas group="kelas" {...props} />
        </PrivateRoute>

        <PrivateRoute exact path="/pengaturan/user">
          <User group="user" {...props} />
        </PrivateRoute>
        <PrivateRoute path="/pengaturan/user/baru">
          <TambahUser group="user" {...props} />
        </PrivateRoute>
        <PrivateRoute path="/pengaturan/user/:id/edit">
          <EditUser group="user" {...props} />
        </PrivateRoute>
      </Switch>
    </BrowserRouter>
  );
};

export default Router;
