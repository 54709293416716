import React, { useState } from "react";
import Private from "../../../../layout/Private";
import Breadcrumb from "../../../../component/Navigation/Breadcrumb";
import { Grid } from "@material-ui/core";
import ButtonSecondary from "../../../../component/Button/ButtonSecondary";
import ButtonPrimary from "../../../../component/Button/ButtonPrimary";
import Axios from "axios";
import { toast } from "react-toastify";
import Failed from "../../../../component/Misc/Failed";
import ButtonLoading from "../../../../component/Button/ButtonLoading";
import { useHistory } from "react-router-dom";
import { cancelMessage, errorMessage } from "../../../../helper/Static";
import TextField from "../../../../component/Field/TextField";
import { createPendidikanURL } from "../../../../api/sekolah";
import Toastr from "../../../../component/Misc/Toastr";

const TambahSekolah = props => {
  const history = useHistory();

  const [state, setState] = useState({
    jumlah_tingkat: "",
    pendidikan: "",
    biaya: "",
    status: "aktif",
    breadcrumb: [
      {
        name: "EEC",
        url: "/dashboard",
        active: false,
      },
      {
        name: "Pengaturan",
        url: "/pengaturan",
        active: false,
      },
      {
        name: "Sekolah",
        url: "/pengaturan/sekolah",
        active: false,
      },
      {
        name: "Tambah Sekolah",
        url: "/pengaturan/sekolah/baru",
        active: true,
      },
    ],
  });

  const onChange = (value, name) => {
    setState({
      ...state,
      [name]: value,
    });
  };

  const cancel = () => {
    if (window.confirm(cancelMessage(`tambah sekolah baru`))) {
      history.push("/pengaturan/sekolah");
    }
  };

  const submitSekolah = async (e) => {
    e.preventDefault();
    setState({
      ...state,
      loading: true,
    });

    try {
      const submit = await Axios.post(createPendidikanURL, {
        nama: state.pendidikan,
        biaya: state.biaya,
        jumlah_tingkat: state.jumlah_tingkat,
      });

      console.log("submit", submit);

      if (submit) {
        if (!submit.data.error) {
          history.push({
            pathname: "/pengaturan/sekolah",
            toast: "Berhasil menambahkan sekolah baru",
          });
          setState({
            ...state,
            loading: false,
          });
        } else {
          throw new Error(submit.data.error);
        }
      } else {
        throw new Error(errorMessage("membuat sekolah"));
      }
    } catch (error) {
      toast(<Failed>{error.message}</Failed>);
      setState({
        ...state,
        loading: false,
      });
    }
  };

  console.log("state sekolah", state);
  return (
    <Private {...props}>
      <Breadcrumb data={state.breadcrumb} />
      <div className="wrapper-form">
        <div className="header-title">TAMBAH SEKOLAH</div>
      </div>
      <form onSubmit={(e) => submitSekolah(e)}>
        <div className="wrapper">
          <div className="container">
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <TextField
                  name="pendidikan"
                  label="Pendidikan"
                  placeholder="Isikan Pendidikan"
                  className="mb-2"
                  value={state.pendidikan}
                  onChange={(e) => onChange(e.target.value, "pendidikan")}
                  required
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  type="number"
                  name="biaya"
                  label="Biaya"
                  placeholder="Isikan Biaya"
                  className="mb-2"
                  value={state.biaya}
                  onChange={(e) => onChange(e.target.value, "biaya")}
                  required
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  type="number"
                  name="jumlah_tingkat"
                  label="Jumlah Tingkat"
                  placeholder="Isikan Jumlah Tingkat"
                  className="mb-2"
                  value={state.jumlah_tingkat}
                  onChange={(e) => onChange(e.target.value, "jumlah_tingkat")}
                  required
                />
              </Grid>
            </Grid>
            <Grid className="mt-4" container spacing={3} justify="flex-end">
              <Grid item>
                <ButtonSecondary onClick={cancel}>Batalkan</ButtonSecondary>
              </Grid>
              <Grid item>
                {state.loading ? (
                  <ButtonLoading>Memproses...</ButtonLoading>
                ) : (
                  <ButtonPrimary type="submit">Tambah Sekolah</ButtonPrimary>
                )}
              </Grid>
            </Grid>
          </div>
        </div>
      </form>
      <Toastr />
    </Private>
  );
};

export default TambahSekolah;
