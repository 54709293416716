/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useCallback } from "react";
import Breadcrumb from "../../../../component/Navigation/Breadcrumb";
import Private from "../../../../layout/Private";
import TablePagination from "../../../../component/Navigation/TablePagination";
import MaterialTable from "material-table";
import { Grid, Tooltip } from "@material-ui/core";
import LinkOutlined from "../../../../component/Button/LinkOutlined";
import { useLocation, Link } from "react-router-dom";
import { toast } from "react-toastify";
import Failed from "../../../../component/Misc/Failed";
import Success from "../../../../component/Misc/Success";
import Toastr from "../../../../component/Misc/Toastr";
import Axios from "axios";
import { errorMessage, deleteMessage } from "../../../../helper/Static";
import { userGetAllURL, deleteUserURL } from "../../../../api/user";
import TextField from "../../../../component/Field/TextField";
import useDebounce from "../../../../helper/useDebounce";

const User = (props) => {
  const location = useLocation();
  const [state, setState] = useState({
    breadcrumb: [
      {
        name: "EEC",
        url: "/dashboard",
        active: false,
      },
      {
        name: "Pengaturan",
        url: "/pengaturan",
        active: false,
      },
      {
        name: "User",
        url: "/pentaturan/user",
        active: true,
      },
    ],
    user: [],
    total: "",
    isLoading: true,
  });

  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const debouncedSearch = useDebounce(search, 500);

  const getAllData = useCallback(async () => {
    setState({
      ...state,
      isLoading: true,
    });

    const urlParam = `${userGetAllURL}?page=${page}${
      search ? `&search=${debouncedSearch}` : ""
    }`;

    try {
      const user = await Axios.get(urlParam);
      if (user) {
        // console.log("tahun ajaran", user);
        setState({
          ...state,
          user: user.data.data,
          total: user.data.total,
          isLoading: false,
        });
      } else {
        const errorText = "Error";
        throw errorText;
      }
    } catch (error) {
      setState({
        ...state,
        isLoading: false,
      });
      toast(<Failed>{error}</Failed>);
    }
  }, [page, debouncedSearch]);

  useEffect(() => {
    getAllData();
  }, [getAllData]);

  useEffect(() => {
    if (location.toast) {
      toast(<Success>{location.toast}</Success>);
    }
  }, []);

  const deleteUser = async (id, text) => {
    if (window.confirm(deleteMessage(`user ${text}`))) {
      try {
        const deleting = await Axios.post(deleteUserURL(id));
        if (deleting) {
          if (!deleting.data.error) {
            toast(<Success>{deleting.data.data}</Success>);
            getAllData();
          } else {
            throw deleting.data.error;
          }
        } else {
          throw errorMessage("menghapus user");
        }
      } catch (error) {
        toast(<Failed>{error}</Failed>);
        setState({
          ...state,
          isLoading: false,
        });
      }
    }
  };

  const onChangeSearch = (e) => {
    setSearch(e.target.value);
    setPage(1);
  };

  console.log("state user", state);

  return (
    <Private {...props}>
      <Breadcrumb data={state.breadcrumb} />
      <div className="wrapper table-wrapper">
        <Grid container className="mb-3" spacing={3} justify="flex-end">
          <Grid item>
            <LinkOutlined address="/pengaturan/user/baru" icon="add">
              TAMBAH USER
            </LinkOutlined>
          </Grid>
          <Grid item xs={12} lg={4}>
            <TextField
              value={search}
              placeholder="Cari User"
              onChange={(e) => onChangeSearch(e)}
            />
          </Grid>
        </Grid>
        <div className="table-title">Daftar User</div>
        <MaterialTable
          columns={[
            {
              title: "Nama User",
              field: "name",
            },
            {
              title: "Username",
              field: "username",
            },
            {
              title: "Role",
              field: "role",
            },
            {
              title: "",
              render: (rowData) => {
                return (
                  <div className="table-action">
                    <Tooltip title="Edit user" arrow>
                      <Link to={`/pengaturan/user/${rowData.id}/edit`}>
                        <i className="material-icons table-edit">edit</i>
                      </Link>
                    </Tooltip>

                    <Tooltip title="Hapus user" arrow>
                      <button
                        onClick={() => deleteUser(rowData.id, rowData.name)}
                      >
                        <i className="material-icons table-delete">delete</i>
                      </button>
                    </Tooltip>
                  </div>
                );
              },
            },

            {
              title: "id",
              field: "id",
              hidden: true,
            },
          ]}
          isLoading={state.isLoading}
          data={state.user}
          options={{
            toolbar: false,
            padding: "dense",
            pageSize: 10,
          }}
          components={{
            Pagination: (props) => {
              console.log("pagination props", props);
              return (
                <td>
                  <TablePagination
                    onChangePage={(e) => setPage(e)}
                    total={state.total}
                    page={page}
                    itemShow={props.rowsPerPage}
                  />
                </td>
              );
            },
          }}
        />
      </div>
      <Toastr />
    </Private>
  );
};

export default User;
