/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useCallback, useContext } from "react";
import Breadcrumb from "../../../../component/Navigation/Breadcrumb";
import Private from "../../../../layout/Private";
import TablePagination from "../../../../component/Navigation/TablePagination";
import MaterialTable from "material-table";
import { Grid, Tooltip } from "@material-ui/core";
import LinkOutlined from "../../../../component/Button/LinkOutlined";
import { useLocation, Link } from "react-router-dom";
import { toast } from "react-toastify";
import TextField from "../../../../component/Field/TextField";
import Failed from "../../../../component/Misc/Failed";
import Success from "../../../../component/Misc/Success";
import Toastr from "../../../../component/Misc/Toastr";
import Axios from "axios";
// import _ from 'lodash';
import { errorMessage, deleteMessage } from "../../../../helper/Static";
import priceFormat from "../../../../helper/PriceFormat";
import useDebounce from "../../../../helper/useDebounce";
import {
  listPengeluaranURL,
  deletePengeluaranURL,
} from "../../../../api/pengeluaran";
import moment from "moment";
import UserContext from "../../../../context/User/UserContext";

const PengeluaranList = (props) => {
  const location = useLocation();
  const user = useContext(UserContext);
  const [state, setState] = useState({
    breadcrumb: [
      {
        name: "EEC",
        url: "/dashboard",
        active: false,
      },
      {
        name: "Akunting",
        url: "/akunting",
        active: false,
      },
      {
        name: "Pengeluaran",
        url: "/akunting/pengeluaran",
        active: true,
      },
    ],
    data: [],
    total: "",
    isLoading: true,
  });

  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const debouncedSearch = useDebounce(search, 500);

  const getAllData = useCallback(async () => {
    setState({
      ...state,
      isLoading: true,
    });

    const urlParam = `${listPengeluaranURL}?page=${page}${
      search ? `&search=${debouncedSearch}` : ""
    }`;

    try {
      const fetch = await Axios.get(urlParam);
      if (fetch) {
        // console.log("tahun ajaran", fetch);
        setState({
          ...state,
          data: fetch.data.data || [],
          total: fetch.data.total,
          isLoading: false,
        });
      } else {
        const errorText = "Error";
        throw errorText;
      }
    } catch (error) {
      setState({
        ...state,
        isLoading: false,
      });
      toast(<Failed>{error}</Failed>);
    }
  }, [page, debouncedSearch]);

  useEffect(() => {
    getAllData();
  }, [getAllData]);

  useEffect(() => {
    if (location.toast) {
      toast(<Success>{location.toast}</Success>);
    }
  }, []);

  const deletePendidikan = async (id, text) => {
    if (window.confirm(deleteMessage(`pengeluaran ${text}`))) {
      try {
        const deleting = await Axios.post(deletePengeluaranURL(id));
        if (deleting) {
          if (!deleting.error) {
            toast(<Success>{deleting.data.data}</Success>);
            getAllData();
          } else {
            throw deleting.data.error;
          }
        } else {
          throw errorMessage("menghapus pengeluaran");
        }
      } catch (error) {
        toast(<Failed>{error}</Failed>);
        setState({
          ...state,
          isLoading: false,
        });
      }
    }
  };

  const onChangeSearch = (e) => {
    setSearch(e.target.value);
    setPage(1);
  };

  // console.log('delayed search', search)

  return (
    <Private {...props}>
      <Breadcrumb data={state.breadcrumb} />
      <div className="wrapper table-wrapper">
        <Grid container className="mb-3" spacing={3} justify="flex-end">
          <Grid item>
            <LinkOutlined address="/akunting/pengeluaran/baru" icon="add">
              TAMBAH PENGELUARAN
            </LinkOutlined>
          </Grid>
          <Grid item xs={12} lg={4}>
            <TextField
              value={search}
              placeholder="Cari Pengeluaran"
              onChange={(e) => onChangeSearch(e)}
            />
          </Grid>
        </Grid>
        <div className="table-title">Daftar Pengeluaran</div>
        <MaterialTable
          columns={[
            {
              title: "Tanggal Pengeluaran",
              field: "tgl_pengeluaran",
              render: (rowData) => (
                <div>
                  {moment(new Date(rowData.tgl_pengeluaran)).format(
                    "DD MMMM YYYY"
                  )}
                </div>
              ),
            },
            {
              title: "Nama Pengeluaran",
              field: "nama_pengeluaran",
            },
            {
              title: "Jumlah Pengeluaran",
              field: "jumlah_pengeluaran",
              render: (rowData) => (
                <div>{priceFormat(rowData.jumlah_pengeluaran)}</div>
              ),
            },
            {
              title: "No. Struk",
              field: "nomor_struk",
            },
            {
              title: "",
              render: (rowData) => {
                return (
                  <div className="table-action">
                    <Tooltip title="Edit pengeluaran" arrow>
                      <Link to={`/akunting/pengeluaran/${rowData.id}/edit`}>
                        <i className="material-icons table-edit">edit</i>
                      </Link>
                    </Tooltip>

                    {user?.user.role !== "Kasir" && (
                      <Tooltip title="Hapus pengeluaran" arrow>
                        <button
                          onClick={() =>
                            deletePendidikan(
                              rowData.id,
                              rowData.nama_pengeluaran
                            )
                          }
                        >
                          <i className="material-icons table-delete">delete</i>
                        </button>
                      </Tooltip>
                    )}
                  </div>
                );
              },
            },
          ]}
          isLoading={state.isLoading}
          data={state.data}
          options={{
            toolbar: false,
            padding: "dense",
            pageSize: 10,
          }}
          components={{
            Pagination: (props) => {
              // console.log("pagination props", props);
              return (
                <td>
                  <TablePagination
                    onChangePage={(e) => setPage(e)}
                    total={state.total}
                    page={page}
                    itemShow={props.rowsPerPage}
                  />
                </td>
              );
            },
          }}
        />
      </div>
      <Toastr />
    </Private>
  );
};

export default PengeluaranList;
