import React from "react";
import DatePicker from "react-datepicker";

const DateRange = (props) => {
  return (
      <>
      {props.label && (
        <label htmlFor={props.name} className="input-label">
          {props.required && <span style={{ color: "#FF615B" }}>*</span>}
          {props.label}
        </label>
      )}
      <div className="date-range">
     <div style={{ width: "50%" }}>
     <DatePicker
        selected={props.startDate}
        onChange={(date) => props.setStartDate(date)}
        selectsStart
        startDate={props.startDate}
        endDate={props.endDate}
        maxDate={props.maxDate}
        popperPlacement="bottom-end"
        dateFormat={props.dateFormat || 'dd MMMM yyyy'}
        showMonthYearPicker={props.showMonthYearPicker || null}

      />
     </div>
      <span className="date-range-separator"> - </span>
      <DatePicker
        selected={props.endDate}
        onChange={(date) => props.setEndDate(date)}
        selectsEnd
        startDate={props.startDate}
        endDate={props.endDate}
        minDate={props.startDate}
        maxDate={props.maxDate}
        popperPlacement="bottom-end"
        dateFormat={props.dateFormat || 'dd MMMM yyyy'}
        showMonthYearPicker={props.showMonthYearPicker || null}
      />
    </div>
      </>
    
  );
};

export default DateRange;
