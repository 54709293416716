import checkEnv from "./index";

// POST
const createKursusURL = `${checkEnv()}/kursus/create`;

const updateKursusURL = (id) => {
    return `${checkEnv()}/kursus/update/${id}`;
  };

const deleteKursusURL = (id) => {
  return `${checkEnv()}/kursus/delete/${id}`;
};

// GET
const listKursusURL = `${checkEnv()}/kursus/get-all`;

const listKursusGroupedURL = `${checkEnv()}/kursus/group-options`;

const findIdKursusURL = (id) => {
  return `${checkEnv()}/kursus/find/${id}`;
};

export {
  listKursusURL,
  createKursusURL,
  updateKursusURL,
  deleteKursusURL,
  findIdKursusURL,
  listKursusGroupedURL,
};
