import React, { useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import Public from "../../layout/Public";
import TextField from "../../component/Field/TextField";
import ButtonPrimary from "../../component/Button/ButtonPrimary";
import ButtonLoading from "../../component/Button/ButtonLoading";
import home from "../../assets/img/home.png";
import logo from "../../assets/img/logo.png";
import axios from "axios";
import { loginURL } from "../../api/authorization";
import { toast } from "react-toastify";
import Toastr from "../../component/Misc/Toastr";
import Failed from "../../component/Misc/Failed";

const Login = props => {
  const [state, setState] = useState({
    username: "",
    password: "",
    loading: false
  });

  const onChange = (e, name) => {
    setState({
      ...state,
      [name]: e
    });
  };

  useEffect(() => {
    const error = localStorage.getItem('error');
    if (error) {
      toast(<Failed>{error}</Failed>)
      localStorage.removeItem('error')
    }
  }, [])

  const userLogin = async e => {
    e.preventDefault();

    setState({
      ...state,
      loading: true,
    })

    try {
      const submit = await axios.post(loginURL, {
        username: state.username,
        password: state.password
      });

      if (submit) {
        if (!submit.data.error) {
          console.log("TOKEN", submit.data.data.token);
          localStorage.setItem("id", submit.data.data.token)
          props.history.push("/dashboard")
          setState({
            ...state,
            loading: false,
          })
        } else {
          throw submit.data.error;
        }
      } else {
        const errorText =
          "Tidak dapat login untuk saat ini. Mohon coba kembali!";
        throw errorText;
      }
    } catch (error) {
      console.log("LOGIN ERROR", error);
      toast(<Failed>{error}</Failed>)
      setState({
        ...state,
        loading: false,
      })
    }
  };

  return (
    <Public {...props}>
      <form onSubmit={e => userLogin(e)}>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={7} className="lg-none">
            <img src={home} alt="home" className="home-image" />
          </Grid>

          <Grid item xs={12} lg={5}>
            <div className="container login-wrapper">
              <img src={logo} alt="logo" className="home-logo" />
              <div className="login-wrapper-form">
                <TextField
                  name="username"
                  label="Username"
                  placeholder="Isikan Username"
                  className="mb-4"
                  value={state.username}
                  onChange={e => onChange(e.target.value, "username")}
                  required
                />
                <TextField
                  name="password"
                  label="Kata Sandi"
                  placeholder="Isikan Kata Sandi"
                  className="mb-4"
                  type="password"
                  value={state.password}
                  onChange={e => onChange(e.target.value, "password")}
                  required
                />
                {state.loading ? (
                  <ButtonLoading>Memuat...</ButtonLoading>
                ) : (
                  <ButtonPrimary type="submit">Masuk</ButtonPrimary>
                )}
              </div>
              <div className="home-copyright">
                <div className="font-weight-bold">
                  Everyone Education Centre
                </div>
                <div>All rights reserved 2020</div>
              </div>
            </div>
          </Grid>
        </Grid>
      </form>
      <Toastr position="top-center" />
    </Public>
  );
};

export default Login;
