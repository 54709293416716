/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import Private from "../../../../layout/Private";
import Breadcrumb from "../../../../component/Navigation/Breadcrumb";
import { Grid } from "@material-ui/core";
import ButtonSecondary from "../../../../component/Button/ButtonSecondary";
import ButtonPrimary from "../../../../component/Button/ButtonPrimary";
import Axios from "axios";
import { toast } from "react-toastify";
import Failed from "../../../../component/Misc/Failed";
import ButtonLoading from "../../../../component/Button/ButtonLoading";
import { useHistory } from "react-router-dom";
import { cancelMessage, errorMessage } from "../../../../helper/Static";
import TextField from "../../../../component/Field/TextField";
import { roleGetAllURL, createUserURL } from "../../../../api/user";
import SingleSelect from "../../../../component/Field/SingleSelect";
import Toastr from "../../../../component/Misc/Toastr";

const TambahUser = props => {
  const history = useHistory();
  const [show, setShow] = useState(false);
  const [state, setState] = useState({
    username: "",
    role: null,
    password: "",
    nama: "",
    roleList: [],
    breadcrumb: [
      {
        name: "EEC",
        url: "/dashboard",
        active: false,
      },
      {
        name: "Pengaturan",
        url: "/pengaturan",
        active: false,
      },
      {
        name: "User",
        url: "/pengaturan/user",
        active: false,
      },
      {
        name: "Tambah User",
        url: "/pengaturan/user/baru",
        active: true,
      },
    ],
  });

  const onChange = (value, name) => {
    setState({
      ...state,
      [name]: value,
    });
  };

  const getListRole = async () => {
    setState({
      ...state,
      isLoading: true,
    });
    try {
      const role = await Axios.get(roleGetAllURL);
      if (role) {
        setState({
          ...state,
          roleList: role.data.data,
          isLoading: false,
        });
      } else {
        const errorText = "Error";
        throw errorText;
      }
    } catch (error) {
      setState({
        ...state,
        isLoading: false,
      });
      toast(<Failed>{error}</Failed>);
    }
  };

  useEffect(() => {
    getListRole();
  }, []);

  const cancel = () => {
    if (window.confirm(cancelMessage(`tambah user baru`))) {
      history.push("/pengaturan/user");
    }
  };

  const submitUser = async (e) => {
    e.preventDefault();
    setState({
      ...state,
      loading: true,
    });

    
    try {
      if(state.nama.length < 5 || state.username < 5) {
        throw new Error('Nama User dan Username minimal 5 karakter')
      }
  
      const submit = await Axios.post(createUserURL, {
        name: state.nama,
        username: state.username,
        role: parseInt(state.role.value),
        password: state.password,
      });

      console.log("submit", submit);

      if (submit) {
        if (!submit.data.error) {
          history.push({
            pathname: "/pengaturan/user",
            toast: "Berhasil menambahkan user baru",
          });
          setState({
            ...state,
            loading: false,
          });
        } else {
          throw new Error(submit.data.error);
        }
      } else {
        throw new Error(errorMessage("membuat user"));
      }
    } catch (error) {
      toast(<Failed>{error.message}</Failed>);
      setState({
        ...state,
        loading: false,
      });
    }
  };

  console.log("state sekolah", state);
  return (
    <Private {...props}>
      <Breadcrumb data={state.breadcrumb} />
      <div className="wrapper-form">
        <div className="header-title">TAMBAH USER</div>
      </div>
      <form onSubmit={(e) => submitUser(e)}>
        <div className="wrapper">
          <div className="container">
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <TextField
                  name="nama-user"
                  label="Nama User"
                  placeholder="Isikan Nama User"
                  className="mb-2"
                  value={state.nama}
                  onChange={(e) => onChange(e.target.value, "nama")}
                  minlength="5"
                  required
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  name="username"
                  label="Username"
                  placeholder="Isikan Username"
                  className="mb-2"
                  value={state.username}
                  onChange={(e) => onChange(e.target.value, "username")}
                  minlength="5"
                  required
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <SingleSelect
                  name="role"
                  label="Role"
                  placeholder="Pilih Role"
                  className="mb-2"
                  value={state.role}
                  onChange={(e) => onChange(e, "role")}
                  options={state.roleList}
                  required
                />
              </Grid>
              <Grid item xs={12} md={6} style={{ position: 'relative' }}>
                <TextField
                  type={show ? 'text' : 'password'}
                  name="password"
                  label="Password"
                  placeholder="Isikan Password"
                  className="mb-2"
                  value={state.password}
                  onChange={(e) => onChange(e.target.value, "password")}
                  required
                />
                <button
                type="button"
                  className="field-password-toggle"
                  onClick={() => setShow(!show)}
                >
                  <i className="material-icons">
                    {show ? "visibility_off" : "visibility"}
                  </i>
                </button>
              </Grid>
            </Grid>
            <Grid className="mt-4" container spacing={3} justify="flex-end">
              <Grid item>
                <ButtonSecondary onClick={cancel}>Batalkan</ButtonSecondary>
              </Grid>
              <Grid item>
                {state.loading ? (
                  <ButtonLoading>Memproses...</ButtonLoading>
                ) : (
                  <ButtonPrimary type="submit">Tambah User</ButtonPrimary>
                )}
              </Grid>
            </Grid>
          </div>
        </div>
      </form>
      <Toastr />
    </Private>
  );
};

export default TambahUser;
