import checkEnv from "./index";

// POST
const createSiswaURL = `${checkEnv()}/siswa/create`;
const cekUlangRegistrasiURL = `${checkEnv()}/siswa/cek-ulang-registrasi`;
const updateSiswaURL = (id) => {
  return `${checkEnv()}/siswa/update/${id}`;
};

const assignKeKelasURL = `${checkEnv()}/siswa/assign-ke-kelas`;
const luluskanURL = `${checkEnv()}/siswa/luluskan`;
const changeStatusURL = (siswa, kelas) => {
  return `${checkEnv()}/siswa/change-status/${siswa}/${kelas}`;
}

// GET
const listDaftarSiswaURL = `${checkEnv()}/siswa/daftar-siswa`;
const listFindByNameURL = `${checkEnv()}/siswa/find-by-name`;
const listDetailSiswaURL = (id) => {
  return `${checkEnv()}/siswa/detail-siswa/${id}`;
};
const listHistoryPembayaran = id => {
  return `${checkEnv()}/pembayaran/history-pembayaran/${id}`
}

const listTagihanPembayaran = id => {
  return `${checkEnv()}/penagihan/history-tagihan/${id}`
}

const findByKelasURL = id => {
  return `${checkEnv()}/siswa/get-by-kelas/${id}`
}

const findByKelasLuluskanURL = id => {
  return `${checkEnv()}/siswa/get-by-kelas-untuk-diluluskan/${id}`
}


export {
  createSiswaURL,
  cekUlangRegistrasiURL,
  updateSiswaURL,
  assignKeKelasURL,
  listDaftarSiswaURL,
  listDetailSiswaURL,
  listFindByNameURL,
  listTagihanPembayaran,
  listHistoryPembayaran,
  findByKelasURL,
  findByKelasLuluskanURL,
  luluskanURL,
  changeStatusURL
};
