import checkEnv from "./index";

// POST
const createPendidikanURL = `${checkEnv()}/pendidikan/create`;

const updatePendidikanURL = (id) => {
    return `${checkEnv()}/pendidikan/update/${id}`;
  };

const deletePendidikanURL = (id) => {
  return `${checkEnv()}/pendidikan/delete/${id}`;
};

// GET
const listPendidikanURL = `${checkEnv()}/pendidikan/get-all`;

const listPendidikanConcatURL = `${checkEnv()}/pendidikan/get-all-concatenated-options`;

const listPendidikanOptionForRegisterURL = `${checkEnv()}/pendidikan/get-options-for-sekolah`;

const listPendidikanOptionForSiswaURL = `${checkEnv()}/pendidikan/get-options-for-daftar-siswa`;

const listMaxTingkatURL = (id) => {
  return `${checkEnv()}/pendidikan/get-max-level/${id}`;
};

const findIdPendidikanURL = (id) => {
  return `${checkEnv()}/pendidikan/find/${id}`;
};

export {
  listPendidikanURL,
  createPendidikanURL,
  updatePendidikanURL,
  deletePendidikanURL,
  findIdPendidikanURL,
  listPendidikanConcatURL,
  listPendidikanOptionForRegisterURL,
  listPendidikanOptionForSiswaURL,
  listMaxTingkatURL
};
