/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from "react";
import logoRound from "../../assets/img/logo-round.svg";
import {supervisorMenu, administratorMenu, kasirMenu} from "./SidebarMenu";
import { useLocation, useHistory } from "react-router-dom";
import UserContext from "../../context/User/UserContext";

const Sidebar = props => {
  const location = useLocation();
  const history = useHistory();
  const user = useContext(UserContext);

  const [sidebar, setSidebar] = useState([]);

  const [state] = useState({
    sidebarOpen: true,
    sidebarData: [],
  });

  const activeOnLoad = data => {
    // console.log('data', data)
    const result = data.map(menu => {
      if (location.pathname.indexOf(menu.url) !== -1) {
        return {
          ...menu,
          selected: true,
          open: true,
          subMenu: menu.subMenu.map((sub, i) => {
            if (location.pathname.indexOf(sub.url) !== -1) {
              return {
                ...sub,
                selected: true
              };
            }
            return sub;
          })
        };
      }
      return menu;
    })

    return result
  }

  useEffect(() => {
    // console.log('user role', activeOnLoad(kasirMenu))
    if(user.user.role === 'Administrator'){
      setSidebar(activeOnLoad(administratorMenu))
    }

    if(user.user.role === 'Supervisor'){
      setSidebar(activeOnLoad(supervisorMenu))
    }

    if(user.user.role === 'Kasir'){
    // console.log('user kasir', user.user.role)
      setSidebar(activeOnLoad(kasirMenu))
    }
  }, [user.user])

  useEffect(() => {
    props.sidebar(state.sidebarOpen);
  }, []);

 

//   const toggleSidebar = () => {
//     setState({
//       ...state,
//       sidebarOpen: !state.sidebarOpen
//     });
//   };

  const setActive = value => {
    // console.log("main active");

    setSidebar(sidebar.map(menu => {
      if (value.name === menu.name) {
        return {
          ...menu,
          selected: true,
          open: !menu.open,
         
        };
      }
      return {
        ...menu,
        selected: false,
        open: false,
      }
    }));

 
    if (!value.subMenu.length) {
      history.push(value.url);
    } 
    
  };

  const setSubMenuActive = (name, subMenu) => {
    // console.log("sub", name, subMenu);
    setSidebar(
      sidebar.map(menu => {
        if (name === menu.name) {
          return {
            ...menu,
            selected: true,
            open: true,
            subMenu: menu.subMenu.map((sub, i) => {
              if (sub.name === subMenu.name) {
                return {
                  ...sub,
                  selected: true
                };
              }
              return sub;
            })
          };
        }
        return menu;
      })
    );
    history.push(subMenu.url);
  };

  //   const toggleOpen = value => {
  //       console.log("toggle", value)
  //       setState({
  //           ...state,
  //           sidebarData: sidebar.map(menu => {
  //             if(menu.name === value.name) {
  //               console.log("menu", menu)
  //                 return {
  //                     ...menu,
  //                     open: false,
  //                 }
  //             }
  //             return menu
  //           })
  //       })
  //   }

  // console.log("state sidebar", sidebar);
  // console.log("state sidebar new", sidebar);

  return (
    <div className={`sidebar ${props.status.sidebar}`}>
      <div className="sidebar-logo">
        <img src={logoRound} alt="logo" />
        <span>Everyone Education Centre</span>
        {/* <button onClick={toggleSidebar}>
          <i className="material-icons">menu</i>
        </button> */}
      </div>
      {sidebar.map((menu, i) => {
        return (
          <div key={menu.name}>
            <div
              
              className={`sidebar-group ${menu.selected ? "selected" : ""}`}
            >
              <button className="sidebar-menu" onClick={() => setActive(menu)}>
                <i className="material-icons sidebar-icon">{menu.icon}</i>
                <div className="sidebar-name">
                  <span>{menu.name}</span>
                  {menu.subMenu.length ? (
                    <i className="material-icons">arrow_drop_down</i>
                  ) : null}
                </div>
              </button>
            </div>

            {menu.subMenu.map(sub => {
              return (
                <button
                  key={sub.name}
                  className={`sidebar-menu sidebar-sub-menu ${
                    menu.selected && menu.open ? "show" : ""
                  }`}
                  onClick={() => setSubMenuActive(menu.name, sub)}
                >
                  <div
                    className={`sidebar-name ${
                      sub.selected ? "sub-active" : ""
                    }`}
                  >
                    <span>{sub.name}</span>
                  </div>
                </button>
              );
            })}
          </div>
        );
      })}
    </div>
  );
};

export default Sidebar;
