import React from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  //   PDFViewer,
} from "@react-pdf/renderer";
import moment from "moment";

// Create styles
const styles = StyleSheet.create({
  page: {
    paddingTop: 16,
    paddingBottom: 16,
    paddingHorizontal: 16,
    fontSize: 12,
  },
  sectionTitle: {
    width: "100%",
    textAlign: "center",
    marginBottom: 10,
  },
  sectionContent: {
    width: "100%",
    textAlign: "left",
    marginBottom: 10,
  },
  flexRow: {
    display: "flex",
    flexDirection: "row",
    marginBottom: 4,
  },
  flexRowPay: {
    display: "flex",
    flexDirection: "row",
    marginBottom: 8,
  },
  smallWidth: {
    width: 72,
  },
  widthXl: {
    width: "60%",
  },
  widthSm: {
    width: "40%",
    textAlign: "right",
  },
  borderDash: {
    height: 10,
    borderBottom: "1px solid #000",
    marginBottom: 16,
  },
});

// Create Document Component
const ResiPembayaran = (props) => {
  console.log('resi pdf', props)
  return (
    <Document>
      <Page size="A5" orientation="landscape" style={styles.page}>
        <View style={styles.sectionTitle}>
          <Text>Everyone Education Center</Text>
          <Text>Jl. Imam Bonjol, No.152, Selatpanjang, Riau</Text>
          <Text>Telp. 022-7204958 - Fax. -</Text>
        </View>
        <View style={styles.borderDash} />
        <View style={styles.sectionContent}>
          <View style={styles.flexRow}>
            <Text style={styles.smallWidth}>No#</Text>
            <Text>: {props.data.no}</Text>
          </View>
          <View style={styles.flexRow}>
            <Text style={styles.smallWidth}>Nama Siswa</Text>
            <Text>: {props.data.nama_siswa}</Text>
          </View>
          <View style={styles.flexRow}>
            <Text style={styles.smallWidth}>Kasir</Text>
            <Text>: {props.data.kasir}</Text>
          </View>
          <View style={styles.flexRow}>
            <Text style={styles.smallWidth}>Tanggal</Text>
            <Text>
              : {moment(new Date(props.data.tanggal)).format("DD-MM-YYYY hh:mm")}
            </Text>
          </View>
        </View>
        <View style={styles.borderDash} />
        <View style={styles.sectionContent}>
          {props.data.detail.map((content, i) => {
            return (
              <View key={i} style={styles.flexRowPay}>
                <View style={styles.widthXl}>
                  <Text>{content.detail}</Text>
                  <Text style={{ marginLeft: 10 }}>{content.range}</Text>
                </View>
                <View style={styles.widthSm}>
                  <Text>{content.total}</Text>
                </View>
              </View>
            );
          })}
        </View>
        <View style={styles.borderDash} />
        <View style={styles.sectionContent}>
          <View style={styles.flexRowPay}>
            <View style={styles.widthXl}>
              <Text>Subtotal -- {props.data.detail.length} item</Text>
            </View>
            <View style={styles.widthSm}>
              <Text>{props.data.subtotal}</Text>
            </View>
          </View>
          <View style={styles.flexRowPay}>
            <View style={styles.widthXl}>
              <Text>Diskon</Text>
            </View>
            <View style={styles.widthSm}>
              <Text>{props.data.diskon}</Text>
            </View>
          </View>
          <View style={styles.flexRowPay}>
            <View style={styles.widthXl}>
              <Text>Total</Text>
            </View>
            <View style={styles.widthSm}>
              <Text>{props.data.total}</Text>
            </View>
          </View>
        </View>
  
        <View style={styles.sectionTitle}>
          <Text>TERIMA KASIH</Text>
        </View>
      </Page>
    </Document>
  )
}

export default ResiPembayaran;
