/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import TextField from "../../../../component/Field/TextField";
import {
  Grid,
  RadioGroup,
  FormControlLabel,
  Radio,
  makeStyles,
} from "@material-ui/core";
import SelectDate from "../../../../component/Field/SelectDate";
import ButtonPrimary from "../../../../component/Button/ButtonPrimary";
import RegistrasiSiswaContext from "../../../../context/RegisterSiswa/RegisterSiswaContext";
import DropdownSiswa from "../../Pembayaran/BuatPembayaran/DropdownSiswa";
import { toast } from "react-toastify";
import Failed from "../../../../component/Misc/Failed";
import useDebounce from "../../../../helper/useDebounce";
import Axios from "axios";
import { listFindByNameURL } from "../../../../api/siswa";

const useStyles = makeStyles({
  radio: {
    "&$checked": {
      color: "#2a2a2a",

      "&:hover": {
        backgroundColor: `rgba(0,0,0, 0.04)`,
      },
    },
    "&:hover": {
      backgroundColor: `rgba(0,0,0, 0.04)`,
    },
  },
  checked: {},
});

const DataDiriSiswa = (props) => {
  const classes = useStyles();
  const siswaContext = useContext(RegistrasiSiswaContext);
  const [allSiswa, setAllSiswa] = useState({
    loading: false,
    data: [],
    total: 0,
  });
  const [selected, setSelected] = useState(null);
  const [search, setSearch] = useState("");
  const [type, setType] = useState("new");

  const debouncedSearch = useDebounce(search, 500);

  //   console.log("data diri props", props);

  const submitDataDiri = (e) => {
    e.preventDefault();
    console.log("submittt");
    props.setActivePanel(1);
  };

  const getAllData = useCallback(
    async (filter) => {
      console.log("filterrrrrr", filter);
      setAllSiswa({
        ...allSiswa,
        isLoading: true,
      });

      const checkSearch = debouncedSearch && { name: debouncedSearch };

      try {
        const siswa = await Axios.post(listFindByNameURL, {
          ...checkSearch,
        });
        if (!siswa.data.error) {
          const manipluatedData = await Promise.all(
            siswa.data.data.map((data) => {
              return {
                nama_siswa: data.nama_siswa,
                jenis_kelamin: data.jenis_kelamin,
                tempat_lahir: data.tempat_lahir,
                tanggal_lahir: new Date(data.tanggal_lahir),
                nama_ortu: data.nama_ortu,
                alamat: data.alamat,
                nis: data.nis,
                label: `${data.nama_siswa} - ${data.nis}`,
                value: data.id,
              };
            })
          );
          console.log("daftar siswa", siswa);
          setAllSiswa({
            ...allSiswa,
            data: manipluatedData,
            total: siswa.data.total,
            isLoading: false,
          });
        } else {
          throw new Error(siswa.data.error);
        }
      } catch (error) {
        setAllSiswa({
          ...allSiswa,
          isLoading: false,
        });
        toast(<Failed>{error.message}</Failed>);
      }
    },
    [debouncedSearch]
  );

  useEffect(() => {
    if (type === "old") {
      getAllData();
    }
  }, [getAllData, type]);

  const onChangeSiswa = (value) => {
    setSelected(value);
    siswaContext.onChangeArray("dataSiswa", value);
  };

  const onChangeSearch = (e) => {
    setSearch(e);
  };

  console.log("selected", selected);

  return (
    <div className="container pt-4 pb-4">
      <form onSubmit={(e) => submitDataDiri(e)}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <label htmlFor="tahun-ajaran-status" className="input-label">
              <span style={{ color: "#FF615B" }}>*</span>
              <span>Formulir</span>
            </label>
            <RadioGroup
              aria-label="tahun-ajaran-status"
              name="tahun-ajaran-status"
              value={type}
              onChange={(e) => {
                setType(e.target.value)
                setSelected(null)
                siswaContext.onChangeArray('dataSiswa', {
                  nama_siswa: "",
                  jenis_kelamin: "lk",
                  tempat_lahir: "",
                  tanggal_lahir: null,
                  nama_ortu: "",
                  alamat: "",
                })
              }}
              style={{ flexDirection: "row" }}
            >
              <FormControlLabel
                value="new"
                control={
                  <Radio
                    disableRipple
                    classes={{
                      root: classes.radio,
                      checked: classes.checked,
                    }}
                  />
                }
                label="Siswa Baru"
                style={{ width: "fit-content" }}
              />
              <FormControlLabel
                value="old"
                control={
                  <Radio
                    disableRipple
                    classes={{
                      root: classes.radio,
                      checked: classes.checked,
                    }}
                  />
                }
                label="Siswa Pernah Terdaftar"
                style={{ width: "fit-content" }}
              />
            </RadioGroup>
          </Grid>
        </Grid>
        <div className="separator-border pt-4 mb-4"></div>
        {type === "old" && (
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} className="mb-4">
              <DropdownSiswa
                name="Pembayaran Oleh"
                label="Nama Siswa/NIS"
                placeholder="Cari nama siswa atau NIS"
                className="mb-2"
                value={selected}
                onChange={(e) => onChangeSiswa(e)}
                options={allSiswa.data}
                onChangeInputValue={(value) => onChangeSearch(value)}
                isLoading={allSiswa.isLoading}
                required
              />
            </Grid>
          </Grid>
        )}
        {(type === "new" ||
          (type === "old" && selected )) && (
            <Fragment>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <TextField
                    name="nama_siswa"
                    label="Nama Lengkap"
                    placeholder="Isikan Nama Lengkap"
                    className="mb-2"
                    value={siswaContext.dataSiswa.nama_siswa}
                    onChange={(e) =>
                      siswaContext.onChangeObject(
                        "dataSiswa",
                        "nama_siswa",
                        e.target.value
                      )
                    }
                    required
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <label htmlFor="tahun-ajaran-status" className="input-label">
                    <span style={{ color: "#FF615B" }}>*</span>
                    <span>Jenis Kelamin</span>
                  </label>
                  <RadioGroup
                    aria-label="tahun-ajaran-status"
                    name="tahun-ajaran-status"
                    value={siswaContext.dataSiswa.jenis_kelamin}
                    onChange={(e) =>
                      siswaContext.onChangeObject(
                        "dataSiswa",
                        "jenis_kelamin",
                        e.target.value
                      )
                    }
                    style={{ flexDirection: "row" }}
                  >
                    <FormControlLabel
                      value="lk"
                      control={
                        <Radio
                          disableRipple
                          classes={{
                            root: classes.radio,
                            checked: classes.checked,
                          }}
                        />
                      }
                      label="Laki-laki"
                      style={{ width: "fit-content" }}
                    />
                    <FormControlLabel
                      value="pr"
                      control={
                        <Radio
                          disableRipple
                          classes={{
                            root: classes.radio,
                            checked: classes.checked,
                          }}
                        />
                      }
                      label="Perempuan"
                      style={{ width: "fit-content" }}
                    />
                  </RadioGroup>
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    name="tempat_lahir"
                    label="Tempat Lahir"
                    placeholder="Contoh: Bandung"
                    className="mb-2"
                    value={siswaContext.dataSiswa.tempat_lahir}
                    onChange={(e) =>
                      siswaContext.onChangeObject(
                        "dataSiswa",
                        "tempat_lahir",
                        e.target.value
                      )
                    }
                    required
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <SelectDate
                    label="Tanggal Lahir"
                    className="mb-2"
                    value={siswaContext.dataSiswa.tanggal_lahir}
                    onChange={(e) =>
                      siswaContext.onChangeObject(
                        "dataSiswa",
                        "tanggal_lahir",
                        e
                      )
                    }
                    placeholder="Pilih Tanggal Lahir"
                    showYearDropdown
                    required
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    name="nama_ortu"
                    label="Nama Orang Tua"
                    placeholder="Isikan Nama Orang Tua"
                    className="mb-2"
                    value={siswaContext.dataSiswa.nama_ortu}
                    onChange={(e) =>
                      siswaContext.onChangeObject(
                        "dataSiswa",
                        "nama_ortu",
                        e.target.value
                      )
                    }
                    required
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    name="alamat"
                    label="Alamat"
                    placeholder="Isikan Alamat"
                    className="mb-2"
                    value={siswaContext.dataSiswa.alamat}
                    onChange={(e) =>
                      siswaContext.onChangeObject(
                        "dataSiswa",
                        "alamat",
                        e.target.value
                      )
                    }
                    required
                  />
                </Grid>
              </Grid>

              <Grid className="mt-4" container spacing={3} justify="flex-end">
                <Grid item>
                  <ButtonPrimary type="submit">Selanjutnya</ButtonPrimary>
                </Grid>
              </Grid>
            </Fragment>
          )}
      </form>
    </div>
  );
};

export default DataDiriSiswa;
