import React from "react";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import id from "date-fns/locale/id";

function MaterialDateRange(props) {

  return (
   <div>
    {props.label && (
        <label htmlFor={props.name} className="input-label">
          {props.required && <span style={{ color: "#FF615B" }}>*</span>}
          {props.label}
        </label>
      )}
    <div className="date-range material">
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={id}>
      <DatePicker
        variant="inline"
        placeholder={props.startPlaceholder}
        value={props.startDate}
        onChange={props.setStartDate}
        format="dd MMMM yyyy"
        disabled={props.disabled}
      />
       <span className="date-range-separator"> - </span>
      <DatePicker
        placeholder={props.endPlaceholder}
        variant="inline"
        value={props.endDate}
        onChange={props.setEndDate}
        format="dd MMMM yyyy"
        minDate={props.startDate}
        disabled={props.disabled}
      />
    </MuiPickersUtilsProvider>
    </div>
   </div>
  );
}

export default MaterialDateRange;
