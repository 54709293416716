import React from "react";

const Failed = props => {
  return (
    <div className="toastr toastr-failed">
      <i className="material-icons">warning</i>
      <span>{props.children}</span>
    </div>
  );
};

export default Failed;
