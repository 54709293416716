import React from "react";
import Select from "react-select";
const defaultStyles = {
  container: (styles) => ({
    ...styles,
    height: "100%",
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: isDisabled
        ? null
        : isSelected
        ? null
        : isFocused
        ? "#f5f5f5"
        : null,
      color: isDisabled
        ? "black"
        : isSelected
        ? "black"
        : isFocused
        ? "black"
        : "black",
    };
  },
  menu: (styles) => ({
    ...styles,
    border: 0,
    boxShadow: "2px 2px 4px 0 #E6E6E6",
    zIndex: 11,
  }),
  input: (styles) => {
    return {
      ...styles,
      height: "100%",
    };
  },
  control: (styles) => ({
    ...styles,
    minHeight: 12,
    height: "100%",
    backgroundColor: "white",
    border: "1px solid #d1d1d1",
    boxShadow: "none",
    borderRadius: 3,
    "&:hover": { borderColor: "#d1d1d1", border: "1px solid #d1d1d1" },
  }),
  indicatorSeparator: () => ({
    display: "none",
  }),
};

export default (props) => (
  <div className="single-select">
    {props.label && (
      <label htmlFor={props.name} className="input-label">
        {props.required && <span style={{ color: "#FF615B" }}>*</span>}
        {props.label}
      </label>
    )}
    <div style={{ ...props.style, height: props.style?.height || 42 }}>
      <Select
        height={props.height || 42}
        value={props.value}
        options={props.options}
        placeholder={props.placeholder}
        onChange={(newValue) => props.onChange(newValue)}
        styles={{ ...defaultStyles }}
        noOptionsMessage={() => props.noOptionsMessage || 'Tidak ada data'}
        isClearable={props.isClearable || null}
        isLoading={props.isLoading || null}
        onInputChange={value => props.onChangeInputValue(value)}
      />
    </div>
  </div>
);
