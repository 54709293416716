/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import Private from "../../../../layout/Private";
import Breadcrumb from "../../../../component/Navigation/Breadcrumb";
import { Grid } from "@material-ui/core";
import ButtonSecondary from "../../../../component/Button/ButtonSecondary";
import ButtonPrimary from "../../../../component/Button/ButtonPrimary";
import Axios from "axios";
import { toast } from "react-toastify";
import Failed from "../../../../component/Misc/Failed";
import ButtonLoading from "../../../../component/Button/ButtonLoading";
import { useHistory, useRouteMatch } from "react-router-dom";
import {
  cancelMessage,
  errorMessage,
  deleteMessage,
} from "../../../../helper/Static";
import TextField from "../../../../component/Field/TextField";
import SingleSelect from "../../../../component/Field/SingleSelect";
import Loading from "../../../../component/Misc/Loading";
import { userFindByIdURL, roleGetAllURL, deleteUserURL, updateUserURL } from "../../../../api/user";
import ToggleField from "../../../../component/Field/ToggleField";
import Toastr from "../../../../component/Misc/Toastr";

const EditUser = (props) => {
  const match = useRouteMatch();
  const history = useHistory();

  const [list, setList] = useState({
    roleList: [],
    isLoading: true,
  });

  const [show, setShow] = useState(false);

  const [state, setState] = useState({
    username: "",
    role: null,
    password: "",
    nama: "",
    isReset: false,
    breadcrumb: [
      {
        name: "EEC",
        url: "/dashboard",
        active: false,
      },
      {
        name: "Pengaturan",
        url: "/pengaturan",
        active: false,
      },
      {
        name: "User",
        url: "/pengaturan/user",
        active: false,
      },
      {
        name: "Edit User",
        url: "/pengaturan/user/edit",
        active: true,
      },
    ],
  });

  const onChange = (value, name) => {
    setState({
      ...state,
      [name]: value,
    });
  };

  const getCurrentData = async () => {
    console.log("current Data");
    setState({
      ...state,
      dataLoading: true,
    });
    try {
      const user = await Axios.get(userFindByIdURL(match.params.id));
      if (!user.data.error) {
        setState({
          ...state,
          nama: user.data.data.name,
          username: user.data.data.username,
          role: {
            label: user.data.data.role,
            value: user.data.data.id_role,
          },
          dataLoading: false,
        });
      } else {
        const errorText = "Error";
        throw errorText;
      }
    } catch (error) {
      setState({
        ...state,
        dataLoading: false,
      });
      toast(<Failed>{error}</Failed>);
    }
  };

  const getListRole = async () => {
    setList({
      ...list,
      isLoading: true,
    });
    try {
      const role = await Axios.get(roleGetAllURL);
      if (role) {
        setList({
          ...list,
          roleList: role.data.data,
          isLoading: false,
        });
      } else {
        const errorText = "Error";
        throw errorText;
      }
    } catch (error) {
      setState({
        ...state,
        isLoading: false,
      });
      toast(<Failed>{error}</Failed>);
    }
  };

  useEffect(() => {
    getListRole();
    getCurrentData();
  }, []);

  const cancel = () => {
    if (window.confirm(cancelMessage(`edit user`))) {
      history.push("/pengaturan/user");
    }
  };

  const submitKursus = async (e) => {
    e.preventDefault();
    setState({
      ...state,
      loading: true,
    });

    try {
      const submit = await Axios.post(updateUserURL(match.params.id), {
        name: state.nama,
        username: state.username,
        role: parseInt(state.role.value),
        password: state.password,
      });

      console.log("submit", submit);

      if (submit) {
        if (!submit.data.error) {
          history.push({
            pathname: "/pengaturan/user",
            toast: "Berhasil mengedit user",
          });
          setState({
            ...state,
            loading: false,
          });
        } else {
          throw new Error(submit.data.error);
        }
      } else {
        throw new Error(errorMessage("mengedit user"));
      }
    } catch (error) {
      toast(<Failed>{error.message}</Failed>);
      setState({
        ...state,
        loading: false,
      });
    }
  };

  const deleteData = async () => {
    if (window.confirm(deleteMessage(`kursus ${state.nama}`))) {
      try {
        const deleting = await Axios.post(deleteUserURL(match.params.id));
        if (deleting.data) {
          if (!deleting.data.error) {
            history.push({
              pathname: "/pengaturan/user",
              toast: "Berhasil menghapus user",
            });
          } else {
            throw deleting.data.error;
          }
        } else {
          throw errorMessage("menghapus user");
        }
      } catch (error) {
        toast(<Failed>{error}</Failed>);
        setState({
          ...state,
          isLoading: false,
        });
      }
    }
  };

  console.log("state edit user", state);
  console.log("list edit user", list);

  return (
    <Private {...props}>
      <Breadcrumb data={state.breadcrumb} />
      <div className="wrapper-form">
        <Grid container justify="space-between">
          <Grid item>
            <div className="header-title">EDIT USER</div>
          </Grid>
          <Grid item>
            <ButtonSecondary onClick={deleteData} icon="delete" color="#DC6E6E">
              HAPUS
            </ButtonSecondary>
          </Grid>
        </Grid>
      </div>
      <form onSubmit={(e) => submitKursus(e)}>
        <div className="wrapper">
          {state.dataLoading || state.isLoading ? (
            <Loading />
          ) : (
            <div className="container">
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <TextField
                  name="nama-user"
                  label="Nama User"
                  placeholder="Isikan Nama User"
                  className="mb-2"
                  value={state.nama}
                  onChange={(e) => onChange(e.target.value, "nama")}
                  required
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  name="username"
                  label="Username"
                  placeholder="Isikan Username"
                  className="mb-2"
                  value={state.username}
                  onChange={(e) => onChange(e.target.value, "username")}
                  required
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <SingleSelect
                  name="role"
                  label="Role"
                  placeholder="Pilih Role"
                  className="mb-2"
                  value={state.role}
                  onChange={(e) => onChange(e, "role")}
                  options={list.roleList}
                  required
                />
              </Grid>
              <Grid item xs={12} md={6} style={{ position: 'relative' }}>
                <ToggleField
                  type={show ? 'text' : 'password'}
                  name="password"
                  label="Password"
                  placeholder="Isikan Password"
                  className="mb-2"
                  value={state.password}
                  onChange={(e) => onChange(e.target.value, "password")}
                  required
                  toggleText= "Reset Password"
                  checkToggle={(value) =>  setState({...state, isReset: value })}
                />
                {state.isReset && <button
                type="button"
                  className="field-password-toggle"
                  onClick={() => setShow(!show)}
                >
                  <i className="material-icons">
                    {show ? "visibility_off" : "visibility"}
                  </i>
                </button>}
              </Grid>
            </Grid>
            <Grid className="mt-4" container spacing={3} justify="flex-end">
              <Grid item>
                <ButtonSecondary onClick={cancel}>Batalkan</ButtonSecondary>
              </Grid>
              <Grid item>
                {state.loading ? (
                  <ButtonLoading>Memproses...</ButtonLoading>
                ) : (
                  <ButtonPrimary type="submit">Simpan Perubahan</ButtonPrimary>
                )}
              </Grid>
            </Grid>
          </div>
          )}
        </div>
      </form>
      <Toastr />
    </Private>
  );
};

export default EditUser;
