import React, { PureComponent } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
class CustomizedAxisTick extends PureComponent {
  render() {
    const { x, y, payload } = this.props;

    return (
      <g transform={`translate(${x},${y})`}>
        <text
          x={0}
          y={0}
          dy={16}
          textAnchor="end"
          fill="#666"
          style={{ fontSize: 12, transform: "translateY(-15px)" }}
        >
          {parseInt(payload.value)
            .toFixed()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
        </text>
      </g>
    );
  }
}

export default class DashboardChart extends PureComponent {
  render() {
    return (
      <ResponsiveContainer height={375}>
        <LineChart
          data={this.props.data}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid vertical={false} />
          <XAxis
            padding={{ left: 30, right: 30 }}
            dataKey="name"
            axisLine={false}
          />
          <YAxis
            axisLine={false}
            tickLine={false}
            tick={<CustomizedAxisTick />}
          />
          <Tooltip />
          <Legend iconType="circle" />
          <Line strokeWidth={2} dataKey="Pendapatan" stroke="#FC7D58" />
          <Line strokeWidth={2} dataKey="Pengeluaran" stroke="#38C976" />
        </LineChart>
      </ResponsiveContainer>
    );
  }
}
