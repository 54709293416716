const kursusInitial = []

  const panelInitial = [
    {
      title: "Data Siswa Baru",
      active: true,
      disabled: false,
      body: "siswa",
      filled: false,
    },
    {
      title: "Paket Pendidikan",
      active: false,
      disabled: true,
      body: "pendidikan",
      filled: false,
    },
    {
      title: "Cek Ulang Registrasi",
      active: false,
      disabled: true,
      body: "cek",
      filled: false,
    },
  ]

  const dataDiriInitial = {
    nama_siswa: "",
    jenis_kelamin: "lk",
    tempat_lahir: "",
    tanggal_lahir: null,
    nama_ortu: "",
    alamat: "",
  }

  const sekolahInitial = {
    page: 1,
    data: [],
    total: "",
    sekolahLoading: true,
    search: "",
    selected: null,
  }

  const ajaranInitial = {
    page: 1,
    data: [],
    total: "",
    ajaranLoading: true,
    search: "",
    selected: null,
  }

  const kelasInitial = {
    page: 1,
    data: [],
    total: "",
    kelasLoading: true,
    search: "",
    selected: null,
    noOptionMessage:
      "Pilih Jenjang Pendidikan dan Tahun Ajaran terlebih dahulu!",
  }

  export { panelInitial, dataDiriInitial, kursusInitial, sekolahInitial, ajaranInitial, kelasInitial  }