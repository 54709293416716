/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import Private from "../../../../layout/Private";
import Breadcrumb from "../../../../component/Navigation/Breadcrumb";
import { Grid } from "@material-ui/core";
import ButtonSecondary from "../../../../component/Button/ButtonSecondary";
import ButtonPrimary from "../../../../component/Button/ButtonPrimary";
import Axios from "axios";
import { toast } from "react-toastify";
import Failed from "../../../../component/Misc/Failed";
import ButtonLoading from "../../../../component/Button/ButtonLoading";
import { useHistory, useRouteMatch } from "react-router-dom";
import {
  cancelMessage,
  errorMessage,
  deleteMessage,
} from "../../../../helper/Static";
import TextField from "../../../../component/Field/TextField";
import {
  deletePendidikanURL,
  findIdPendidikanURL,
  updatePendidikanURL,
} from "../../../../api/sekolah";
import Loading from "../../../../component/Misc/Loading";
import Toastr from "../../../../component/Misc/Toastr";

const EditSekolah = (props) => {
  const match = useRouteMatch();
  const history = useHistory();

  const [state, setState] = useState({
    jumlah_tingkat: "",
    pendidikan: "",
    biaya: "",
    breadcrumb: [
      {
        name: "EEC",
        url: "/dashboard",
        active: false,
      },
      {
        name: "Pengaturan",
        url: "/pengaturan",
        active: false,
      },
      {
        name: "Sekolah",
        url: "/pengaturan/sekolah",
        active: false,
      },
      {
        name: "Edit Sekolah",
        url: "/pengaturan/sekolah/edit",
        active: true,
      },
    ],
  });

  const getCurrentData = async () => {
    setState({
      ...state,
      isLoading: true,
    });
    try {
      const sekolah = await Axios.get(findIdPendidikanURL(match.params.id));
      if (!sekolah.data.error) {
        setState({
          ...state,
          pendidikan: sekolah.data.data.nama,
          biaya: sekolah.data.data.biaya,
          jumlah_tingkat: sekolah.data.data.jumlah_tingkat,
          isLoading: false,
        });
      } else {
        const errorText = "Error";
        throw errorText;
      }
    } catch (error) {
      setState({
        ...state,
        isLoading: false,
      });
      toast(<Failed>{error}</Failed>);
    }
  };

  useEffect(() => {
    getCurrentData();
  }, []);

  const onChange = (value, name) => {
    setState({
      ...state,
      [name]: value,
    });
  };

  const cancel = () => {
    if (window.confirm(cancelMessage(`edit sekolah`))) {
      history.push("/pengaturan/sekolah");
    }
  };

  const submitSekolah = async (e) => {
    e.preventDefault();
    setState({
      ...state,
      loading: true,
    });

    try {
      const submit = await Axios.post(updatePendidikanURL(match.params.id), {
        nama: state.pendidikan,
        biaya: state.biaya,
        jumlah_tingkat: state.jumlah_tingkat,
      });

      console.log("submit", submit);

      if (submit) {
        if (!submit.data.error) {
          history.push({
            pathname: "/pengaturan/sekolah",
            toast: "Berhasil mengedit sekolah",
          });
          setState({
            ...state,
            loading: false,
          });
        } else {
          throw new Error(submit.data.error);
        }
      } else {
        throw new Error(errorMessage("mengedit sekolah"));
      }
    } catch (error) {
      toast(<Failed>{error.message}</Failed>);
      setState({
        ...state,
        loading: false,
      });
    }
  };

  const deleteSekolah = async () => {
    if (window.confirm(deleteMessage(`tahun ajaran ${state.pendidikan}`))) {
      try {
        const deleting = await Axios.post(deletePendidikanURL(match.params.id));
        if (deleting.data) {
          if (!deleting.data.error) {
            history.push({
              pathname: "/pengaturan/sekolah",
              toast: "Berhasil menghapus sekolah",
            });
          } else {
            throw deleting.data.error;
          }
        } else {
          throw errorMessage("menghapus sekolah");
        }
      } catch (error) {
        toast(<Failed>{error}</Failed>);
        setState({
          ...state,
          isLoading: false,
        });
      }
    }
  };

  console.log("state sekolah", state);
  return (
    <Private {...props}>
      <Breadcrumb data={state.breadcrumb} />
      <div className="wrapper-form">
        <Grid container justify="space-between">
          <Grid item>
            <div className="header-title">EDIT SEKOLAH</div>
          </Grid>
          <Grid item>
            <ButtonSecondary
              onClick={deleteSekolah}
              icon="delete"
              color="#DC6E6E"
            >
              HAPUS
            </ButtonSecondary>
          </Grid>
        </Grid>
      </div>
      <form onSubmit={(e) => submitSekolah(e)}>
        <div className="wrapper">
          {state.isLoading ? (
            <Loading />
          ) : (
            <div className="container">
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <TextField
                    name="pendidikan"
                    label="Pendidikan"
                    placeholder="Isikan Pendidikan"
                    className="mb-2"
                    value={state.pendidikan}
                    onChange={(e) => onChange(e.target.value, "pendidikan")}
                    required
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    type="number"
                    name="biaya"
                    label="Biaya"
                    placeholder="Isikan Biaya"
                    className="mb-2"
                    value={state.biaya}
                    onChange={(e) => onChange(e.target.value, "biaya")}
                    required
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    type="number"
                    name="jumlah_tingkat"
                    label="Jumlah Tingkat"
                    placeholder="Isikan Jumlah Tingkat"
                    className="mb-2"
                    value={state.jumlah_tingkat}
                    onChange={(e) => onChange(e.target.value, "jumlah_tingkat")}
                    required
                    disabled
                  />
                </Grid>
              </Grid>
              <Grid className="mt-4" container spacing={3} justify="flex-end">
                <Grid item>
                  <ButtonSecondary onClick={cancel}>Batalkan</ButtonSecondary>
                </Grid>
                <Grid item>
                  {state.loading ? (
                    <ButtonLoading>Memproses...</ButtonLoading>
                  ) : (
                    <ButtonPrimary type="submit">
                      Simpan Perubahan
                    </ButtonPrimary>
                  )}
                </Grid>
              </Grid>
            </div>
          )}
        </div>
      </form>
      <Toastr />
    </Private>
  );
};

export default EditSekolah;
