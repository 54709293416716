import React from 'react';

const ButtonPrimary = props => {
    return (
        <button
            type={props.type || 'submit'}
            className={`button-primary ${props.className}`}
            style={props.style}
            onClick={props.onClick}
        >
            {props.children}
        </button>
    );
};

export default ButtonPrimary;