/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useState, useEffect } from "react";
import DropdownFilter from "../../../../component/Field/DropdownFilter";
import { listPendidikanOptionForRegisterURL } from "../../../../api/sekolah";
import Axios from "axios";
import { toast } from "react-toastify";
import Failed from "../../../../component/Misc/Failed";
import {
  FormGroup,
  FormControlLabel,
  Checkbox,
  Grid,
  RadioGroup,
  Radio,
} from "@material-ui/core";
import ButtonPrimary from "../../../../component/Button/ButtonPrimary";
import DateRangePembayaran from "../../Pembayaran/BuatPembayaran/DateRangePembayaran";

const DefaultList = (props) => {
  console.log("props", props);
  return (
    <div className="p-3">
      <FormGroup>
        {props.data.map((data, index) => {
          return (
            <FormControlLabel
              key={index}
              control={
                <Checkbox
                  color="default"
                  checked={data.checked}
                  onChange={() => props.handleChange(index, data)}
                  name={data.label}
                />
              }
              label={data.label}
            />
          );
        })}
      </FormGroup>
    </div>
  );
};

const PeriodFilter = (props) => {
  return (
    <div className="p-3">
      <div>
        <RadioGroup
          aria-label="Gender"
          name="gender1"
          value={props.period.type}
          onChange={(e) => {
            props.onChangePeriod({
              type: e.target.value,
              endDate: props.period.startDate,
              startDate: props.period.endDate,
            });
          }}
        >
          <FormControlLabel
            value="All"
            control={<Radio color="default" />}
            label={
              <span style={{ fontWeight: 700, fontSize: 16 }}>All Period</span>
            }
          />

          <FormControlLabel
            value="Date Range"
            control={<Radio color="default" />}
            label={
              <span style={{ fontWeight: 700, fontSize: 16 }}>Date Range</span>
            }
          />
        </RadioGroup>
      </div>
      <div className="d-flex align-items-end">
        <DateRangePembayaran
          label="Periode"
          startDate={props.period.startDate}
          endDate={props.period.endDate}
          startPlaceholder="Dari tanggal"
          endPlaceholder="Sampai tanggal"
          setStartDate={(e) =>
            props.onChangePeriod({
              type: props.period.type,
              startDate: e,
              endDate: props.period.endDate,
            })
          }
          setEndDate={(e) =>
            props.onChangePeriod({
              type: props.period.type,
              startDate: props.period.startDate,
              endDate: e,
            })
          }
          disabled={props.period.type === "All"}
        />
        <ButtonPrimary
          className="ml-2"
          style={{ width: "fit-content" }}
          type="button"
          onClick={props.filterPeriod}
        >
          Filter
        </ButtonPrimary>
      </div>
    </div>
  );
};

const FilterPenagihan = (props) => {
  const [sekolah, setSekolah] = useState({
    isLoading: false,
    data: [],
  });

  const [filterCard, setFilterCard] = useState([]);

  const [period, setPeriod] = useState({
    type: "All",
    startDate: null,
    endDate: null,
  });

  const getAllSekolahData = useCallback(async () => {
    setSekolah({
      ...sekolah,
      isLoading: true,
    });

    const urlParam = `${listPendidikanOptionForRegisterURL}`;

    try {
      const sekolah = await Axios.get(urlParam);
      if (sekolah) {
        // console.log("tahun ajaran", sekolah);
        setSekolah({
          ...sekolah,
          data: sekolah.data.data.map((data) => {
            return {
              ...data,
              checked: false,
            };
          }),
          isLoading: false,
        });
      } else {
        const errorText = "Error";
        throw errorText;
      }
    } catch (error) {
      setSekolah({
        ...sekolah,
        isLoading: false,
      });
      toast(<Failed>{error}</Failed>);
    }
  }, []);

  useEffect(() => {
    getAllSekolahData();
  }, []);

  useEffect(() => {
    console.log("filter card use effect", filterCard);
    const data = {
      sekolah: sekolah.data.filter((x) => x.checked).map((x) => x.value),
      period,
    };
    console.log("data filter period", data);
    props.getFilterData(data);
  }, [filterCard]);

  const onChangeSekolah = (index, data) => {
    setSekolah({
      ...sekolah,
      data: sekolah.data.map((item, idx) => {
        if (index === idx) {
          return {
            ...item,
            checked: true,
          };
        }
        return {
          ...item,
          checked: false,
        };
      }),
    });

    if (filterCard.length) {
      setFilterCard(
        filterCard.map((res) => {
          if (res.type === "sekolah") {
            return { ...data, type: "sekolah" };
          }

          return res;
        })
      );
    } else {
      setFilterCard(filterCard.concat({ ...data, type: "sekolah" }));
    }
  };

  const filterPeriod = () => {
    if (period.type === "Date Range" && period.startDate && period.endDate) {
      const data = {
        sekolah: sekolah.data.filter((x) => x.checked),
        period,
      };
      console.log("data filter period", data);
      props.getFilterData(data);
    }

    if (period.type === "All") {
      const data = {
        sekolah: sekolah.data.filter((x) => x.checked).map((x) => x.value),
        period,
      };
      props.getFilterData(data);
    }
  };

  const onChangePeriod = (data) => {
    setPeriod(data);

    if (data.type === "All") {
      const result = {
        sekolah: sekolah.data.filter((x) => x.checked).map((x) => x.value),
        period: {
          type: "All",
          startDate: null,
          endDate: null,
        },
      };
      props.getFilterData(result);
    }
  };

  const removeCard = (data) => {
    console.log("remove", data);
    setFilterCard(filterCard.filter((item) => item.value !== data.value));

    if (data.type === "sekolah") {
      setSekolah({
        ...sekolah,
        data: sekolah.data.map((item) => {
          if (data.value === item.value) {
            return {
              ...item,
              checked: !item.checked,
            };
          }
          return {
            ...item,
          };
        }),
      });
    }
  };

  return (
    <div>
      <div className="color-grey">Filter</div>
      <div className="d-flex">
        <DropdownFilter
          title="Sekolah"
          pr={24}
          borderRight
          filter={
            <DefaultList
              data={sekolah.data}
              handleChange={onChangeSekolah}
              {...props}
            />
          }
        />

        <DropdownFilter
          title="Periode"
          pl={24}
          filter={
            <PeriodFilter
              period={period}
              onChangePeriod={(data) => onChangePeriod(data)}
              filterPeriod={filterPeriod}
              {...props}
            />
          }
        />
      </div>

      {!!filterCard.length && (
        <Grid container spacing={3} className="filter-card" alignItems="center">
          <Grid item>
            Menampilkan <span className="text-bold">{props.total}</span> siswa
            dengan filter{" "}
          </Grid>
          {filterCard.map((data) => {
            return (
              <Grid item key={`${data.type}-${data.value}`}>
                <div className="filter-chip">
                  <span> {data.label} </span>
                  <button
                    onClick={() => removeCard(data)}
                    className="filter-chip__button"
                  >
                    {" "}
                    <i className="material-icons">close</i>
                  </button>
                </div>
              </Grid>
            );
          })}
        </Grid>
      )}
    </div>
  );
};

export default FilterPenagihan;
