import checkEnv from "./index";

// POST
const createTahunAjaranURL = `${checkEnv()}/tahun-ajaran/create`;

const updateTahunAjaranURL = (id) => {
  return `${checkEnv()}/tahun-ajaran/update/${id}`;
};

const editStatusTahunAjaranURL = (id) => {
  return `${checkEnv()}/tahun-ajaran/status/${id}`;
};

const deleteTahunAjaranURL = (id) => {
  return `${checkEnv()}/tahun-ajaran/delete/${id}`;
};

// GET
const listTahunAjaranURL = `${checkEnv()}/tahun-ajaran/get-all`;
const currentTahunAjaranURL = `${checkEnv()}/tahun-ajaran/get-current-year`;
const activeTahunAjaranURL = `${checkEnv()}/tahun-ajaran/get-all-active`;
const listTahunAjaranGetActiveOptionURL = `${checkEnv()}/tahun-ajaran/get-active-options`;
const findIdTahunAjaranURL = (id) => {
  return `${checkEnv()}/tahun-ajaran/find/${id}`;
};

export {
  listTahunAjaranURL,
  createTahunAjaranURL,
  updateTahunAjaranURL,
  editStatusTahunAjaranURL,
  deleteTahunAjaranURL,
  findIdTahunAjaranURL,
  currentTahunAjaranURL,
  activeTahunAjaranURL,
  listTahunAjaranGetActiveOptionURL,
};
