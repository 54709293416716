import React, { useState } from "react";
import Private from "../../../../layout/Private";
import Breadcrumb from "../../../../component/Navigation/Breadcrumb";
import { Grid } from "@material-ui/core";
import ButtonSecondary from "../../../../component/Button/ButtonSecondary";
import ButtonPrimary from "../../../../component/Button/ButtonPrimary";
import { toast } from "react-toastify";
import Failed from "../../../../component/Misc/Failed";
import ButtonLoading from "../../../../component/Button/ButtonLoading";
import Toastr from "../../../../component/Misc/Toastr";
import SelectDate from "../../../../component/Field/SelectDate";
import MultipleSelect from "../../../../component/Field/MultipleSelect";
import moment from "moment";

const optionLaporan = [
  {
    label: "Pengeluaran",
    value: "Pengeluaran",
  },
  {
    label: "Pendapatan",
    value: "Pendapatan",
  },
];

const GenerateLaporan = (props) => {
  const [state, setState] = useState({
    sampai: null,
    dari: null,
    laporan: null,
    breadcrumb: [
      {
        name: "EEC",
        url: "/dashboard",
        active: false,
      },
      {
        name: "Akunting",
        url: "/akunting",
        active: false,
      },
      {
        name: "Laporan",
        url: "/akunting/laporan",
        active: true,
      },
    ],
  });

  const onChange = (value, name) => {
    setState({
      ...state,
      [name]: value,
    });
  };

  const reset = () => {
    if (window.confirm("Apakah anda yakin akan mereset filter laporan?")) {
      setState({
        ...state,
        sampai: null,
        dari: null,
        laporan: null,
      });
    }
  };

  const generateLaporan = async (e) => {
    e.preventDefault();
    setState({
      ...state,
      loading: true,
    });

    try {
      const checkLaporan = state.laporan?.map((data) => data.label);

      if (!checkLaporan) {
        throw new Error("Pilih tipe laporan!");
      }

      window.open(
        `${window.location.origin}/akunting/laporan/pdf?dari_tanggal=${moment(
          new Date(state.dari)
        ).format("YYYY-MM-DD")}&hingga_tanggal=${moment(
          new Date(state.sampai)
        ).format("YYYY-MM-DD")}&pendapatan=${checkLaporan.includes(
          "Pendapatan"
        )}&pengeluaran=${checkLaporan.includes("Pengeluaran")}`,
        "_blank"
      );

      setState({
        ...state,
        loading: false,
      });
    } catch (error) {
      console.log(error);
      toast(<Failed>{error.message}</Failed>);
      setState({
        ...state,
        loading: false,
      });
    }
  };

  console.log("state sekolah", state);
  return (
    <Private {...props}>
      <Breadcrumb data={state.breadcrumb} />
      <div className="wrapper-form">
        <Grid container justify="space-between" alignItems="center">
          <Grid item>
            <div className="header-title">LAPORAN</div>
          </Grid>
          <Grid item>
            <ButtonSecondary onClick={reset} icon="cached" color="#284979">
              Reset
            </ButtonSecondary>
          </Grid>
        </Grid>
      </div>
      <form onSubmit={(e) => generateLaporan(e)}>
        <div className="wrapper">
          <div className="">
            <Grid container spacing={3}>
              <Grid item xs={12} md={4}>
                <MultipleSelect
                  name="laporan"
                  label="Laporan"
                  placeholder="Pilih Laporan"
                  className="mb-2"
                  value={state.laporan}
                  onChange={(e) => onChange(e, "laporan")}
                  options={optionLaporan}
                  required
                />
                {/* <TextField
                  name="laporan"
                  label="Laporan"
                  placeholder="Pilih Laporan"
                  className="mb-2"
                  value={state.laporan}
                  onChange={(e) => onChange(e.target.value, "laporan")}

                /> */}
              </Grid>
              <Grid item xs={12} md={4}>
                <SelectDate
                  type="number"
                  name="dari"
                  label="Dari"
                  className="mb-2"
                  value={state.dari}
                  onChange={(e) => onChange(e, "dari")}
                  placeholder="Pilih tanggal awal laporan"
                  required
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <SelectDate
                  type="number"
                  name="sampai"
                  label="Sampai"
                  className="mb-2"
                  value={state.sampai}
                  onChange={(e) => onChange(e, "sampai")}
                  placeholder="Pilih tanggal akhir laporan"
                  required
                />
              </Grid>
            </Grid>
            <Grid className="mt-4" container spacing={3} justify="flex-end">
              <Grid item>
                {state.loading ? (
                  <ButtonLoading>Memproses...</ButtonLoading>
                ) : (
                  <ButtonPrimary type="submit">Generate Laporan</ButtonPrimary>
                )}
              </Grid>
            </Grid>
          </div>
        </div>
      </form>
      <Toastr />
    </Private>
  );
};

export default GenerateLaporan;
