import React from 'react';

const ButtonLoading = props => {
    return (
        <button
            type="button"
            className={`button-loading ${props.className}`}
            style={props.style}
            
        >
            {props.children}
        </button>
    );
};

export default ButtonLoading;