import React, { Component } from "react";
import { PieChart, Pie, Cell, ResponsiveContainer, Legend, Tooltip } from "recharts";

const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042"];

export default class DashboardPieChart extends Component {
  static jsfiddleUrl = "https://jsfiddle.net/alidingling/3Leoa7f4/";

  renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    index,
  }) => {
  const RADIAN = Math.PI / 180;
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text
        x={x}
        y={y}
        fill="white"
        textAnchor={x > cx ? "start" : "end"}
        dominantBaseline="central"
      >
        {percent > 0.04 && `${(percent * 100).toFixed(0)}%`}
      </text>
    );
  };

  customTooltip = ({ active, payload, label }) => {
    console.log(payload)
    if (active) {
      return (
        <div className="custom-tooltip">
          <div className="label"><strong>{payload[0].name}</strong> : {payload[0].value} siswa</div>
        </div>
      );
    }
  
    return null;

  };

  render() {
   

    return (
      <ResponsiveContainer height={this.props.height || 300}>
        <PieChart onMouseEnter={this.onPieEnter}>
          <Pie
            label={this.renderCustomizedLabel}
            labelLine={false}
            legendType="circle"
            data={this.props.data}
            fill="#8884d8"
            dataKey="value"
          >
            {this.props.data.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={COLORS[index % COLORS.length]}
              />
            ))}
          </Pie>
          <Legend
            // layout="vertical"
            align="left"
            // verticalAlign="middle"
            // height={36}
            wrapperStyle={{ fontSize: 14 }}
          />

          <Tooltip content={this.customTooltip} />
        </PieChart>
      </ResponsiveContainer>
    );
  }
}
