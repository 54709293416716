/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import Private from "../../../../layout/Private";
import Breadcrumb from "../../../../component/Navigation/Breadcrumb";
import { Grid } from "@material-ui/core";
import ButtonSecondary from "../../../../component/Button/ButtonSecondary";
import ButtonPrimary from "../../../../component/Button/ButtonPrimary";
import Axios from "axios";
import { toast } from "react-toastify";
import Failed from "../../../../component/Misc/Failed";
import ButtonLoading from "../../../../component/Button/ButtonLoading";
import { useHistory, useRouteMatch } from "react-router-dom";
import { cancelMessage, errorMessage, deleteMessage } from "../../../../helper/Static";
import TextField from "../../../../component/Field/TextField";
import { listPendidikanConcatURL } from "../../../../api/sekolah";
import SingleSelect from "../../../../component/Field/SingleSelect";
import { findIdKelasURL, updateKelasURL, deleteKelasURL } from "../../../../api/kelas";
import Loading from "../../../../component/Misc/Loading";
import Toastr from "../../../../component/Misc/Toastr";

const EditKelas = props => {
  const match = useRouteMatch();
  const history = useHistory();
  const [list, setList] = useState({
    listPendidikan: [],
    isLoading: true,
  });
  const [type, setType] = useState("");
  const [state, setState] = useState({
    jumlah_siswa: "",
    pendidikan: "",
    tingkat: "",
    nama: "",
    breadcrumb: [
      {
        name: "EEC",
        url: "/dashboard",
        active: false,
      },
      {
        name: "Pengaturan",
        url: "/pengaturan",
        active: false,
      },
      {
        name: "Kelas",
        url: "/pengaturan/kelas",
        active: false,
      },
      {
        name: "Edit Kelas",
        url: "/pengaturan/kelas/edit",
        active: true,
      },
    ],
  });

  const onChange = (value, name) => {
    setState({
      ...state,
      [name]: value,
    });

    if (name === "pendidikan") {
      if (value.value.indexOf("kursus") !== -1) {
        setType("kursus");
      } else {
        setType("sekolah");
      }
    }
  };

  const getCurrentData = async () => {
    console.log("current Data");
    setState({
      ...state,
      dataLoading: true,
    });
    try {
      const sekolah = await Axios.get(findIdKelasURL(match.params.id));
      if (!sekolah.data.error) {
        const checkPendidikan = sekolah.data.data.pendidikan
          ? {
              value: `sekolah-${sekolah.data.data.pendidikan.id}`,
              label: sekolah.data.data.pendidikan.nama,
            }
          : {
              value: `kursus-${sekolah.data.data.kursus.id}`,
              label: sekolah.data.data.kursus.nama,
            };
        setState({
          ...state,
          nama: sekolah.data.data.nama,
          pendidikan: { ...checkPendidikan },
          tingkat: sekolah.data.data.tingkat,
          jumlah_siswa: sekolah.data.data.jumlah_siswa,
          dataLoading: false,
        });

        setType(sekolah.data.data.pendidikan ? "sekolah" : "kursus");
      } else {
        const errorText = "Error";
        throw errorText;
      }
    } catch (error) {
      setState({
        ...state,
        dataLoading: false,
      });
      toast(<Failed>{error}</Failed>);
    }
  };

  const getListPendidikan = async () => {
    setList({
      ...list,
      isLoading: true,
    });
    try {
      const sekolah = await Axios.get(listPendidikanConcatURL);
      if (sekolah) {
        setList({
          ...list,
          listPendidikan: sekolah.data.data,
          isLoading: false,
        });
      } else {
        const errorText = "Error";
        throw errorText;
      }
    } catch (error) {
      setList({
        ...list,
        isLoading: false,
      });
      toast(<Failed>{error}</Failed>);
    }
  };

  useEffect(() => {
    getListPendidikan();
    getCurrentData();
  }, []);

  const cancel = () => {
    if (window.confirm(cancelMessage(`edit kelas`))) {
      history.push("/pengaturan/kelas");
    }
  };

  const submitKursus = async (e) => {
    e.preventDefault();
    setState({
      ...state,
      loading: true,
    });

    try {
      const submit = await Axios.post(updateKelasURL(match.params.id), {
        nama: state.nama,
        pendidikan: state.pendidikan.value,
        jumlah_siswa: parseInt(state.jumlah_siswa) || 0,
        tingkat: parseInt(state.tingkat) || 0,
      });

      console.log("submit", submit);

      if (submit) {
        if (!submit.data.error) {
          history.push({
            pathname: "/pengaturan/kelas",
            toast: "Berhasil mengedit kelas",
          });
          setState({
            ...state,
            loading: false,
          });
        } else {
          throw new Error(submit.data.error);
        }
      } else {
        throw new Error(errorMessage("mengedit kelas"));
      }
    } catch (error) {
      toast(<Failed>{error.message}</Failed>);
      setState({
        ...state,
        loading: false,
      });
    }
  };

  const deleteKelas = async () => {
    if (window.confirm(deleteMessage(`kelas ${state.nama}`))) {
      try {
        const deleting = await Axios.post(deleteKelasURL(match.params.id));
        if (deleting.data) {
          if (!deleting.data.error) {
            history.push({
              pathname: "/pengaturan/kelas",
              toast: "Berhasil menghapus kelas",
            });
          } else {
            throw deleting.data.error;
          }
        } else {
          throw errorMessage("menghapus kelas");
        }
      } catch (error) {
        toast(<Failed>{error}</Failed>);
        setState({
          ...state,
          isLoading: false,
        });
      }
    }
  };

  console.log("state kelas", state);
  return (
    <Private {...props}>
      <Breadcrumb data={state.breadcrumb} />
      <div className="wrapper-form">
        <Grid container justify="space-between">
          <Grid item>
            <div className="header-title">EDIT KELAS</div>
          </Grid>
          <Grid item>
            <ButtonSecondary
              onClick={deleteKelas}
              icon="delete"
              color="#DC6E6E"
            >
              HAPUS
            </ButtonSecondary>
          </Grid>
        </Grid>
      </div>
      <form onSubmit={(e) => submitKursus(e)}>
        <div className="wrapper">
          {state.dataLoading || state.isLoading ? (
            <Loading />
          ) : (
            <div className="container">
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <TextField
                    name="nama_kelas"
                    label="Nama Kelas"
                    placeholder="Isikan Nama"
                    className="mb-2"
                    value={state.nama}
                    onChange={(e) => onChange(e.target.value, "nama")}
                    required
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <SingleSelect
                    type="number"
                    name="pendidikan"
                    label="Pendidikan"
                    placeholder="Isikan Pendidikan"
                    className="mb-2"
                    value={state.pendidikan}
                    onChange={(e) => onChange(e, "pendidikan")}
                    options={list.listPendidikan}
                    required
                    isDisabled
                  />
                </Grid>

                {type !== "kursus" && (
                  <Grid item xs={12} md={6}>
                    <TextField
                      type="number"
                      name="tingkat"
                      label="Tingkat"
                      placeholder="Isikan Tingkat"
                      className="mb-2"
                      value={state.tingkat}
                      onChange={(e) => onChange(e.target.value, "tingkat")}
                      required
                      disabled
                    />
                  </Grid>
                )}

                {/* <Grid item xs={12} md={6}>
                  <TextField
                    type="number"
                    name="jumlah_siswa"
                    label="Jumlah Siswa"
                    placeholder="Isikan Jumlah Siswa"
                    className="mb-2"
                    value={state.jumlah_siswa}
                    onChange={(e) => onChange(e.target.value, "jumlah_siswa")}
                    required
                  />
                </Grid> */}
              </Grid>
              <Grid className="mt-4" container spacing={3} justify="flex-end">
                <Grid item>
                  <ButtonSecondary onClick={cancel}>Batalkan</ButtonSecondary>
                </Grid>
                <Grid item>
                  {state.loading ? (
                    <ButtonLoading>Memproses...</ButtonLoading>
                  ) : (
                    <ButtonPrimary type="submit">
                      Simpan Perubahan
                    </ButtonPrimary>
                  )}
                </Grid>
              </Grid>
            </div>
          )}
        </div>
      </form>
      <Toastr />
    </Private>
  );
};

export default EditKelas;
