/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useCallback } from "react";
import Breadcrumb from "../../../component/Navigation/Breadcrumb";
import Private from "../../../layout/Private";
import TablePagination from "../../../component/Navigation/TablePagination";
import MaterialTable from "material-table";
import { Grid, Tooltip } from "@material-ui/core";
import { useLocation, Link } from "react-router-dom";
import { toast } from "react-toastify";
import Failed from "../../../component/Misc/Failed";
import Success from "../../../component/Misc/Success";
import Toastr from "../../../component/Misc/Toastr";
import Axios from "axios";
// import { errorMessage, deleteMessage } from "../../../../helper/Static";
// import { deleteKelasURL, listKelasURL } from "../../../../api/kelas";
import TextField from "../../../component/Field/TextField";
import useDebounce from "../../../helper/useDebounce";
// import ButtonSecondary from "../../../component/Button/ButtonSecondary";
// import { cancelMessage, errorMessage } from "../../../helper/Static";
// import moment from "moment";
import { listDaftarKelasURL } from "../../../api/kelas";

const DaftarKelas = (props) => {
  const location = useLocation();
  const [state, setState] = useState({
    breadcrumb: [
      {
        name: "EEC",
        url: "/dashboard",
        active: false,
      },
      {
        name: "Daftar Kelas",
        url: "/kelas-list",
        active: false,
      },
      {
        name: "Kelas",
        url: "/kelas-list/kelas",
        active: true,
      },
    ],
    kelas: [],
    total: "",
    isLoading: true,
  });

  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const debouncedSearch = useDebounce(search, 500);

  const getAllData = useCallback(async () => {
    setState({
      ...state,
      isLoading: true,
    });

    const urlParam = `${listDaftarKelasURL}?page=${page}${
      search ? `&search=${debouncedSearch}` : ""
    }`;

    try {
      const kelas = await Axios.get(urlParam);

      console.log("kelas", kelas);

      if (kelas) {
        // console.log("tahun ajaran", kelas);
        setState({
          ...state,
          kelas: kelas.data.data,
          total: kelas.data.total,
          isLoading: false,
        });
      } else {
        const errorText = "Error";
        throw errorText;
      }
    } catch (error) {
      setState({
        ...state,
        isLoading: false,
      });
      toast(<Failed>{error}</Failed>);
    }
  }, [page, debouncedSearch]);

  useEffect(() => {
    getAllData();
  }, [getAllData]);

  useEffect(() => {
    if (location.toast) {
      toast(<Success>{location.toast}</Success>);
    }
  }, []);

  const onChangeSearch = (e) => {
    setSearch(e.target.value);
    setPage(1);
  };

  console.log("pendaftaraaan", state.kelas);

  return (
    <Private {...props}>
      <Breadcrumb data={state.breadcrumb} />
      <div className="wrapper table-wrapper">
        <Grid container className="mb-3" spacing={3} justify="flex-end">
          <Grid item xs={12} lg={4}>
            <TextField
              value={search}
              placeholder="Cari Kelas"
              onChange={(e) => onChangeSearch(e)}
            />
          </Grid>
        </Grid>
        <div className="table-title">Daftar Kelas</div>
        <MaterialTable
          columns={[
            {
              title: "Pendidikan",
              field: "pendidikan",
              render: (rowData) => (
                <div
                >
                  {rowData.pendidikan}
                </div>
              ),
            },
            {
              title: "Nama Kelas",
              field: "nama_kelas",
              render: (rowData) => (
                <Link
                  className="link-underline text-bold"
                  to={`/kelas-list/daftar/${rowData.id}`}
                >
                  {rowData.nama_kelas}
                </Link>
              ),
            },
            {
              title: "Durasi Pembelajaran",
              field: "durasi_pembelajaran",
              render: (rowData) => (
                <div
                >
                  {rowData.durasi_pembelajaran}
                </div>
              ),
            },
           
            {
              title: "Jumlah Siswa",
              field: "jumlah_siswa",
              render: (rowData) => (
                <div
                  
                >
                  {rowData.jumlah_siswa}
                </div>
              ),
            },
           
            {
              title: "Status",
              field: "status",
              render: (rowData) => {
                return (
                  <div>
                    {rowData.status === "Penuh" ? (
                      <div className="tag-grey">
                        Penuh
                        </div>
                    ) : (
                      <div style={{ color: "#80848D", textDecoration: 'italic', fontSize: 14 }}>
                        {rowData.status}
                      </div>
                    )}
                  </div>
                );
              },
            },
            {
                title: "",
                render: (rowData) => {
                  return (
                    <div className="table-action">
                      <Tooltip title="Edit kelas" arrow>
                        <Link to={`/kelas-list/daftar/${rowData.id}?state=edit`}>
                          <i className="material-icons table-edit">edit</i>
                        </Link>
                      </Tooltip>
                    </div>
                  );
                },
            },
          ]}
          isLoading={state.isLoading}
          data={state.kelas}
          options={{
            toolbar: false,
            padding: "dense",
            pageSize: 10,
          }}
          components={{
            Pagination: (props) => {
              // console.log("pagination props", props);
              return (
                <td>
                  <TablePagination
                    onChangePage={(e) => setPage(e)}
                    total={state.total}
                    page={page}
                    itemShow={props.rowsPerPage}
                  />
                </td>
              );
            },
          }}
        />
      </div>
      <Toastr />
    </Private>
  );
};

export default DaftarKelas;
