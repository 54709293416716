/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useState } from "react";
import { Grid, Icon, IconButton } from "@material-ui/core";
import TextField from "../../../../component/Field/TextField";
import ButtonAddItem from "../../../../component/Button/ButtonAddItem";
import usePrevious from "../../../../helper/usePrevious";
import { isEqual } from "lodash";
import { useContext } from "react";
import RegistrasiSiswaContext from "../../../../context/RegisterSiswa/RegisterSiswaContext";

const PaketLainLain = (props) => {
  const [others, setOthers] = useState([]);
  const siswaContext = useContext(RegistrasiSiswaContext);

  const prevOthers = usePrevious(others);

  const onChangeOthers = (value, name, index) => {
    setOthers(
      others.map((other, i) => {
        if (index === i) {
          return {
            ...other,
            [name]: value,
          };
        }
        return other;
      })
    );
  };

  const addItem = () => {
    setOthers(
      others.concat({
        harga: "",
        nama: "",
      })
    );
  };

  const deleteItem = (index) => {
    setOthers(others.filter((data, i) => i !== index));
  };

  useEffect(() => {
    if(!isEqual(others, prevOthers)) {
      props.setInventori(others);
    }
  }, [others]);

  useEffect(() => {
    if(siswaContext.isReset) {
      setOthers([])
    }
  }, [siswaContext.isReset])

  return (
    <div className="container pt-4 pb-4">
      <div className="text-bold text-uppercase color-ocean-blue mb-4">
        Lain-lain
      </div>
      <div>
        {others.map((data, index) => {
          return (
            <Grid
              key={index}
              container
              spacing={3}
              style={{ position: "relative" }}
            >
              <Grid item xs={12} md={6}>
                <TextField
                  name="nama"
                  label="Nama Barang"
                  placeholder="Isikan Nama Barang"
                  className="mb-2"
                  value={data.nama}
                  onChange={(e) =>
                    onChangeOthers(e.target.value, "nama", index)
                  }
                  required
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  type="number"
                  name="harga"
                  label="Harga"
                  placeholder="Isikan Harga"
                  className="mb-2"
                  value={data.harga}
                  onChange={(e) =>
                    onChangeOthers(e.target.value, "harga", index)
                  }
                  required
                />
              </Grid>
              <Grid
                item
                style={{ position: "absolute", right: "-56px", top: 26 }}
              >
                <IconButton onClick={() => deleteItem(index)}>
                  <Icon style={{ color: "#DC6E6E" }}>delete</Icon>
                </IconButton>
              </Grid>
            </Grid>
          );
        })}
        <ButtonAddItem onClick={addItem} className="mt-4" icon="add">
          Tambah Item
        </ButtonAddItem>
      </div>
    </div>
  );
};

export default PaketLainLain;
