import checkEnv from "./index";

// POST
const createKelasURL = `${checkEnv()}/kelas/create`;

const updateKelasURL = (id) => {
    return `${checkEnv()}/kelas/update/${id}`;
  };

const deleteKelasURL = (id) => {
  return `${checkEnv()}/kelas/delete/${id}`;
};

// GET
const listKelasURL = `${checkEnv()}/kelas/get-all`;
const listDaftarKelasURL = `${checkEnv()}/kelas/daftar-kelas`;
const listKelasGetOptionURL = `${checkEnv()}/kelas/get-options-for-sekolah`;

const findIdKelasURL = (id) => {
  return `${checkEnv()}/kelas/find/${id}`;
};

const findKelasByPendidikanURL = (id) => {
  return `${checkEnv()}/kelas/find-kelas-by-pendidikan/${id}`;
};

export {
  listKelasURL,
  createKelasURL,
  updateKelasURL,
  deleteKelasURL,
  findIdKelasURL,
  findKelasByPendidikanURL,
  listKelasGetOptionURL,
  listDaftarKelasURL,
};
