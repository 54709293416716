/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useState, useEffect } from "react";
import DropdownFilter from "../../../../component/Field/DropdownFilter";
import {
  listPendidikanOptionForRegisterURL,
  listPendidikanOptionForSiswaURL,
} from "../../../../api/sekolah";
import Axios from "axios";
import { toast } from "react-toastify";
import Failed from "../../../../component/Misc/Failed";
import { FormGroup, FormControlLabel, Checkbox, Grid } from "@material-ui/core";
import { listKursusGroupedURL } from "../../../../api/kursus";

const DefaultList = (props) => {
  console.log("props", props);
  return (
    <div className="p-3">
      <FormGroup>
        {props.data.map((data, index) => {
          return (
            <FormControlLabel
              key={index}
              control={
                <Checkbox
                  color="default"
                  checked={data.checked}
                  onChange={() => props.handleChange(index, data)}
                  name={data.label}
                />
              }
              label={data.label}
            />
          );
        })}
      </FormGroup>
    </div>
  );
};

const GroupedList = (props) => {
  console.log("props", props);
  return (
    <div className="p-3">
      <Grid container spacing={3}>
        {props.data.map((type, index) => {
          return (
            <Grid item xs={12} md={6} key={index}>
              <div className="text-bold">{type.type}</div>
              <FormGroup>
                {type.list.map((data, idx) => {
                  const cardData = {
                    ...data,
                    name: type.type
                  }
                  return (
                    <FormControlLabel
                      key={idx}
                      control={
                        <Checkbox
                          color="default"
                          checked={data.checked}
                          onChange={() => props.handleChange(index, cardData, idx)}
                          name={data.label}
                        />
                      }
                      label={data.label}
                    />
                  );
                })}
              </FormGroup>
            </Grid>
          );
        })}
      </Grid>
    </div>
  );
};

const KelasList = (props) => {
  console.log("props", props);
  return (
    <div className="p-3">
      {props.data.length ? (
        <Grid container spacing={3}>
          {props.data.map((type, index) => {
            if (type.kelas.length) {
              return (
                <Grid item xs={12} key={index}>
                  <div className="text-bold">{type.name}</div>
                  <FormGroup>
                    {type.kelas.map((data, idx) => {
                      return (
                        <FormControlLabel
                          key={idx}
                          control={
                            <Checkbox
                              color="default"
                              checked={data.checked}
                              onChange={() =>
                                props.handleChange(index, data, idx)
                              }
                              name={data.label}
                            />
                          }
                          label={data.label}
                        />
                      );
                    })}
                  </FormGroup>
                </Grid>
              );
            }
            return null;
          })}
        </Grid>
      ) : (
        <div>Mohon pilih sekolah/kursus terlebih dahulu!</div>
      )}
    </div>
  );
};

const FilterSiswa = (props) => {
  const [sekolah, setSekolah] = useState({
    isLoading: false,
    data: [],
  });

  const [kursus, setKursus] = useState({
    isLoading: false,
    data: [],
  });

  const [kelas, setKelas] = useState({
    isLoading: false,
    data: [],
  });

  const [filterCard, setFilterCard] = useState([]);

  const getAllSekolahData = useCallback(async () => {
    setSekolah({
      ...sekolah,
      isLoading: true,
    });

    const urlParam = `${listPendidikanOptionForRegisterURL}`;

    try {
      const sekolah = await Axios.get(urlParam);
      if (sekolah) {
        // console.log("tahun ajaran", sekolah);
        setSekolah({
          ...sekolah,
          data: sekolah.data.data.map((data) => {
            return {
              ...data,
              checked: false,
            };
          }),
          isLoading: false,
        });
      } else {
        const errorText = "Error";
        throw errorText;
      }
    } catch (error) {
      setSekolah({
        ...sekolah,
        isLoading: false,
      });
      toast(<Failed>{error}</Failed>);
    }
  }, []);

  useEffect(() => {
    getAllSekolahData();
  }, []);

  const getAllKursusData = useCallback(async () => {
    setKursus({
      ...kursus,
      isLoading: true,
    });

    const urlParam = `${listKursusGroupedURL}`;

    try {
      const kursus = await Axios.get(urlParam);
      if (kursus) {
        // console.log("tahun ajaran", kursus);
        if (!kursus.data.error) {
          setKursus({
            ...kursus,
            data: kursus.data.data,
            isLoading: false,
          });
        } else {
          throw new Error(kursus.data.error);
        }
      } else {
        throw new Error('Tidak dapat mengambil data kursus.');

      }
    } catch (error) {
      setKursus({
        ...kursus,
        isLoading: false,
      });
      toast(<Failed>{error.message}</Failed>);
    }
  }, []);

  useEffect(() => {
    getAllKursusData();
  }, []);

  const getAllKelasData = useCallback(async (data) => {
    // setKelas({
    //   ...kelas,
    //   isLoading: true,
    // });

    const filterSekolah = data.filter((data) => data.type === "sekolah");
    const filterKursus = data.filter((data) => data.type === "kursus");

    const checkSekolah = filterSekolah.length && {
      sekolah: filterSekolah.map((data) => data.label),
    };
    const checkKursus = filterKursus.length && {
      kursus: filterKursus.map((data) => data.label),
    };

    const checkKelas = data
      .filter((item) => item.type === "kelas")
      .map((item) => item.value);

    try {
      const kelas = await Axios.get(listPendidikanOptionForSiswaURL, {
        params: {
          ...checkSekolah,
          ...checkKursus,
        },
      });
      if (kelas) {
        console.log("tahun ajaran", kelas);
        if (kelas.data) {
          setKelas({
            ...kelas,
            data: kelas.data.data.map((data) => {
              return {
                ...data,
                kelas: data.kelas.map((item) => {
                  return {
                    ...item,
                    name: data.name,
                    checked: checkKelas.includes(item.value),
                  };
                }),
              };
            }),
            isLoading: false,
          });
        } else {
          const errorText = "Error";
          throw errorText;
        }
      } else {
        const errorText = "Error";
        throw errorText;
      }
    } catch (error) {
      setKelas({
        ...kelas,
        isLoading: false,
      });
      toast(<Failed>{error}</Failed>);
    }
  }, []);

  useEffect(() => {
    console.log("filter card use effect", filterCard);
    getAllKelasData(filterCard);
    props.fetchSiswa(filterCard);
  }, [filterCard]);

  const onChangeSekolah = (index, data) => {
    setSekolah({
      ...sekolah,
      data: sekolah.data.map((item, idx) => {
        if (index === idx) {
          return {
            ...item,
            checked: !item.checked,
          };
        }
        return {
          ...item,
        };
      }),
    });

    if (data.checked) {
      setFilterCard(
        filterCard.filter(
          (item) => item.value !== data.value && item.type === "sekolah"
        )
      );
    } else {
      setFilterCard(filterCard.concat({ ...data, type: "sekolah" }));
    }
  };

  const onChangeKursus = (index, data, idx) => {
    setKursus({
      ...kursus,
      data: kursus.data.map((type, i) => {
        if (index === i) {
          const grouped = type.list.map((item, id) => {
            if (idx === id) {
              return {
                ...item,
                checked: !item.checked,
              };
            }
            return item;
          });

          return {
            ...type,
            list: grouped,
          };
        }
        return type;
      }),
    });

    if (data.checked) {
      setFilterCard(
        filterCard.filter(
          (item) => item.value !== data.value && item.type === "kursus"
        )
      );
    } else {
      setFilterCard(filterCard.concat({ ...data, type: "kursus" }));
    }
  };

  const onChangeKelas = (index, data, idx) => {
    setKelas({
      ...kelas,
      data: kelas.data.map((type, i) => {
        if (index === i) {
          const grouped = type.kelas.map((item, id) => {
            if (idx === id) {
              return {
                ...item,
                checked:  !item.checked,
              };
            }
            return item;
          });

          return {
            ...type,
            kelas: grouped,
          };
        }
        return type;
      }),
    });

    if (data.checked) {
      setFilterCard(
        filterCard.filter(
          (item) => item.value !== data.value && item.type !== "kelas"
        )
      );
    } else {
      setFilterCard(filterCard.concat({ ...data, type: "kelas" }));
    }
  };

  const removeCard = data => {
    setFilterCard(
      filterCard.filter(
        (item) => item.value !== data.value && item.type !== data.type
      )
    );

    if(data.type === 'kelas') {
      setKelas({
        ...kelas,
        data: kelas.data.map((type, i) => {
          if (data.name === type.name) {
            const grouped = type.kelas.map((item, id) => {
              if (data.value === item.value) {
                return {
                  ...item,
                  checked: !item.checked,
                };
              }
              return item;
            });
  
            return {
              ...type,
              kelas: grouped,
            };
          }
          return type;
        }),
      });
    }

    if(data.type === 'sekolah') {
      setSekolah({
        ...sekolah,
        data: sekolah.data.map((item) => {
          if (data.value === item.value) {
            return {
              ...item,
              checked: !item.checked,
            };
          }
          return {
            ...item,
          };
        }),
      });
    }

    if(data.type === 'kursus') {
      setKursus({
        ...kursus,
        data: kursus.data.map((type) => {
          if (data.name === type.type) {
            const grouped = type.list.map((item, id) => {
              if (data.value === item.value) {
                return {
                  ...item,
                  checked: !item.checked,
                };
              }
              return item;
            });
  
            return {
              ...type,
              list: grouped,
            };
          }
          return type;
        }),
      });
    }
  }

  console.log("filter card", filterCard);
  console.log("kursus state", kursus.data);

  return (
    <div>
      <div className="color-grey">Filter</div>
      <div className="d-flex">
        <DropdownFilter
          title="Sekolah"
          pr={24}
          borderRight
          filter={
            <DefaultList
              data={sekolah.data}
              {...props}
              handleChange={(index, data) => onChangeSekolah(index, data)}
            />
          }
        />
        <DropdownFilter
          title="Kursus"
          pr={24}
          pl={24}
          borderRight
          filter={
            <GroupedList
              data={kursus.data}
              handleChange={(index, data, idx) =>
                onChangeKursus(index, data, idx)
              }
              {...props}
            />
          }
        />
        <DropdownFilter
          title="Kelas"
          pl={24}
          filter={
            <KelasList
              data={kelas.data}
              handleChange={(index, data, idx) =>
                onChangeKelas(index, data, idx)
              }
              {...props}
            />
          }
        />
      </div>

      {!!filterCard.length && (
        <Grid container spacing={3} className="filter-card" alignItems="center">
          <Grid item>
            Menampilkan <span className="text-bold">{props.total}</span> siswa
            dengan filter{" "}
          </Grid>
          {filterCard.map((data) => {
            return (
              <Grid item key={`${data.type}-${data.value}`}>
                <div className="filter-chip">
                  <span> {data.label} </span>
                  <button onClick={() => removeCard(data)} className="filter-chip__button">
                    {" "}
                    <i className="material-icons">close</i>
                  </button>
                </div>
              </Grid>
            );
          })}
        </Grid>
      )}
    </div>
  );
};

export default FilterSiswa;
