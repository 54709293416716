/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useCallback } from "react";
import Breadcrumb from "../../../../component/Navigation/Breadcrumb";
import Private from "../../../../layout/Private";
import TablePagination from "../../../../component/Navigation/TablePagination";
import MaterialTable from "material-table";
import { Grid, Tooltip } from "@material-ui/core";
import { useLocation, Link } from "react-router-dom";
import { toast } from "react-toastify";
import TextField from "../../../../component/Field/TextField";
import Failed from "../../../../component/Misc/Failed";
import Success from "../../../../component/Misc/Success";
import Toastr from "../../../../component/Misc/Toastr";
import Axios from "axios";
import useDebounce from "../../../../helper/useDebounce";
import { listDaftarSiswaURL } from "../../../../api/siswa";
import StatusTag from "../../../../component/Misc/StatusTag";
import FilterSiswa from "./FilterSiswa";

const DaftarSiswa = (props) => {
  const location = useLocation();
  const [state, setState] = useState({
    breadcrumb: [
      {
        name: "EEC",
        url: "/dashboard",
        active: false,
      },
      {
        name: "Siswa",
        url: "/siswa/registrasi",
        active: false,
      },
      {
        name: "Daftar Siswa",
        url: "/siswa/daftar",
        active: true,
      },
    ],
    siswa: [],
    total: "",
    isLoading: true,
  });

  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [filter, setFilter] = useState(null);
  const debouncedSearch = useDebounce(search, 500);

  const getAllData = useCallback(async filter => {
    console.log('filterrrrrr', filter)
    setState({
      ...state,
      isLoading: true,
    });

    const sekolah = filter && {
      sekolah: filter?.filter(data => data.type === 'sekolah').map(data => data.value)
    }

    const kursus = filter && {
      kursus: filter?.filter(data => data.type === 'kursus').map(data => data.value)
    }

    const kelas = filter && {
      kelas: filter?.filter(data => data.type === 'kelas').map(data => data.value)
    }

    const urlParam = `${listDaftarSiswaURL}?page=${page}${
      search ? `&search=${debouncedSearch}` : ""
    }`;

    try {
      const fetch = await Axios.get(urlParam, {
        params: {
          ...sekolah,
          ...kursus,
          ...kelas,
        }
      });
      if (fetch) {
        if(fetch.data.error){
          throw new Error(fetch.data.error);
        }

        setState({
          ...state,
          siswa: fetch.data.data,
          total: fetch.data.total,
          isLoading: false,
        });
      } else {
        throw new Error('Gagal memuat daftar siswa!');
      }
    } catch (error) {
      setState({
        ...state,
        siswa: [],
        isLoading: false,
      });
      toast(<Failed>{error.message}</Failed>);
    }
  }, [page, debouncedSearch]);

  useEffect(() => {
    getAllData(filter);
  }, [getAllData, filter]);

  useEffect(() => {
    if (location.toast) {
      toast(<Success>{location.toast}</Success>);
    }
  }, []);

  const onChangeSearch = (e) => {
    setSearch(e.target.value);
    setPage(1)
  };

  // console.log('delayed search', search)

  console.log('siswa state', state)

  return (
    <Private {...props}>
      <Breadcrumb data={state.breadcrumb} />
      <div className="wrapper table-wrapper">
        <Grid container className="mb-3" spacing={3} justify="space-between">
          <Grid item>
            <FilterSiswa fetchSiswa={(filter) => setFilter(filter)} total={state.total} />
          </Grid>
          <Grid item xs={12} lg={4}>
            <TextField
              value={search}
              placeholder="Cari Siswa"
              onChange={(e) => onChangeSearch(e)}
            />
          </Grid>
        </Grid>
        <div className="table-title">Daftar Siswa</div>
        <MaterialTable
          columns={[
            {
              title: "NIS",
              field: "nis",
            },
            {
              title: "Nama",
              field: "nama_siswa",
              render: (rowData) => (
                <Link
                  className="link-underline text-bold"
                  to={`/siswa/daftar/${rowData.id}`}
                >
                  {rowData.nama_siswa}
                </Link>
              ),
            },
            {
              title: "Pendidikan",
              field: "sekolah",
              width: 300,
              render: (rowData) => (
                <div>
                  {rowData.sekolah ? (
                    <div className="mb-1">{rowData.sekolah.pendidikan}</div>
                  ) : null}
                  {!!rowData.kursus.length &&
                    rowData.kursus.map((data) => {
                      return (
                        <div className="mb-1" key={data.pendidikan}>
                          {data.pendidikan}
                        </div>
                      );
                    })}
                </div>
              ),
            },
            {
              title: "Kelas",
              field: "sekolah",
              render: (rowData) => (
                <div>
                  {rowData.sekolah ? (
                    <div className="mb-1 link-underline text-bold">
                      {rowData.sekolah.kelas}
                    </div>
                  ) : null}
                  {!!rowData.kursus.length &&
                    rowData.kursus.map((data) => {
                      return (
                        <div
                          className="mb-1 link-underline text-bold"
                          key={data.kelas}
                        >
                          {data.kelas}
                        </div>
                      );
                    })}
                </div>
              ),
            },

            {
              title: "Status",
              field: "status",
              render: (rowData) => {
                return (
                  <StatusTag
                    background={rowData.status === "aktif" ? null : "#80848D"}
                  >
                    {rowData.status === "aktif" ? "Aktif" : "Non-aktif"}
                  </StatusTag>
                );
              },
            },

            {
              title: "",
              render: (rowData) => {
                return (
                  <div className="table-action">
                    <Tooltip title="Edit siswa" arrow>
                      <Link to={`/siswa/daftar/${rowData.id}/edit`}>
                        <i className="material-icons table-edit">edit</i>
                      </Link>
                    </Tooltip>

                    
                  </div>
                );
              },
            },
            {
              title: "id",
              field: "id",
              hidden: true,
            },
            {
              title: "kursus",
              field: "kursus",
              hidden: true,
            },
          ]}
          isLoading={state.isLoading}
          data={state.siswa}
          options={{
            toolbar: false,
            padding: "dense",
            pageSize: 10,
          }}
          components={{
            Pagination: (props) => {
              // console.log("pagination props", props);
              return (
                <td>
                  <TablePagination
                    onChangePage={(e) => setPage(e)}
                    total={state.total}
                    page={page}
                    itemShow={props.rowsPerPage}
                  />
                </td>
              );
            },
          }}
        />
      </div>
      <Toastr />
    </Private>
  );
};

export default DaftarSiswa;
