const supervisor = ["siswa", "kelas-list" ,"pembayaran", "penagihan", "pengeluaran", ];
const kasir = ["siswa", "pembayaran", "penagihan", "pengeluaran"];

const checkRole = (role, group) => {
  const token = localStorage.getItem("id");
  if (token) {

    if(!role) {
      localStorage.clear();
      localStorage.setItem('error', 'Server Error. Mohon Login Kembali')
      window.location.reload();
    }

    if (role === "Administrator") {
      return {
        status: true,
        baseRoute: "/dashboard",
      };
    }

    if (role === "Supervisor") {
      if (supervisor.includes(group)) {
        return {
          status: true,
          baseRoute: "/siswa/registrasi",
        };
      }
      return {
        status: false,
        baseRoute: "/siswa/registrasi",
      };
    }

    if (role === "Kasir") {
      if (kasir.includes(group)) {
        return {
          status: true,
          baseRoute: "/siswa/registrasi",
        };
      }
      return {
        status: false,
        baseRoute: "/siswa/registrasi",
      };
    }
  } else {
    window.location.assign("/");
  }
};

export default checkRole;
