/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useState, useEffect, useContext } from "react";
import MaterialTable from "material-table";
import { Checkbox } from "@material-ui/core";
import Axios from "axios";
import { toast } from "react-toastify";
import Failed from "../../../component/Misc/Failed";
import { findByKelasLuluskanURL } from "../../../api/siswa";
import PanelContext from "../../../context/KenaikanKelas/PanelContext";

const KenaikanPanel = (props) => {
  const context = useContext(PanelContext);
  const [detail, setDetail] = useState({
    data: [],
    isLoading: false,
  });

  const getDetailData = useCallback(
    async (status) => {
      setDetail({
        ...detail,
        isLoading: true,
      });

      try {
        if (!context.data[props.index].detail) {
          const fetch = await Axios.get(findByKelasLuluskanURL(props.id));
          if (fetch) {
            console.log("tahun ajaran", props.checked);

            const manipulateData =
              fetch.data.data.map((res, i) => ({
                ...res,
                checked: !!status,
              })) || [];

            setDetail({
              ...detail,
              data: manipulateData,
              isLoading: !!status,
            });

            context.onChangePanel(props.id, "detail", manipulateData);
          } else {
            throw new Error("Tidak dapat memuat data.");
          }
        } else {
          const manipulateData = context.data[props.index].detail.map(
            (res, i) => ({
              ...res,
              checked: !!status,
            })
          );

          context.onChangePanel(props.id, "detail", manipulateData);

          setDetail({
            ...detail,
            data: manipulateData,
            isLoading: !!status,
          });
        }
      } catch (error) {
        toast(<Failed>{error.message}</Failed>);
      } finally {
        setDetail({
          ...detail,
          isLoading: false,
        });
      }
    },
    [props.id]
  );

  useEffect(() => {
    getDetailData(props.checked);
  }, [getDetailData, props.checked]);

  const selectDetail = (id, status) => {
    const manipulateData = context.data[props.index].detail.map((res) => {
      if (res.id === id) {
        return {
          ...res,
          checked: status,
        };
      }

      return res;
    });

    setDetail({
      ...detail,
      data: manipulateData,
    });
    context.onChangePanel(props.id, "detail", manipulateData);
  };

  return (
    <div className="table-penagihan-panel detail-panel">
      <MaterialTable
        columns={[
          {
            title: "",
            field: "checked",
            width: 30,
            render: (rowData) => (
              <div>
                <Checkbox
                  style={{ padding: "0px 8px" }}
                  color="default"
                  checked={rowData.checked}
                  onChange={() => selectDetail(rowData.id, !rowData.checked)}
                  name={rowData.nama_siswa}
                />
              </div>
            ),
          },
          {
            width: 300,
            title: "NIS",
            field: "nis",
          },
          {
            title: "Nama Siswa",
            field: "nama_siswa",
            width: 550,
            render: (rowData) => <div>{rowData.nama_siswa}</div>,
          },
          {
            title: "Status",
            field: "jumlah",
            render: (rowData) => (
              <div>{rowData.status === "aktif" ? "Aktif" : "Non Aktif"}</div>
            ),
          },
        ]}
        isLoading={detail.isLoading}
        data={context.data[props.index]?.detail || []}
        options={{
          // selection: true,
          toolbar: false,
          padding: "dense",
          pageSize: 10,
          actionsColumnIndex: -1,
          detailPanelColumnAlignment: "right",
          paging: false,
          emptyRowsWhenPaging: false,
          rowStyle: { backgroundColor: "rgba(255, 245, 208, 0.25)" },
        }}
      />
    </div>
  );
};

export default KenaikanPanel;
