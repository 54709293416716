/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useCallback, useContext } from "react";
import Breadcrumb from "../../../../component/Navigation/Breadcrumb";
import Private from "../../../../layout/Private";
import MaterialTable from "material-table";
import { Grid, Checkbox } from "@material-ui/core";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { BlobProvider } from "@react-pdf/renderer";
import TextField from "../../../../component/Field/TextField";
import Failed from "../../../../component/Misc/Failed";
import Success from "../../../../component/Misc/Success";
import Toastr from "../../../../component/Misc/Toastr";
import Axios from "axios";
import priceFormat from "../../../../helper/PriceFormat";
import useDebounce from "../../../../helper/useDebounce";
import moment from "moment";
import FilterPenagihan from "./FilterPenagihan";
import {
  listMainPenagihanURL,
  detailPenagihanURL,
  invoiceURL,
} from "../../../../api/penagihan";
import DetailPanel from "./DetailPanel";
import ButtonLoading from "../../../../component/Button/ButtonLoading";
import ButtonPrimary from "../../../../component/Button/ButtonPrimary";
import PanelContext from "../../../../context/KenaikanKelas/PanelContext";
import PanelProvider from "../../../../context/KenaikanKelas/PanelProvider";
import InvoicePenagihan from "./InvoicePenagihan";
import TablePagination from "../../../../component/Navigation/TablePagination";

const DetailIcon = (props) => (
  <div className="d-flex align-items-center">
    <i className="material-icons color-ocean-blue">
      {props.open ? "expand_less" : "expand_more"}
    </i>
    <div className="text-bold color-ocean-blue font-md">
      {props.open ? "Tutup" : "Detail"}
    </div>
  </div>
);

const DaftarPenagihan = (props) => {
  const location = useLocation();
  const [state, setState] = useState({
    breadcrumb: [
      {
        name: "EEC",
        url: "/dashboard",
        active: false,
      },
      {
        name: "Akunting",
        url: "/akunting",
        active: false,
      },
      {
        name: "Penagihan",
        url: "/akunting/penagihan",
        active: true,
      },
    ],
    data: [],
    sekolahFilter: [],
    selectedId: "",
    total: "",
  });

  const context = useContext(PanelContext);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const debouncedSearch = useDebounce(search, 500);
  const [invoice, setInvoice] = useState(null);
  const [loading, setLoading] = useState({
    status: false,
    label: "",
  });

  const [filter, setFilter] = useState({
    startDate: new Date(),
    endDate: null,
    type: "All",
  });

  const [sekolahFilter, setSekolahFilter] = useState([]);

  console.log("context", context);

  const getAllData = useCallback(async () => {
    setState({
      ...state,
      isLoading: true,
    });

    const checkPeriod =
      filter.type === "All"
        ? ""
        : `&tanggal_mulai=${moment(new Date(filter.startDate)).format(
            "YYYY-MM-00"
          )}&tanggal_selesai=${moment(new Date(filter.endDate)).format(
            "YYYY-MM-00"
          )}`;

    const checkSekolah = sekolahFilter.length ? `&pendidikan=${parseInt(sekolahFilter[0])}` : ''

    const urlParam = `${listMainPenagihanURL}?page=${page}${
      search ? `&search=${debouncedSearch}` : ""
    }${checkPeriod}${checkSekolah}`;

    try {
      const fetch = await Axios.get(urlParam);
      if (fetch) {
        const manipulateData =
          fetch.data.data?.map((res, i) => ({
            ...res,
            checked: false,
            index: i,
            detail: [],
          })) || [];

        setState({
          ...state,
          data: manipulateData,
          total: fetch.data.total,
          isLoading: false,
        });

        context.onChange("data", manipulateData);
      } else {
        throw new Error("Tidak dapat memuat daftar penagihan!");
      }
    } catch (error) {
      setState({
        ...state,
        isLoading: false,
      });
      toast(<Failed>{error.message}</Failed>);
    }
  }, [
    page,
    debouncedSearch,
    filter.type,
    filter.startDate,
    filter.endDate,
    sekolahFilter,
  ]);

  const getDetailData = async (id) => {
    console.log("id", id);
    try {
      if (id) {
        setLoading({
          label: "Memeriksa data siswa...",
          status: true,
        });
        const fetch = await Axios.get(detailPenagihanURL(id));
        if (fetch) {
          const manipulateData =
            fetch.data.data?.map((res, i) => ({
              ...res,
              checked: true,
            })) || [];

          context.onChangePanel(id, "detail", manipulateData);
        }
      } else {
        throw new Error("Tidak dapat memuat data.");
      }
    } catch (error) {
      toast(<Failed>{error.message}</Failed>);
    } finally {
      setLoading({
        label: "",
        status: false,
      });
    }
  };

  useEffect(() => {
    getAllData();
  }, [getAllData]);

  useEffect(() => {
    if (location.toast) {
      toast(<Success>{location.toast}</Success>);
    }
  }, []);

  const onChangeSearch = (e) => {
    setSearch(e.target.value);
  };

  const selectSiswa = (id) => {
    getDetailData(id);

    const manipulateData = state.data.map((res) => {
      if (res.id_siswa === id) {
        return {
          ...res,
          checked: !res.checked,
        };
      }

      return {
        ...res,
        checked: false,
      };
    });

    setState({
      ...state,
      data: manipulateData,
    });

    context.onChange("data", manipulateData);
  };

  const createInvoice = async () => {
    try {
      const manipulateData = context.data.reduce((result, options) => {
        const checkSiswaSelected = options.detail.filter(
          (data) => data.checked
        );
        if (checkSiswaSelected.length) {
          const data = checkSiswaSelected.map((data) => data.id_item);

          return result.concat(data);
        }

        return result;
      }, []);

      setLoading({
        label: "Memproses...",
        status: true,
      });

      const submit = await Axios.post(invoiceURL, {
        id_item: manipulateData,
      });

      const sekolahObjKeys = Object.keys(submit.data.data.items);

      const manipulate = sekolahObjKeys.map((sekolah) => {
        const monthObjKey = Object.keys(submit.data.data.items[sekolah]);
        console.log(monthObjKey);
        const details = monthObjKey.map((month) => ({
          bulan: month,
          item: submit.data.data.items[sekolah][month].item,
          qty: submit.data.data.items[sekolah][month].qty,
          sub: submit.data.data.items[sekolah][month].sub,
        }));

        return {
          sekolah: sekolah,
          details,
        };
      });

      setInvoice({ ...submit.data.data, invoice: manipulate });

      if (submit.data.error) {
        throw new Error(submit.data.error);
      }

      if (submit) {
        toast(<Success>Berhasil membuat invoice!</Success>);
      }
    } catch (error) {
      setLoading(false);
      toast(<Failed>{error.message}</Failed>);
    } finally {
      setLoading({
        label: "",
        status: false,
      });
    }
  };

  const getFilterData = (data) => {
    console.log("FILTER ", data);
    setFilter(data.period);
    setSekolahFilter(data.sekolah);
  };

  console.log("invoice", invoice);

  // `${moment(new Date(period.startDate)).format(
  //   "MMMM YYYY"
  // )} - ${moment(new Date(period.endDate)).format("MMMM YYYY")}`

  return (
    <Private {...props}>
      <Breadcrumb data={state.breadcrumb} />
      <div className="wrapper table-wrapper table-penagihan">
        <Grid container className="mb-3" spacing={3} justify="space-between">
          <Grid item>
            <FilterPenagihan getFilterData={getFilterData} />
          </Grid>
          <Grid item xs={12} lg={4}>
            <TextField
              value={search}
              placeholder="Cari Penagihan"
              onChange={(e) => onChangeSearch(e)}
            />
          </Grid>
        </Grid>
        <div className="table-title">
          Daftar Penagihan per{" "}
          {moment(
            filter.startDate ? new Date(filter.startDate) : new Date()
          ).format("MMMM YYYY")}
          {filter.endDate &&
            `- ${moment(new Date(filter.endDate)).format("MMMM YYYY")}`}
        </div>
        <MaterialTable
          columns={[
            {
              title: "",
              field: "checked",
              width: 30,
              render: (rowData) => (
                <div>
                  <Checkbox
                    style={{ padding: "0px 8px" }}
                    color="default"
                    checked={rowData.checked}
                    onChange={() => {
                      selectSiswa(rowData.id_siswa);
                    }}
                    name={rowData.nama_siswa}
                  />
                </div>
              ),
            },
            {
              title: "Nama Siswa",
              field: "nama_siswa",
              width: 228,
              render: (rowData) => <div>{rowData.nama_siswa}</div>,
            },
            {
              width: 250,
              title: "Bulan Penagihan",
              field: "bulan_penagihan",
            },
            {
              width: 300,
              title: "Pendidikan",
              field: "pendidikan",
            },
            {
              title: "Jumlah",
              field: "jumlah",
              render: (rowData) => <div>{priceFormat(rowData.jumlah)}</div>,
            },
          ]}
          isLoading={state.isLoading}
          data={context.data}
          // onRowClick={(event, rowData, togglePanel) => {
          //   console.log("data", rowData);
          //   getDetailData(rowData.id_siswa);
          //   togglePanel();
          // }}
          options={{
            toolbar: false,
            padding: "dense",
            pageSize: 10,
            actionsColumnIndex: -1,
            detailPanelColumnAlignment: "right",
            rowStyle: (rowData) => {
              // console.log("row data", rowData);
              if (rowData.tableData.id % 2 === 0) {
                return { backgroundColor: "#F6F9FF" };
              }

              return { backgroundColor: "#fff" };
            },
          }}
          detailPanel={[
            {
              icon: () => <DetailIcon />,
              openIcon: () => <DetailIcon open />,
              render: (rowData) => {
                return (
                  <DetailPanel
                    id={rowData.id_siswa}
                    index={rowData.tableData.id}
                    checked={rowData.checked}
                  />
                );
              },
            },
          ]}
          components={{
            Pagination: (props) => {
              // console.log("pagination props", props);
              return (
                <td>
                  <TablePagination
                    onChangePage={(e) => setPage(e)}
                    total={state.total}
                    page={page}
                    itemShow={props.rowsPerPage}
                  />
                </td>
              );
            },
          }}
        />
        <Grid className="mt-4" container spacing={3} justify="flex-end">
          <Grid item>
            {loading.status ? (
              <ButtonLoading>{loading.label}</ButtonLoading>
            ) : (
              <ButtonPrimary onClick={createInvoice}>
                Buat Penagihan
              </ButtonPrimary>
            )}
          </Grid>
        </Grid>
      </div>
      <Toastr />
      {invoice && (
        <BlobProvider document={<InvoicePenagihan data={invoice} />}>
          {({ blob, url, loading, error }) => {
            if (!loading) {
              console.log("url", url);
              window.open(url, "_blank");
              setInvoice(null);
              getAllData();
            }
            return <div></div>;
          }}
        </BlobProvider>
      )}
    </Private>
  );
};

export default (props) => {
  return (
    <PanelProvider>
      <DaftarPenagihan {...props} />
    </PanelProvider>
  );
};
