/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useCallback, Fragment } from "react";
import Private from "../../../../layout/Private";
import Breadcrumb from "../../../../component/Navigation/Breadcrumb";
import { Grid } from "@material-ui/core";
// import ButtonSecondary from "../../../../component/Button/ButtonSecondary";
import Axios from "axios";
import { toast } from "react-toastify";
import Failed from "../../../../component/Misc/Failed";
import { useRouteMatch } from "react-router-dom";
// import { cancelMessage, errorMessage } from "../../../../helper/Static";
import Toastr from "../../../../component/Misc/Toastr";
import {
  listDetailSiswaURL,
  listTagihanPembayaran,
  listHistoryPembayaran,
} from "../../../../api/siswa";
import MaterialTable from "material-table";
import StatusTag from "../../../../component/Misc/StatusTag";
import moment from "moment";
import LinkOutlined from "../../../../component/Button/LinkOutlined";
import Loading from "../../../../component/Misc/Loading";
import TabsSiswa from "./TabsSiswa";
import priceFormat from "../../../../helper/PriceFormat";

const DetailsSiswa = (props) => {
  const match = useRouteMatch();

  const [state, setState] = useState({
    detailsSiswa: {},
    tagihan: [],
    lain: [],
    history: [],
    isLoading: false,
    breadcrumb: [
      {
        name: "EEC",
        url: "/dashboard",
        active: false,
      },
      {
        name: "Siswa",
        url: "/siswa",
        active: false,
      },
      {
        name: "Daftar Siswa",
        url: "/siswa/daftar",
        active: false,
      },
      {
        name: "",
        url: "/siswa/1",
        active: true,
      },
    ],
  });

  const [page, setPage] = useState(1);
  const [historyData, setHistoryData] = useState({
    data: [],
    isLoading: false,
    total: 0,
  });

  const getHistoryData = useCallback(async () => {
    setHistoryData({
      ...state,
      isLoading: true,
    });

    const urlParam = `${listHistoryPembayaran(match.params.id)}?page=${page}`;

    try {
      const history = await Axios.get(urlParam);
      if (history) {
        const historyData = history.data.data?.map((pay, index) => {
          const getContent = pay.items.map((item, idx) => {
            const objKey = Object.keys(item);

            return objKey.map((key) => {
              return {
                detail: `${item[key].qty} x ${item[key].tipe}-${item[key].nama} @ ${item[key].harga}`,
                range: `${moment(new Date(item[key].bulan_mulai)).format(
                  "MMMM YYYY"
                )} - ${moment(new Date(item[key].bulan_selesai)).format(
                  "MMMM YYYY"
                )}`,
                total: `${item[key].jumlah},-`,
              };
            });
          });

          console.log("content", getContent);
          return {
            ...pay,
            resi: {
              nama_siswa: pay.nama_siswa,
              kasir: pay.cashier.name,
              no: pay.nomor_resi,
              subtotal: `${priceFormat(pay.subtotal)},-`,
              diskon: pay.diskon ? `${priceFormat(pay.diskon)},-` : "Rp. 0,-",
              total: `${priceFormat(pay.jumlah)},-`,
              tanggal: pay.created_at,
              detail: getContent[0],
            },
          };
        });
        setHistoryData({
          isLoading: false,
          data: historyData || [],
          total: history.data.total,
        });
      } else {
        throw new Error("Tidak dapat memuat history pembayaran siswa");
      }
    } catch (error) {
      setHistoryData({
        ...state,
        isLoading: false,
      });
      toast(<Failed>{error.message}</Failed>);
    }
  }, [page]);

  useEffect(() => {
    getHistoryData();
  }, [getHistoryData]);

  const getDetailSiswa = async () => {
    setState({
      ...state,
      isLoading: true,
    });
    try {
      const siswa = await Axios.get(listDetailSiswaURL(match.params.id));
      const tagihan = await Axios.get(listTagihanPembayaran(match.params.id));
      console.log("state siswa tagihan", tagihan);

      // console.log('details', siswa.data.data)

      if (siswa && tagihan) {
        setState({
          ...state,
          tagihan: tagihan.data.data?.tagihan || [],
          lain: tagihan.data.data?.lain,
          detailsSiswa: siswa.data.data,
          breadcrumb: state.breadcrumb.map((data, i) => {
            if (i === 3) {
              return {
                ...data,
                name: siswa.data.data.nama_lengkap,
                url: `/siswa/daftar/${match.params.id}`,
              };
            }
            return data;
          }),
          isLoading: false,
        });
      } else {
        throw new Error("Tidak dapat memuat detail siswa");
      }
    } catch (error) {
      setState({
        ...state,
        isLoading: false,
      });
      toast(<Failed>{error.message}</Failed>);
    }
  };

  useEffect(() => {
    getDetailSiswa();
  }, []);

  console.log("siswa", state);

  return (
    <Private {...props}>
      <Breadcrumb data={state.breadcrumb} />
      <div className="wrapper-form edit">
        <Grid container justify="space-between" alignItems="center">
          <Grid item>
            <div className="header-title">
              DATA SISWA {state.detailsSiswa.nis && `- ${state.detailsSiswa.nis}`}
            </div>
          </Grid>
          <Grid item>
            <LinkOutlined
              address={`/siswa/daftar/${match.params.id}/edit`}
              icon="edit"
              color="#25345A"
            >
              EDIT
            </LinkOutlined>
          </Grid>
        </Grid>
      </div>

      <div className="wrapper table-wrapper">
        {state.isLoading ? (
          <Loading />
        ) : (
          <React.Fragment>
            <div className="container">
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <div className="color-grey font-sm">Nama Lengkap</div>
                  <div className="text-bold">
                    {state.detailsSiswa.nama_lengkap}
                  </div>
                </Grid>

                <Grid item xs={12} md={6}>
                  <div className="color-grey font-sm">Jenis Kelamin</div>
                  <div className="text-bold">
                    {state.detailsSiswa.jenis_kelamin === "lk"
                      ? "Laki - laki"
                      : "Perempuan"}
                  </div>
                </Grid>

                <Grid item xs={12} md={6}>
                  <div className="color-grey font-sm">Tempat Lahir</div>
                  <div className="text-bold">
                    {state.detailsSiswa.tempat_lahir}
                  </div>
                </Grid>

                <Grid item xs={12} md={6}>
                  <div className="color-grey font-sm">Tanggal Lahir</div>
                  <div className="text-bold">
                    {moment(new Date(state.detailsSiswa.tanggal_lahir)).format(
                      "DD MMMM YYYY"
                    )}
                  </div>
                </Grid>

                <Grid item xs={12} md={6}>
                  <div className="color-grey font-sm">Nama Orang Tua</div>
                  <div className="text-bold">
                    {state.detailsSiswa.nama_orang_tua}
                  </div>
                </Grid>

                <Grid item xs={12} md={6}>
                  <div className="color-grey font-sm">Alamat</div>
                  <div className="text-bold">{state.detailsSiswa.alamat}</div>
                </Grid>
              </Grid>
            </div>
            <div className="separator-border pt-4 mb-4"></div>
            <p className="font-sm">Terdaftar sebagai siswa di</p>
            <MaterialTable
              columns={[
                {
                  title: "Pendidikan",
                  field: "pendidikan",
                  width: 250,
                },
                {
                  title: "Kelas",
                  field: "kelas",
                  render: (rowData) => (
                    <Fragment>
                      {rowData.kelas ? (
                        <div className="mb-1 link-underline text-bold">
                          {rowData.kelas}
                        </div>
                      ) : (
                        <div className="color-grey text-sm">
                          Belum memilih kelas (tingkat {rowData.tingkat})
                        </div>
                      )}
                    </Fragment>
                  ),
                },
                {
                  title: "Tahun Ajaran",
                  field: "tahun_ajaran",
                  width: 250,
                },
                {
                  title: "Status",
                  field: "status",
                  render: (rowData) => {
                    return (
                      <StatusTag
                        background={
                          rowData.status === "aktif" ? null : "#80848D"
                        }
                      >
                        {rowData.status === "aktif" ? "Aktif" : "Non-aktif"}
                      </StatusTag>
                    );
                  },
                },
                {
                  title: "id",
                  field: "id",
                  hidden: true,
                },
                {
                  title: "kursus",
                  field: "kursus",
                  hidden: true,
                },
              ]}
              isLoading={state.isLoading}
              data={state.detailsSiswa.daftar_pendidikan}
              options={{
                toolbar: false,
                padding: "dense",
                pageSize: 10,
                paging: false,
              }}
            />
          </React.Fragment>
        )}
      </div>

      <TabsSiswa
        tagihan={state.tagihan}
        lain={state.lain}
        page={page}
        history={historyData}
        setPageHistory={(e) => setPage(e)}
        loading={state.isLoading}
        reload={getDetailSiswa}
      />

      <Toastr />
    </Private>
  );
};

export default DetailsSiswa;
