import React from "react";
import { Link } from "react-router-dom";

const Breadcrumb = (props) => {
  return (
    <div className="breadcrumb">
      {props.data.map((data, i) => {
        return (
          <React.Fragment key={data.name}>
            <Link to={data.url}>
              <span className={data.active ? "active" : ""}>{data.name}</span>
            </Link>
            {i + 1 !== props.data.length ? (
              <span className="breadcrumb-dash">-</span>
            ) : (
              ""
            )}
          </React.Fragment>
        );
      })}
    </div>
  );
};

export default Breadcrumb;
